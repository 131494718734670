import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
//import { HomePage } from './home/home.page';
import { LoginComponent } from './user/login/login.component';
import { ChangePasswordComponent } from './user/change-password/change-password.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { AdminSettingsComponent } from './admin-settings/admin-settings.component';
import { SettingsComponent } from './settings/settings.component';
import { ContactPersonComponent } from './contact-person/contact-person.component';
import { RoomComponent } from './room/room.component';
import { RegisterComponent } from './user/register/register.component';
import { BookListComponent } from './books/book-list/book-list.component';
import { BookPurchasesListComponent } from './bookstore/book-purchases-list/book-purchases-list.component';
import { BookEditComponent } from './books/book-edit/book-edit.component';
import { BookCreateComponent } from './books/book-create/book-create.component';
import { GameListComponent } from './games/singleplayer/game-list/game-list.component';
import { SessionComponent } from './session/session.component';
import { SingleReadingComponent } from './single-reading/single-reading.component';
import { ResetPasswordComponent } from './user/reset-password/reset-password.component';
import { AdminListComponent } from './user/admin/admin-list/admin-list.component';
import { MentorListComponent } from './user/mentor/mentor-list/mentor-list.component';
import { MentorMappingComponent } from './user/mentor/mentor-mapping/mentor-mapping.component';
import { StudentListComponent } from './user/student/student-list/student-list.component';
import { StudentEditComponent } from './user/student/student-edit/student-edit.component';
import { MentorEditComponent } from './user/mentor/mentor-edit/mentor-edit.component';
import { CreateQuestionComponent } from './questions/create-question/create-question.component';
import { EditQuestionComponent } from './questions/edit-question/edit-question.component';
import { SingleReadingSilentComponent } from './single-reading-silent/single-reading-silent.component';
import { QuizComponent } from './quiz/quiz.component';
import { CreateSchoolComponent } from './school/create-school/create-school.component';
import { EditSchoolComponent } from './school/edit-school/edit-school.component';
import { SchoolListComponent } from './school/school-list/school-list.component';
import { BookPreviewComponent } from './books/book-preview/book-preview.component';
import { CoordinatorListComponent } from './user/coordinator/coordinator-list/coordinator-list.component';
import { CoordinatorEditComponent } from './user/coordinator/coordinator-edit/coordinator-edit.component';
import { CoordinatorMenuComponent } from './user/coordinator/coordinator-menu/coordinator-menu.component';
import { StudentMenuComponent } from './user/student/student-menu/student-menu.component';
import { MentorMenuComponent } from './user/mentor/mentor-menu/mentor-menu.component';
import { SchoolMenuComponent } from './school/school-menu/school-menu.component';
import { SessionMenuComponent } from './session-menu/session-menu.component';
import { StudentMatchingComponent } from './user/student/student-matching/student-matching.component';
import { MentorMatchingComponent } from './user/mentor/mentor-matching/mentor-matching.component';
import { ListQuestionComponent } from './questions/list-question/list-question.component';
import { CsvUploadComponent } from './csv-upload/csv-upload.component';
// Bookstore
import { BookLibraryOverviewPageComponent } from './bookstore/book-library-overview/book-library-overview.component';
import { SearchResultsPageComponent } from './bookstore/search-results/search-results-page.component';
import { SpeedTestComponent } from './helpers/speed-test/speed-test.component';

//Notification
import { NotificationsComponent } from './notifications/notifications.component';
//Chat
import { ChatComponent } from './chat/chat.component';
// import { BookListComponent } from './books/book-list/book-list.component';
import { BookCreateISBNComponent } from './books/book-create-isbn/book-create-isbn.component';
import { CreditAdministrationComponent } from './credit-administration/credit-administration.component';
import { AdminTokenComponent } from './admin-token/admin-token.component';
import { AdminBookDonationListComponent } from './admin-book-donation/admin-book-donation-list/admin-book-donation-list.component';
import { AdminBookDonationCreateComponent } from './admin-book-donation/admin-book-donation-create/admin-book-donation-create.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  //{ path: 'home', loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)},
  //{ path: 'home', component: HomePage },
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'admin-dashboard', component: AdminDashboardComponent },
  { path: 'admin-settings', component: AdminSettingsComponent },
  { path: 'settings/:id', component: SettingsComponent },
  { path: 'contact/:id', component: ContactPersonComponent },
  { path: 'change-password/:id', component: ChangePasswordComponent },
  { path: 'room/:id', component: RoomComponent },
  { path: 'registrierung', component: RegisterComponent },
  { path: 'books', component: BookListComponent },
  { path: 'books/:id', component: BookListComponent },
  { path: 'book-create', component: BookCreateComponent },
  { path: 'games', component: GameListComponent },
  { path: 'admin-create', component: RegisterComponent },
  { path: 'admin-list', component: AdminListComponent },
  { path: 'mentor-create', component: RegisterComponent },
  { path: 'mentor-list', component: MentorListComponent },
  { path: 'mentor-mapping/:id', component: MentorMappingComponent },
  { path: 'student-create', component: RegisterComponent },
  { path: 'student-list', component: StudentListComponent },
  { path: 'student-edit/:id', component: StudentEditComponent },
  { path: 'student-matching/:id', component: StudentMatchingComponent },
  { path: 'mentor-matching/:id', component: MentorMatchingComponent },
  { path: 'mentor-edit/:id', component: MentorEditComponent },
  { path: 'book-edit/:id', component: BookEditComponent },
  { path: 'session', component: SessionComponent },
  { path: 'session/:id', component: SessionComponent },
  { path: 'single-reading', component: SingleReadingComponent },
  { path: 'book-preview', component: BookPreviewComponent },
  { path: 'book-preview/:id', component: BookPreviewComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'create-question/:id', component: CreateQuestionComponent },
  { path: 'edit-question/:id', component: EditQuestionComponent },
  { path: 'list-question/:id', component: ListQuestionComponent },
  { path: 'single-reading-silent', component: SingleReadingSilentComponent },
  { path: 'quiz', component: QuizComponent },
  { path: 'create-school', component: CreateSchoolComponent },
  { path: 'edit-school/:id', component: EditSchoolComponent },
  { path: 'school-create', component: CreateSchoolComponent },
  { path: 'school-list', component: SchoolListComponent },
  { path: 'coordinator-create', component: RegisterComponent },
  { path: 'coordinator-edit/:id', component: CoordinatorEditComponent },
  { path: 'coordinator-list', component: CoordinatorListComponent },
  { path: 'coordinator-menu', component: CoordinatorMenuComponent },
  { path: 'coordinator-mapping/:id', component: MentorMappingComponent },
  { path: 'coordinator-matching/:id', component: MentorMatchingComponent },
  { path: 'student-menu', component: StudentMenuComponent },
  { path: 'mentor-menu', component: MentorMenuComponent },
  { path: 'school-menu', component: SchoolMenuComponent },
  { path: 'session-menu', component: SessionMenuComponent },
  { path: 'csv-upload', component: CsvUploadComponent },
  { path: 'book-create-isbn', component: BookCreateISBNComponent },
  { path: 'games/aviator',
    loadChildren: () => import('./games/games-lazy-load-module/lazy-load-aviator.module').then(m => m.LazyLoadAviatorModule)
  },
  { path: 'games/rabbit-run',
    loadChildren: () => import('./games/games-lazy-load-module/lazy-load-rabbit.module').then(m => m.LazyLoadRabbitModule)
  },
  { path: 'games/tower-blocks',
    loadChildren: () => import('./games/games-lazy-load-module/lazy-load-tower-blocks.module').then(m => m.LazyLoadTowerBlocksModule)
  },
  { path: 'games/hextris',
    loadChildren: () => import('./games/games-lazy-load-module/lazy-load-hextris.module').then(m => m.LazyLoadHextrisModule)
  },
  { path: 'speed-test', component: SpeedTestComponent },
  // Bookstore
  { path: 'library', canActivate: [], component: BookLibraryOverviewPageComponent },
  { path: 'search', canActivate: [], component: SearchResultsPageComponent },
  { path: 'search/:searchText', canActivate: [], component: SearchResultsPageComponent },
  { path: 'book-purchases', component: BookPurchasesListComponent },
  { path: 'notifications', component: NotificationsComponent },
  { path: 'chat/:id', component: ChatComponent },
  { path: 'admin-credits', component: CreditAdministrationComponent },
  { path: 'admin-token', component: AdminTokenComponent },
  { path: 'admin-book-donation-list', component: AdminBookDonationListComponent },
  { path: 'admin-book-donation-create', component: AdminBookDonationCreateComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
