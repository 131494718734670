<div class="container-fluid">
  <div class="update-password row pt-5">
    <div class="col-md-12">
      <div class="button-group">
        <div class="update_password_wrap">
          <h1>Kennwort für {{ username }} ändern</h1>
          <form (submit)="updatePassword(updatePasswordForm)" #updatePasswordForm="ngForm">
              <mat-form-field>
                <input type="password" name="password" minlength="3" ngModel matInput placeholder="Neues Kennwort" #passwordInput="ngModel" required>
                <mat-error *ngIf="passwordInput.invalid">Please enter a valid password.</mat-error> 
              </mat-form-field>
              <button mat-button color="accent" type="submit" class="size-big" aria-label="LOG IN">Kennwort ändern</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>