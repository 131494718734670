<div class="speed-test container-fluid py-3">
  <div class="row">
    <div class="col-md-12" *ngIf="hasTracked || isTracking">
      <h1 class="text-center">Messung Bandbreite</h1>
      <div class="speed-test_wrap">
        <div class="speed-bars">
          <div *ngFor="let item of speeds">
            <mat-progress-bar mode="determinate" [value]="item"></mat-progress-bar>
          </div>
        </div>
        <div class="speed-current" *ngIf="isTracking">
          <span *ngIf="speeds[0]">{{ speeds[0] }} Mbps</span> 
        </div>
      </div>
      <div class="speed-count" *ngIf="hasTracked">
        <span>Durchschnitt: {{ average }} Mbps</span> 
      </div>
    </div>
  </div>
</div>