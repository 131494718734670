import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent {
  searchForm: FormGroup;
  isSubmitted: boolean = false;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly router: Router
  ) {
    this.searchForm = this.formBuilder.group({
      searchText: ['', Validators.required],
    });
  }

  onSearch(): void {
    this.isSubmitted = true;

    if (this.searchForm.invalid) {
      return;
    }

    this.router.navigate([
      'search',
      this.searchForm.controls['searchText'].value,
    ]);
  }
}
