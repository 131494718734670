export function environmentRuntimeVariable(key: any, value: any) {
    // Check if window key env exists
    if (window['env'] && window['env'].hasOwnProperty(key)) {
        let envValue = window['env'][key];
        // Check for string
        if (typeof envValue === 'string' && envValue !== '') {
            // Check if environment placeholder value and return original value
            if (envValue.match(/(?:\${)\w+/)) {
                envValue = value;
            }
            // Check if string could be a number
            if (typeof envValue !== 'boolean' && typeof envValue !== 'number') {
                if (envValue.match(/^-?\d+$/)) {
                    // Convert string to number
                    envValue = parseInt(envValue);
                }
            }
            // Covert string to boolean
            if (envValue == 'true') {
                envValue = true;
            } else if (envValue == 'false') {
                envValue = false;
            }
            return envValue;
        } else {
            return value;
        }
    } else {
        return value;
    }
}