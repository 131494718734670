<div class="container-fluid pt-3">
  <div class="row">
    <div class="col-md-12 col-sm-12 px-3" *ngIf="start && !endQuiz && isStudent">
      <div class="quiz_headline">
        <div class="quiz_question">
          <h1>{{currentIndex + 1}} Frage:</h1> {{currentQuizSet.question}}
        </div>
        <div class="quiz_answers">
          <h1>Antwort:</h1>
          <mat-radio-group [(ngModel)]="currentQuizSet.selected" [disabled]="!isStudent">
            <mat-radio-button *ngFor="let o of currentQuizSet.answers" name="{{o._id}}" value="{{o._id}}" [ngClass]="{'rightAnswer': o.rightAnswer && !isStudent, 'wrongAnswer': !o.rightAnswer && !isStudent }">{{o.answerText}}
            </mat-radio-button>
          </mat-radio-group>
          <!-- freeTextanswer -->
          <div *ngIf="currentQuizSet.answers.length < 1">
            <mat-form-field class="example-full-width" appearance="fill">
              <textarea matInput placeholder="Antwort" [(ngModel)]="currentQuizSet.freeTextAnswer"></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>

    </div>

    <div class="quiz-button col-md-12 col-sm-12 px-3" *ngIf="start && isStudent">
      <button mat-button color="accent" *ngIf="(currentIndex+1) != quiz.length" (click)="next()"
        [disabled]="!currentQuizSet?.selected && currentQuizSet.answers.length > 0 || !isStudent" class="size-big">Weiter</button>
    </div>
    <div class="quiz-button" *ngIf="isStudent">
      <button mat-button color="accent" (click)="showSummary()" class="size-big" *ngIf="isfinished && !endQuiz && !quizFinished"
      [disabled]="!currentQuizSet?.selected && currentQuizSet.answers.length > 0">Quiz beenden</button>
    </div>

    <div class="quiz-content col-md-12 col-sm-12 px-3" *ngIf="quiz?.length > 0 && quizCheck || !isStudent">
      <div *ngIf="!start || !isStudent">
        <div *ngIf="!quizFinished && isStudent">
          <h1>Zu {{bookName}} wurden {{ quiz.length }} Fragen gefunden.</h1>
          <p>Möchtest Du das Quiz starten?</p>
        </div>
        <div *ngIf="quizFinished">
          <h1>Ergebnis: {{correct}}/{{quiz.length}} Fragen wurden richtig beantwortet.</h1>

          <!-- <h1>Ergebnis</h1> -->
          <!-- <div *ngIf="quizResult"> -->
            <mat-list role="list" *ngFor="let quiz of quizSummary; let i = index" [ngClass]="checkAnswer(i, quiz.selected)">
              <mat-list-item class="quiz-summary-list"> 
                <div class="quiz-summary">
                  <h1>{{i + 1}}. Frage:</h1> 
                  <p>{{quiz.question}}</p>
                  <div *ngIf="quiz.answers.length > 1"> 
                    <mat-radio-group [(ngModel)]="quiz.selected" [disabled]="true">
                      <!-- <mat-radio-button *ngFor="let o of quiz.answers" name="{{o._id}}" value="{{o._id}}" [ngStyle]="{'font-weight': answerFontWeight}">{{o.answerText}}</mat-radio-button> -->
                      <mat-radio-button *ngFor="let o of quiz.answers" name="{{o._id}}" value="{{o._id}}" [ngClass]="{'rightAnswer': o.rightAnswer, 'wrongAnswer': !o.rightAnswer}">
                        {{o.answerText}}</mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <div *ngIf="quiz.answers.length < 1 && !isStudent">
                    Antwortmöglichkeit: {{quiz.referenceAnswer}}
                  </div>
                    <mat-form-field class="example-full-width" appearance="fill"  *ngIf="quiz.answers.length < 2">
                      <textarea matInput placeholder="Antwort"[(ngModel)]="quiz.freeTextAnswer" [disabled]="true"></textarea>
                    </mat-form-field>
                  <!-- Bewertung todo -->
                  <button mat-button color="primary" class="size-big"  *ngIf="quiz.answers.length < 1 && !isStudent" (click)="rightAnswer(quiz)">Richtig</button>
                  <button mat-button color="accent"  class="size-big"  *ngIf="quiz.answers.length < 1 && !isStudent" (click)="wrongAnswer(quiz)">Falsch</button>
                </div>
                <!-- <div [ngClass]="{'rightResult': quiz.selected == o.rightAnswer }"></div> -->
              </mat-list-item>
            </mat-list>
          <!-- </div> -->
        </div>
        <div class="quiz-button" *ngIf="isStudent">  
          <button *ngIf="!quizFinished" mat-button color="accent" (click)="startQuiz()" class="size-big">{{buttonname}}</button>
          <button *ngIf="quizFinished" mat-button color="accent" (click)="closeQuiz()" class="size-big">Quiz schließen</button>
        </div>
        <div class="quiz-button" *ngIf="!isStudent">  
          <button *ngIf="quizFinished" mat-button color="accent" (click)="closeQuiz()" class="size-big">Quiz schließen</button>
        </div>
      </div>
    </div>



    <div class="quiz-summary-container col-md-12 col-sm-12 px-3" *ngIf="isSummary && !quizFinished && isStudent">
      <!-- quiz summary -->
      <h1>Quiz Zusammenfassung</h1>
      <mat-list role="list" *ngFor="let quiz of quizSummary; let i = index">
        <mat-list-item class="quiz-summary-list"> 
          <div class="quiz-summary">
            <h1>{{i + 1}}. Frage:</h1>
            <p>{{quiz.question}}</p>
            <mat-radio-group [(ngModel)]="quiz.selected">
              <mat-radio-button *ngFor="let o of quiz.answers" name="{{o._id}}" value="{{o._id}}">{{o.answerText}}
              </mat-radio-button>
            </mat-radio-group>
            <mat-form-field class="example-full-width" appearance="fill"  *ngIf="quiz.answers.length < 1">
                <textarea matInput placeholder="Antwort"[(ngModel)]="quiz.freeTextAnswer"></textarea>
            </mat-form-field>

          </div>
        </mat-list-item>
      </mat-list>
      <div class="quiz-button"> 
        <button mat-button color="accent" (click)="submit()" class="size-big" *ngIf="endQuiz"
        [disabled]="!currentQuizSet?.selected && !isHidden">Quiz auswerten</button>
      </div>

    </div>
      

    <div class="col-md-12 col-sm-12 px-3" *ngIf="quiz?.length < 1 && quizCheck && isStudent">
      <h1> Keine Fragen zu {{bookName}} vorhanden</h1>
    </div>

    <mat-icon class="close_quiz_icon" (click)="closeQuiz()">close</mat-icon>
  </div>
</div>

<!-- mentor view tandem mode-->
<div class="container-fluid pt-3" *ngIf="!isStudent && !quizFinished">
  <div class="quiz-summary-container col-md-12 col-sm-12 px-3">
  
    <h1>Quiz Zusammenfassung</h1>
  
      <mat-list role="list" *ngFor="let quiz of quiz; let i = index" [ngClass]="{'current-child-position': i == childIndex }">
        <mat-list-item class="quiz-summary-list"> 
          <div class="quiz-summary">
            <h1>{{i + 1}}. Frage:</h1>
            <p>{{quiz.question}}</p>
            <mat-radio-group [(ngModel)]="quiz.selected" [disabled]="true">
           
              <mat-radio-button *ngFor="let o of quiz.answers" name="{{o._id}}" value="{{o._id}}" [ngClass]="{'rightAnswer': o.rightAnswer, 'wrongAnswer': !o.rightAnswer }">
                {{o.answerText}}</mat-radio-button>

            </mat-radio-group>
            <div *ngIf="quiz.answers.length < 1">
              Antwortmöglichkeit: {{quiz.referenceAnswer}}
            </div>
          </div>
        </mat-list-item>
      </mat-list>
      <div class="quiz-button">  
        <button *ngIf="!quizFinished" mat-button color="accent" (click)="closeQuiz()" class="size-big">Quiz schließen</button>
      </div>
  </div>
</div>