<mat-dialog-content>
  <div *ngIf="enoughCredit; else insufficient_credits">
    <h2>Möchtest Du das Buch wirklich kaufen?</h2>
    <p>Das Buch kostet: <b class="text-danger">{{ getPrice(bookPrice) }}</b></p>
    <p>Nach dem Kauf hast Du noch ein Guthaben von: <b>{{remainingWallet}}</b></p>
    <!-- <p>Am XX.XX.XXXX bekommst Du wieder 25€ auf dein Guthabenkonto.</p> -->

    <div class="action-buttons">
      <button [mat-dialog-close]="true" class="btn btn-sm btn-secondary">
        Nicht kaufen
      </button>
      <button class="btn btn-sm btn-danger" (click)="purchaseBook()">
        Kaufen
      </button>
    </div>
  </div>

  <ng-template #insufficient_credits>
    <h2>Du hast leider zu wenig Guthaben.</h2>
    <p>Du kannst Dir ein Buch für 0 Token aussuchen.</p>
    <!-- <p>Am XX.XX.XXXX bekommst Du wieder 25€ auf Dein Guthabenkonto.</p> -->

    <div class="action-buttons">
      <button [mat-dialog-close]="true" class="btn btn-sm btn-secondary">
        Zurück
      </button>
      <!-- <button class="btn btn-sm btn-primary" (click)="addToWatchList()">
        Auf Merkliste setzen
      </button> -->
      <!-- <button class="btn btn-sm btn-secondary" (click)="showFreeBooks()">
        0€-Bücher ansehen
      </button> -->
    </div>
  </ng-template>
</mat-dialog-content>
