import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormatDatePipe } from './formatDate.pipe';
import { TimeformatPipe } from './timeformat.pipe';
import { LinebreaksPipe  } from './linebreak.pipe';
import { SafeHtmlPipe  } from './safehtml.pipe';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        FormatDatePipe,
        TimeformatPipe,
        LinebreaksPipe,
        SafeHtmlPipe
    ],
    exports: [
        FormatDatePipe,
        TimeformatPipe,
        LinebreaksPipe,
        SafeHtmlPipe
    ]
})
export class PipeModule { }