import { Component, OnInit, Input } from '@angular/core';
import { WebSocketService } from '../../../providers/websocket.service';
import { AuthService } from '../../../auth/auth.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-game-uno',
  templateUrl: './game-uno.component.html',
  styleUrls: ['./game-uno.component.scss'],
})
export class GameUnoComponent implements OnInit {

  isLoading: boolean = true;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  @Input('userType') isMentor;
  @Input('studentId') studentId: string;
  @Input('mentorId') mentorId: string;

  constructor(
    private webSocketService: WebSocketService,
    private authService : AuthService
  ) { }

  ngOnInit() {
    // Generate gameplay server
    this.initGame();
  }

  /**
   * initGame / start game
   */ 
  initGame() {
    // Generate gameplay frontend
    this.webSocketService.unoInit(this.mentorId, this.studentId, this.isMentor);
    // Listen for game-start event
    this.webSocketService.listen('game-uno-game-created').pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((data) => { this.isLoading = false; });
  }

  /**
   * reset the game
   */ 
  resetGame() {
    this.webSocketService.emitTo('game-uno-rematch', this.webSocketService.getCurrentRoom(), {
      gameId: 'r-' + this.webSocketService.getCurrentRoom()
    });
  }

  /**
   * On destroy / left game if mentor close it
   */
  ngOnDestroy(): void {
    console.log('Game uno left')
    this.webSocketService.emitTo('game-uno-remove', this.webSocketService.getCurrentRoom(), {
      gameId: 'r-' + this.webSocketService.getCurrentRoom()
    });
    this.webSocketService.unoClearEventlistener();
    // Unsubscribe from all subscriptions
    setTimeout(() => {
      this._unsubscribeAll.next(true);
      this._unsubscribeAll.complete();
    }, 100);
  }
}