import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface Quiz {
  _id?: string;
  question?: string;
  textPassage?: string;
  page?: number;
  book?: string;
  answers?: any;
  startPage?: number;
  activePage?: number;
}

@Injectable({
  providedIn: 'root'
})

export class QuizService {
  private restServerUrl;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  quiz: Quiz[] = [];
  quizUpdated = new Subject<Quiz[]>();
  onQuizChanged: BehaviorSubject<any>;

  constructor(
    private http: HttpClient,
  ) { 
    let protocol = environment.ssl ? 'https://' : 'http://';
    this.restServerUrl = protocol + environment.restServer + ":" + environment.restServerPort ;
  }
  
  createQuestionForQuiz(formData) {
    return this.http.post<any>(this.restServerUrl + "/api/quiz/create", formData)
      .pipe(
        takeUntil(this._unsubscribeAll)
      );
  }
  
  getQuestionbyId(id) {
    return this.http.get<Quiz[]>(this.restServerUrl + '/api/quiz/' + id)
      .pipe(map(data => data));
  }

    /**
   * get list of all students
   */
	getQuestionsList() {
		this.http.get<Quiz[]>(this.restServerUrl + '/api/quiz/list')
    .pipe(map(data => data), takeUntil(this._unsubscribeAll))
			.subscribe(data => {
        // console.log(data)
                this.quiz = data;
                this.quizUpdated.next([...this.quiz]);
                // this.onQuizChanged.next(this.quiz);
			});
  }

  getQuestionAboutTheBook(id) {
    console.log(id)
		this.http.get<Quiz[]>(this.restServerUrl + '/api/quiz/listByBook/' + id)
    .pipe(map(data => data), takeUntil(this._unsubscribeAll))
			.subscribe(data => {
        console.log(data)
                this.quiz = data;
                this.quizUpdated.next([...this.quiz]);
			});
  }

  getQuestionUpdateListener() {
    return this.quizUpdated.asObservable();
  }

  updateQuestion(id, data:any)  {
    return this.http.put(
      this.restServerUrl + '/api/quiz/' + id,
      JSON.stringify(data),
      { headers: new HttpHeaders().set('Content-Type', 'application/json') })
        .pipe(map(res => {
          return <Quiz>res;
        }));
  }

   /**
   * set delete question
  */
 deleteQuestion(id: string) {
  this.http.delete(this.restServerUrl + '/api/quiz/' + id).pipe(
    takeUntil(this._unsubscribeAll)
  ).subscribe(res => {
    // Update local list
    this.quiz.splice(this.quiz.findIndex((item) => {
        return item._id === id;
    }), 1);
      this.quizUpdated.next([...this.quiz]);
    }, (error) => {
      console.error("Error deleting books: ", error);
  });
}

generateQuiz(data) {
  console.log(data)
  const quizData: Quiz = { 
    startPage : data.startPage,
    activePage: data.activePage
  };
  return this.http.post<any>(this.restServerUrl + "/api/quiz/generateQuiz/" + data.bookId, quizData)
    .pipe(map(res => {
      console.log(res)
       return <Quiz>res;
    }));
}

uploadCsv(element) {
  const formData = new FormData();
        formData.append('quiz', element.file);
  return this.http.post<any>(this.restServerUrl + "/api/quiz/csvimport", formData)
    .pipe(map((res) => {
      return <Quiz>res
    })
  );
}
    
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
