import { Component, OnInit, Inject, Optional, Input  } from '@angular/core';
import {  MatDialogRef, MatDialog,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuizResultService } from '../providers/quizResult.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UserService } from '../providers/user.service';

export interface DialogData {
  startPage: number;
  activePage: number;
  bookId: string;
  bookName: string;
}


@Component({
  selector: 'app-quiz-result',
  templateUrl: './quiz-result.component.html',
  styleUrls: ['./quiz-result.component.scss'],
})
export class QuizResultComponent implements OnInit {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  quizResult;
  quiz;
  isHiddenField: boolean = false;
  correct:any;
  result;

  constructor(
    private quizResultService : QuizResultService,
    public snackBar: MatSnackBar,
    private userService : UserService,
    public dialogRef: MatDialogRef<QuizResultComponent>,
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: any,
    ){}
  
  ngOnInit() {

    this.quizResultService.getQuizResultbyId(this.injectedData.id).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(res => {
      this.quizResult = res;
      this.result =  Math.round(res["result"]);
      this.quiz = res["quiz"];
  
      this.quiz.forEach(element => {
        console.log(element.answers[0]?.answerText)
        if (element.answers[0]?.answerText == undefined ) {
          this.isHiddenField = true;
          console.log(this.isHiddenField )
        }
      });
    });
   }

   rightAnswer(element) {
    element.answers.push({
      rightAnswer : true
    })

    this.correct = 0;
    // Generate new quiz result
    this.quiz.map(x => {
      let result = x.answers[x.answers.findIndex((item) => {
        return item['_id'] === x.selected;
      })];
      if (result && result.rightAnswer === true) {
        this.correct = this.correct + 1;
      }
    });

    // Update Quiz result
    this.quizResultService.updateQuizResult({
        _id : this.quizResult._id,
         quiz : this.quiz,
         result: this.correct / this.quiz.length * 100
      }).pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe(res => {
        this.result = Math.round(res["result"]);
        this.quizResult.result = res["result"];
       // increase readingPoints
        if(res["result"] >= 50 && res["result"] < 51 ) {
          this.userService.getUserbyId(this.quizResult.student).pipe(
            takeUntil(this._unsubscribeAll)
          ).subscribe(res => {
              //  Get current rading points
              let currentReadingPoints = res["readingPoints"] ? res["readingPoints"] : 0;
              // increase readingPoints
              currentReadingPoints++;
              // Save to server
              this.userService.updateUser({
                _id : this.quizResult.student,
                readingPoints : currentReadingPoints
              }).pipe(
                takeUntil(this._unsubscribeAll)
              ).subscribe(res => {
                //console.log('new points earned')
                this.snackBar.open("Der Punktestand wurde um +1 erhöht.", "",{
                  duration: 3000,
                  horizontalPosition: 'right'
                });
              });
          });
        }
      });
   }

   checkAnswer(i, selectedAnswer) {
    let check = this.quiz[i].answers.filter(selected => {
      if (selected._id == selectedAnswer) {
        return selected;
      }
    })
    if (check.length > 0) {
      if (check[0].rightAnswer) {
        return 'result-right';
      } else {
        return 'result-wrong';
      }
    } else {
      return 'result-unrated';
    }
  }

   wrongAnswer(element) {
    element.answers.push({
      rightAnswer : false
    })

    this.quizResultService.updateQuizResult({
        _id : this.quizResult._id,
         quiz : this.quiz
      }).pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe(res => {
        this.quizResult.result = res["result"];
      });
   }

   closeOverlay() {
    this.dialogRef.close();
   }

}
