import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BookDetailsDialogComponent } from '../dialogs/book-details-dialog/book-details-dialog.component';
import { environment } from "../../../environments/environment";
import { BookWithIsbn } from "../shared/models/book-startpage";
import { formatCurrency } from "@angular/common";
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-book-vertical-list-item',
  templateUrl: './book-vertical-list-item.component.html',
  styleUrls: ['./book-vertical-list-item.component.scss'],
})
export class BookVerticalListItemComponent implements OnInit {
  @Input() book!: BookWithIsbn;
  bookstoreServerUrl;
  showPrice: boolean = false;

  constructor(
    private readonly dialog: MatDialog,
    private authService : AuthService
    ) {
    let protocol = environment.ssl ? 'https://' : 'http://';
    this.bookstoreServerUrl = protocol + environment.bookstoreServerUrl + ":" + environment.bookstoreServerPort;

  }

  ngOnInit(): void {
    if (!this.book) {
      throw new Error('Input parameter "book" is undefined');
    }
  }

  openBookDetails(): void {
    this.dialog.open(BookDetailsDialogComponent, {
      width: '80vw', data: {isbn: this.book.isbn, restoreFocus: false, lower_age_barrier: this.book.lower_age_barrier}
    });
  }

  // public getPrice(price:number): string{
  //   return formatCurrency(price, "de-DE", "€");
  // }
  public getPrice(price:number): string{
    if (this.showPrice) {
      return formatCurrency(price, "de-DE", "€");
    } else{
        return price > 0 ? "1 Token" : "0 Token";
    }
  }
}
