import { createFeatureSelector, createSelector } from '@ngrx/store';
import { bookFeatureKey, BookStateModel } from './book.reducer';

const getBookState = createFeatureSelector<BookStateModel>(bookFeatureKey);

const isFetchByIsbnLoading = createSelector(
  getBookState,
  (bookState: BookStateModel) => bookState.isFetchByIsbnLoading
);

const getFetchByIsbnError = createSelector(
  getBookState,
  (bookState: BookStateModel) => bookState.fetchByIsbnError
);

const getBook = createSelector(
  getBookState,
  (bookState: BookStateModel) => bookState.book
);

const isPurchaseLoading = createSelector(
  getBookState,
  (bookState: BookStateModel) => bookState.isPurchaseLoading
);

const getPurchaseError = createSelector(
  getBookState,
  (bookState: BookStateModel) => bookState.purchaseError
);

const isSearchLoading = createSelector(
  getBookState,
  (bookState: BookStateModel) => bookState.isSearchLoading
);

const getSearchError = createSelector(
  getBookState,
  (bookState: BookStateModel) => bookState.searchError
);

const getSearchResults = createSelector(
  getBookState,
  (bookState: BookStateModel) => bookState.searchResults
);

const isStartpageBookListLoading = createSelector(
  getBookState,
  (state: BookStateModel) => state.isStartpageBookListLoading,
)

const getStartpageBookList = createSelector(
  getBookState,
  (state: BookStateModel) => state.startpageBookList,
)

const getStartpageBookListError = createSelector(
  getBookState,
  (state: BookStateModel) => state.startpageBookListError,
)

export const bookSelectors = {
  isFetchByIsbnLoading,
  getFetchByIsbnError,
  getBook,
  isPurchaseLoading,
  getPurchaseError,
  isSearchLoading,
  getSearchError,
  getSearchResults,
  isStartpageBookListLoading,
    getStartpageBookList,
  getStartpageBookListError,
};
