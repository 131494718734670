import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../environments/environment';
import { UserService } from '../providers/user.service';
import { takeUntil } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import { Router } from "@angular/router";

export interface Students {
  username: string;
  _id: string;
  role: string;
}

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss'],
})
export class AdminDashboardComponent implements OnInit {
  isAdmin = false;
  isCoordinator = false;
  userType;
  userId;
  BOOKSTORE_ACTIVE = environment.bookstoreModule;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  isPermissionsCreditList: boolean = false;
  isPermissionsTokenList: boolean = false;
  isPermissionsDonationList: boolean = false;

  constructor(
    private authService : AuthService,
    private userService : UserService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.userType = this.authService.getType();
    this.userId = this.authService.getCurrentID();

    if (this.userType == 'admin') {
      this.isAdmin = true;
    }
    if (this.userType == 'coordinator') {
      this.isCoordinator = true;
    }

    this.userService.getUserbyId(this.userId).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(data => {
      console.log(data)
      if (data.hasOwnProperty('permissions')) {
        if (data["permissions"].includes("credit:list")) {
          this.isPermissionsCreditList = true;
        } else {
          this.isPermissionsCreditList = false;;
        }
        if (data["permissions"].includes("token:list")) {
          this.isPermissionsTokenList = true;
        } else {
          this.isPermissionsTokenList = false;;
        }
        if (data["permissions"].includes("donation:list")) {
          this.isPermissionsDonationList = true;
        } else {
          this.isPermissionsDonationList = false;;
        }
      }
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();

    const authInformation = this.authService.getIsAuth();
    if (authInformation) { 
      if (this.router.url.includes('/login') && this.userType == "admin") {
        this.router.navigate(["/admin-dashboard"]);
      } 
    }
  }
}
