<div class="session container-fluid pt-3">
  <div class="session_wrap row">
    <div class="col-md-12">

      <div class="table-navigation">
        <div class="filter-area">
          <div class="filter-wrap">
            <mat-form-field>
              <mat-label>Filter</mat-label>
              <input matInput (keyup)="applyFilter($event)" placeholder="Nachname, Vorname, Schule, Mentor" #input>
            </mat-form-field>
  
            <mat-checkbox (change)="mentorSelectionFilter($event)">Kinder ohne Mentor</mat-checkbox>

            <mat-checkbox (change)="nextMeetingSelectionFilter($event)">Kinder ohne zukünftigen Lesetermin</mat-checkbox>
          </div>
        </div>
        <div class="button-area" *ngIf="isAdmin">
          <a [routerLink]="['/student-create']">
            <mat-icon >person_add</mat-icon> Kind anlegen
          </a>
        </div>
      </div>

      <mat-table [dataSource]="dataSource" class="mat-elevation-z1" matSort matSortActive="username" matSortDisableClear matSortDirection="asc">
        <ng-container matColumnDef="preview-button">
          <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-icon (click)="openStudentProfil(element._id)">preview</mat-icon>
          </mat-cell>
        </ng-container>
        
        <ng-container matColumnDef="username">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Benutzername</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span>
            {{ element.username}}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="firstname">
            <mat-header-cell *matHeaderCellDef  mat-sort-header>Vorname</mat-header-cell>
            <mat-cell *matCellDef="let element">
                  {{ element.firstname}} 
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lastname">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Nachname</mat-header-cell>
            <mat-cell *matCellDef="let element">
                  {{ element.lastname }} 
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="school">
            <mat-header-cell *matHeaderCellDef>Schule</mat-header-cell>
            <mat-cell *matCellDef="let element">
                  <span *ngIf="element.school">{{ element.school?.name }} | {{ element.school?.city }}</span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="mentor">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Mentor</mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.mentor?.firstname }} {{ element.mentor?.lastname }} 
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="nextMeeting">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Nächster Lesetermin</mat-header-cell>
            <mat-cell *matCellDef="let element">
                  {{ element.nextMeeting | date:'short' }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="buttons">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let element" >
                <div>
                    <button mat-icon-button [matMenuTriggerFor]="moreMenu" aria-label="More">
                        <mat-icon class="secondary-text">more_vert</mat-icon>
                    </button>

                    <mat-menu #moreMenu="matMenu">
                        <button mat-menu-item aria-label="edit" [routerLink]="['/student-edit/' + element._id]">
                            <mat-icon>create</mat-icon>
                            <span>Bearbeiten</span>
                        </button>
                        <button mat-menu-item aria-label="match" *ngIf="!element.mentor && element.activeMembership" [routerLink]="['/student-matching/' + element._id]">
                            <mat-icon>link</mat-icon>
                            <span>Matching</span>
                        </button>
                        <button mat-menu-item aria-label="password" [routerLink]="['/change-password/' + element._id]">
                            <mat-icon>lock</mat-icon>
                            <span>Kennwort ändern</span>
                        </button>
                        <button mat-menu-item aria-label="remove" *ngIf="isAdmin" (click)="deleteUser(element._id, element.firstname, element.lastname)">
                          <mat-icon>delete</mat-icon>
                          <span>Löschen</span>
                        </button>
                    </mat-menu>
                </div>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
      <mat-paginator #paginator [pageSize]="10"></mat-paginator>
    </div>
  </div>
</div>