import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { combineLatest, Subscription, Subject } from 'rxjs';
import { Book } from 'src/app/bookstore/shared/models/book';
import { BookFacade } from 'src/app/bookstore/shared/store/book/book.facade';
import { WatchListDialogComponent } from '../watch-list-dialog/watch-list-dialog.component';
import { BookPreviewDialogComponent } from '../book-preview-dialog/book-preview-dialog.component';
import { BookPurchaseDialogComponent } from '../book-purchase-dialog/book-purchase-dialog.component';
import { environment } from "../../../../environments/environment";
import { formatCurrency } from "@angular/common";
import { AuthService } from '../../../auth/auth.service';
import { UserService } from "../../../providers/user.service";
import { BookService } from '../../../providers/book.service'

@Component({
  selector: 'app-book-details-dialog',
  templateUrl: './book-details-dialog.component.html',
  styleUrls: ['./book-details-dialog.component.scss'],
})
export class BookDetailsDialogComponent implements OnInit, OnDestroy {
  bookstoreServerUrl;
  book?: Book;
  // isLoading?: boolean;
  isLoading: boolean = true;
  error?: HttpErrorResponse;
  isbn: bigint;
  donation_available: boolean;
  isMentor: boolean = false;
  userId;
  userType;
  studentIsbnBooks: any = [];
  bookPurchaseable: boolean = false;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  showPrice: boolean = false;
  private fetchSubscription!: Subscription;
  public sessionStorage = sessionStorage;
  public localStorage = localStorage;
  userToken;
  mentorCredit: string = '0';

  constructor(
    private readonly dialog: MatDialog,
    private readonly bookFacade: BookFacade,
    private authService : AuthService,
    public userService: UserService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private bookService : BookService
  ) {
    let protocol = environment.ssl ? 'https://' : 'http://';
    this.bookstoreServerUrl = protocol + environment.bookstoreServerUrl + ":" + environment.bookstoreServerPort;
    this.isbn = data.isbn;
    this.donation_available = data.donation_available;
  }

  ngOnInit(): void {
    this.userId = this.authService.getCurrentID();
    this.userService.libById(this.userId).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(data => {
      console.log(data)
      if (data != undefined) {
        if (data.books.length > 0) {
          this.studentIsbnBooks = data.books;
          var foundBook = this.studentIsbnBooks.findIndex((item) => {
            return item.isbn === this.isbn;
          });
          console.log(foundBook)
          console.log(this.isbn)
          if (foundBook < 0) {
            this.bookPurchaseable = true;
            console.log('kauf mich')
          }
        } else {
          this.bookPurchaseable = true;
        }
      } else {
          this.bookPurchaseable = true;
      }
    });
    this.userType = this.authService.getType();
    if (this.userType == 'mentor' || this.userType == 'coordinator') {
      this.isMentor = true;
      var activeStudent = this.authService.getActiveStudent();
      this.mentorCredit = activeStudent["token"].filter(token => token.status == "active").length;
    }

    this.bookService.fetchByIsbn(BigInt(this.isbn)).pipe(
      takeUntil(this._unsubscribeAll)
        ).subscribe((res) => {
          this.book = res;
          this.book['donation_available'] = this.donation_available;
          console.log(this.book.description)
          this.isLoading = false;
    })

    // this.bookFacade.fetchByIsbn(BigInt(this.isbn));
    // this.fetchSubscription = combineLatest([
    //   this.bookFacade.isFetchByIsbnLoading$,
    //   this.bookFacade.fetchByIsbnError$,
    //   this.bookFacade.book$,
    // ]).subscribe(([isLoading, error, book]) => {
    //   this.isLoading = isLoading;
    //   this.error = error;
    //   this.book = book;
    // });

  }

  buyBook(): void {
    console.log(this.book)
    this.dialog.open(BookPurchaseDialogComponent, {
      width: '500px', data: {isbn: this.isbn, book: this.book}
    });
  }

  setBookonWatchlist(): void {
    this.dialog.open(WatchListDialogComponent, {
      width: '500px', data: {isbn: this.isbn, book: this.book}
    });
  }

  openPreview(): void {
    this.dialog.open(BookPreviewDialogComponent, {
      maxHeight: '100vh', restoreFocus: false,
      width: '115vh', data: {isbn: this.isbn, book: this.book}
    });
  }

  // public getPrice(price:number): string{
  //   return formatCurrency(price, "de-DE", "€");
  // }

  public getPrice(price:number): string{
    if (this.showPrice) {
      return formatCurrency(price, "de-DE", "€");
    } else{
        return price > 0 ? (this.donation_available ? "0 Token" : "1 Token") : "0 Token";
    }
  }

  ngOnDestroy(): void {
    // this.fetchSubscription.unsubscribe();
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
