import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../providers/user.service';
import { takeUntil } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import { MatTableDataSource,} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { AuthService } from "../auth/auth.service";
import { MatSnackBar } from '@angular/material/snack-bar';

export interface Students {
  username: string;
  _id: string;
  role: string;
}

@Component({
  selector: 'app-credit-administration',
  templateUrl: './credit-administration.component.html',
  styleUrls: ['./credit-administration.component.scss'],
})
export class CreditAdministrationComponent implements OnInit {
  displayedColumns: string[] = ['username', 'firstname', 'lastname', 'credit', 'annual', 'created', 'buttons'];
  dataSource:any;
	@ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  isAdmin: boolean = false;
  userType: string;
  dialogConfig: any;
  firstname: string;
  lastname: string;
  filterValues = {
    search: '',
    mentor: false,
    nextMeeting: false
  };
  userId;
  isPermissionsCreditAdd: boolean = false;

  students: Students[] = [];
  private studentsSub: Subscription;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(   
    private userService : UserService,
    public authService: AuthService,
    public snackBar: MatSnackBar,
    ) {}

  ngOnInit() {
    this.userType = this.authService.getType();
    this.userId = this.authService.getCurrentID();
    if (this.userType == 'admin') {
      this.isAdmin = true;
    }
    this.userService.getStudentsList(true);
    this.studentsSub = this.userService.getStudentsUpdateListener().pipe(
        takeUntil(this._unsubscribeAll)
    ).subscribe((students: Students[]) => {
        this.students = students;
        this.firstname = students["firstname"];
        this.lastname = students["lastname"];
        console.log(this.students)
        this.dataSource = new MatTableDataSource<Students>(students);
        // pagination and sort table
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (value, sortHeaderId) => value[sortHeaderId];
        this.dataSource.paginator = this.paginator;
        // Filter in nested array as well
        this.dataSource.filterPredicate = (data: any, filter) => {
            let positionFound = JSON.stringify(data).toLowerCase().indexOf(filter.search) != -1;
            return positionFound;
        };
      });

      this.userService.getUserbyId(this.userId).pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe(data => {
        if (data.hasOwnProperty('permissions')) {
          if (data["permissions"].includes("credit:add")) {
            this.isPermissionsCreditAdd = true;
          } else {
            this.isPermissionsCreditAdd = false;;
          }
        }
      });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filterValues['search'] = filterValue.trim().toLowerCase()
    this.dataSource.filter = this.filterValues;

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

increaseCredits(id, credit) {
      this.userService.updateUserCredits(id, {
            credit : credit
          }).pipe(
            takeUntil(this._unsubscribeAll)
          ).subscribe(res => {
            // update list
            let message = "Die Credits wurden erhöht.";
            this.userService.getStudentsList(true);
            this.snackBar.open(message, "",{
              duration: 3000,
              horizontalPosition: 'right'
            });
          }, (err) => {
            this.snackBar.open(err.error.message, "",{
              panelClass: 'snack-error',
              duration: 3000,
              horizontalPosition: 'right'
            });
      });
  }

}