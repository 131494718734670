import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource,} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../helpers/confirm-dialog/confirm-dialog.component';
import { SessionService } from '../providers/session.service';
import { AuthService } from '../auth/auth.service';
import { map, takeUntil } from 'rxjs/operators';
import { UserService } from '../providers/user.service';
import { environment } from '../../environments/environment';
import { SessionNoteDialogComponent } from '../session-note/session-note.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
import { QuizResultComponent } from '../quiz-result/quiz-result.component';


export interface Session {
  student: string[];
  mentor: string;
  filename: string;
  sessionReadTime: number;
  readingMode: string;
  quizResult: string;
}


@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.scss'],
})
export class SessionComponent implements OnInit {
  QUIZ_ACTIVE = environment.quizModule;
  BOOKSTORE_ACTIVE = environment.bookstoreModule
  // column name of the table
  displayedColumns: string[] = ['sessionReadTime', 'sessionGoalTime', 'readBooks', 'readingMode', 'created', 'filename', 'buttons'];
  dataSource:any;
  dialogConfig: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  private userSub: Subscription;
  session: Session[] = [];
  studentId: string;
  isMentor = true;
  isAdmin = false;
  showFilter = false;
  isCoordinator = false;
  activeStudentName;
  userType;
  pageSize = 5;
  totalReadingTime = 0; 
  totalBookCount = 0;
  totalPagesCount = 0;
  readingPointsIncreased = 0;
  //@Input('studentId') studentId: string;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sessionService : SessionService,
    private authService : AuthService,
    private userService : UserService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
    if (this.QUIZ_ACTIVE) {
      this.displayedColumns = ['sessionReadTime', 'sessionGoalTime', 'readBooks', 'quiz', 'readingMode', 'created', 'filename', 'buttons'];
    } 
  }

  ngOnInit() {
    this.studentId = this.route.snapshot.params['id'];
    this.userType =  this.authService.getType();
    if (this.userType == 'admin') {
      this.isAdmin = true;
    }
    if (this.userType == 'coordinator') {
      this.isCoordinator = true;
    }
    if (this.isAdmin || this.isCoordinator) {
      // If coordinator but mentor view
      if (!this.studentId) {
        if (this.QUIZ_ACTIVE) {
          this.displayedColumns = ['sessionReadTime', 'sessionGoalTime', 'readBooks', 'readingMode', 'student', 'quiz', 'created', 'filename', 'buttons'];
        } else {
          this.displayedColumns = ['sessionReadTime', 'sessionGoalTime', 'readBooks', 'readingMode', 'student', 'created', 'filename', 'buttons'];
        }
        this.pageSize = 9;
        this.showFilter = true;
      }
    }
    if (this.studentId) {
      this.sessionService.getListOfSession(this.studentId);     
    } else {
      this.sessionService.getListOfSession();     
    }
    if (!(this.userType == 'mentor' || this.userType == 'coordinator')) {
      this.isMentor = false;
    }
    if (!this.isAdmin) {
      this.activeStudentName = this.authService.getUsernameOfActiveStudent();
    }
    this.userSub = this.sessionService.getSessionUpdateListener().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((session:Session[]) => {
        this.session = session;
      
        console.log(this.session)
        this.dataSource = new MatTableDataSource<Session>(this.session);
        // pagination and sort table
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (value, sortHeaderId) => value[sortHeaderId];
        this.dataSource.paginator = this.paginator;
        // Filter in nested array as well
        this.dataSource.filterPredicate = (data: any, filter) => {
          const dataStr = JSON.stringify(data).toLowerCase();
          return dataStr.indexOf(filter) != -1; 
        };
    });
  }

  /**
   * Generate string of title, startPage end endPage
   */
  generateReadBooksColumn(row) {
    let string = '';
    if (row.readBooksIsbn.length > 0) {
      // Bookstore variant of read books
      let books = [];
      let isbns = this.getIsbnsOfReadBooks(row.readBooksIsbn);
      // Remove duplicate isbns
      isbns = isbns.filter(function(value, index){ return isbns.indexOf(value) == index });
      // Get first and last page by isbn
      isbns.forEach(isbn => {
        books.push({
          title: this.getDataOfReadBooksByIsbn(row.readBooksIsbn, isbn, 'title'),
          startPage: this.getDataOfReadBooksByIsbn(row.readBooksIsbn, isbn, 'startPage'),
          endPage: this.getDataOfReadBooksByIsbn(row.readBooksIsbn, isbn, 'endPage')
        });
      });
      books.forEach((element, index) => {
        if (element.title) {
          string += (index + 1) + '. ' + element.title;
        }
        if (element.startPage) {
          string += ' (Seite ' + element.startPage;
        }
        if (element.endPage > element.startPage) {
          string += '-' + element.endPage;
        }
        if (element.startPage) {
          string += ')';
        }
        string += '<br />';
      });
      return string;
    } else {
      // Old variant of read books
      if (row.readBooks.length > 0) {
        row.readBooks.forEach((element, index) => {
          if (element.book) {
            string += (index + 1) + '. ' + element.book.name;
          }
          if (element.startPage) {
            string += ' (Seite ' + element.startPage;
          }
          if (element.activePage > element.startPage) {
            string += '-' + element.activePage;
          }
          if (element.startPage) {
            string += ')';
          }
          string += '<br />';
        });
        return string;
      } else {
        return string;
      }
    }
  }

  /**
   * Get specific data by isbn
   */
   getDataOfReadBooksByIsbn(parts, isbn, searchTerm) {
    if (searchTerm == 'title') {
       return parts.filter(x => x.book === isbn).map(x => x.title)[0];
    } else if (searchTerm == 'startPage') {
      let obj = parts.filter(x => x.book === isbn).reduce(function(prev, curr) {
        return prev.displayPage < curr.displayPage ? prev : curr;
      });
      return obj.displayPage;
    } else if (searchTerm == 'endPage') {
      let obj = parts.filter(x => x.book === isbn).reduce(function(prev, curr) {
        return prev.displayPage > curr.displayPage ? prev : curr;
      });
      return obj.displayPage;
    } else {
      return '';
    }
  }

  /**
   * Get all Isbn
   */
  getIsbnsOfReadBooks(parts) {
    return parts.map(({ book }) => book);
  } 

  getAudio(filename) {
    return this.sessionService.getAudio(filename);
  }

  addNoteToSession(id) {
    this.dialogConfig = new MatDialogConfig();
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = false;
    //this.dialogConfig.width = '400px';
    this.dialogConfig.data = {
      title: 'Notiz hinzufügen',
      note: 'Diese Funktion steht noch nicht zur Verfügung.',
      accept: true,
      acceptIcon: '',
      acceptText: 'OK',
      decline: false,
      declineIcon: '',
      declineText: 'Nein',
      id
    };
    this.dialog.closeAll();
    let dialogRef = this.dialog.open(SessionNoteDialogComponent, this.dialogConfig);
  }

  deleteSession(id) {
      this.dialogConfig = new MatDialogConfig();
      this.dialogConfig.disableClose = true;
      this.dialogConfig.autoFocus = false;
      //this.dialogConfig.width = '400px';
      this.dialogConfig.data = {
        title: 'Achtung',
        note: 'Soll die Session wirklich gelöscht werden?',
        accept: true,
        acceptIcon: '',
        acceptText: 'Ja',
        decline: true,
        declineIcon: '',
        declineText: 'Nein'
      };
      this.dialog.closeAll();
      let dialogRef = this.dialog.open(ConfirmDialogComponent, this.dialogConfig);
      dialogRef.afterClosed().pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe(result => {
        if (result == 'ok') {
          this.sessionService.deleteSession({
              _id: id,
              sessionStatus: 'DELETED'
          });
        }
      });
  }

  increaseReadingPoints(element) {
    element.clicked = true;
    let userId = element.student._id;
    let sessionId = element._id;
    this.userService.getUserbyId(userId).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(res => {
        //  Get current rading points
        let currentReadingPoints = res["readingPoints"] ? res["readingPoints"] : 0;
        // increase readingPoints
        currentReadingPoints++;
        // Save to server
        this.userService.updateUser({
          _id : userId,
          readingPoints : currentReadingPoints
        }).pipe(
          takeUntil(this._unsubscribeAll)
        ).subscribe(res => {
          //console.log('new points earned')
          console.log(element._id)
          this.sessionService.updateSession({
            _id : sessionId,
            rated: true
          }).subscribe(res => {
            this.snackBar.open("Der Punktestand wurde um +1 erhöht.", "",{
              duration: 3000,
              horizontalPosition: 'right'
            });
          })
        });
    });
  }

  QuizResultDialog(id): void {
    const dialogRef = this.dialog.open(QuizResultComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: ['quiz-overlay-panel'],
      data: {
        id: id
      }
    });
  }



  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

}

