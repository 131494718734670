import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EbooksEpubService } from "../../providers/epub.service";
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from "@angular/forms";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs'

export class Book {
  _id: string;
  isbn?: bigint;
}


@Component({
  selector: 'app-book-create',
  templateUrl: './book-create-isbn.component.html',
  styleUrls: ['./book-create-isbn.component.scss'],
})
export class BookCreateISBNComponent implements OnInit {
  rForm: FormGroup;
  book: any;
  bookPicture: any;
  allowedFileTypes = '';
  uploadprogress: number;
  showprogress: boolean = false;
  color = 'primary';
  mode = 'determinate';
  private _unsubscribeAll: Subject<any> = new Subject<any>();


  constructor(
    public ebookEpubService: EbooksEpubService,
    private router: Router,
    private _formBuilder: FormBuilder
  ) { }

  ngOnInit() {
      //init form / input data and validator
      this.rForm = this._formBuilder.group({
        isbn  : ['', Validators.required],
      }); 
  }


   /**
   * submit data
   */
  submitForm(formDirective: FormGroupDirective) {
    if (this.rForm.invalid) {
      this.rForm.get("isbn").markAsTouched();
      return;
    }
    console.log(this.rForm.value.isbn)
    this.ebookEpubService.createByISBN(
      this.rForm.value.isbn
    ).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((res:any) => {
      formDirective.resetForm();
      this.rForm.reset();
    });
 
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

}
