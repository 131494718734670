<div class="container-fluid">
  <div class="row justify-content-center h-100">
      <div class="iframe-col col-lg-12 col-md-12 col-sm-12">
        <div class="close_gamelist_overlay_wrap">
          <mat-icon class="close_gamelist_overlay" mat-dialog-close>close</mat-icon> 
        </div>
        <div class="iframe-wrap">
          <iframe width="100%" height="100%" frameBorder="0" [src]="urlSafe"></iframe>
        </div>
      </div>
  </div>
</div>