<div class="c-book-list">
  <h2 class="c-book-list__heading">{{ title }}</h2>
  <div class="c-book-list__container">
    <div class="c-book-list__arrow--right" (click)="scroll(-200)"></div>
    <div class="c-book-list__list" #bookListContent>
      <!-- TODO: Replace readability score -->
      <app-book-horizontal-list-item
        *ngFor="let book of books"
        [readabilityScore]="book.difficulty"
        [price]="getPrice(book.price, book.donation_available)"
        [isbn]="book.isbn"
        [donation_available]="book.donation_available"
      ></app-book-horizontal-list-item>
    </div>
    <div class="c-book-list__arrow--left" (click)="scroll(200)"></div>
  </div>
</div>
