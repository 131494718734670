import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Subject,BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { formatDate } from '@angular/common';

export interface Notification {
  notificationId?: string
  description?: string;
  recipient?: any;
  type?: string;
  status?: string;
  readAt?: any;
  createdAt?: any;
}
 
@Injectable({
  providedIn: 'root'
})

export class NotificationService {
  private restServerUrl;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  notifications: Notification[] = [];
  notificationUpdated = new Subject<Notification[]>();
  notificationBadgeCount = new BehaviorSubject<number>(0);
  currentnotificationBadgeCount = this.notificationBadgeCount.asObservable();


  constructor(
    private http: HttpClient,
    private authService: AuthService
    ) {
    let protocol = environment.ssl ? 'https://' : 'http://';
    this.restServerUrl = protocol + environment.restServer + ":" + environment.restServerPort ;
  }

  getNotificationUpdateListener() {
    return this.notificationUpdated.asObservable();
  }

 
  getNotificationList() {
    this.http.get<Notification[]>(this.restServerUrl + '/api/notification/list')
    .pipe(map(data => data), takeUntil(this._unsubscribeAll))
        .subscribe(data => {
            this.notifications = data;
            this.notificationUpdated.next([...this.notifications]);
            this.checkUnreadNotifications();
        }, (error) => { 
        //   this.authenticationService.showToastOnError("Keine Verbindung zum Server")
        });
  }

  checkUnreadNotifications() {
      // filter unread messages
      let count = this.notifications.filter(note => note?.readAt === null).length;
      this.notificationBadgeCount.next(count);
  }
  

  updateNotification(data){
    console.log(data)
    const notifacationData: Notification = { 
      notificationId : data.notificationId,
      status: data.status
    };
    return this.http.post<Notification>(
            this.restServerUrl + "/api/notification/update",
            notifacationData,
          ).pipe(map(res => {
            let index =this.notifications.findIndex((item) => {
              return item['_id'] ===  data.notificationId;
            });
            console.log(index)
            if (index >= 0) {
              this.notifications[index].readAt = formatDate(new Date(), 'yyyyMMddhhmmss', 'en');
            }
            this.notificationUpdated.next([...this.notifications]);
            //this.decreaseNotificationBadgeCount();
            this.checkUnreadNotifications();
            return <Notification>res;
          }));
  }

  increaseNotificationBadgeCount() {
    var newcount:number = this.notificationBadgeCount.value;
    this.notificationBadgeCount.next(newcount + 1);
  }

  decreaseNotificationBadgeCount() {
    var newcount:number = this.notificationBadgeCount.value;
    if ( this.notificationBadgeCount.value > 0) {
      this.notificationBadgeCount.next(newcount - 1);
    }
  }

  addNotificationSocketCallback(notification) {
    this.notifications.push(notification);
    // Change order
    this.notifications.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1);
    // Update list
    this.notificationUpdated.next([...this.notifications]);
    this.checkUnreadNotifications();
  }

  resetNotificationBadgeCount() {
    this.notificationBadgeCount.next(0);
  }

}