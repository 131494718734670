import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from "../auth/auth-interceptor";
import { ErrorInterceptor } from "../error/error-interceptor";
import { AppRoutingModule } from '../app-routing.module';
import { MaterialModule } from '../material.module';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BookHorizontalListItemComponent } from './book-horizontal-list-item/book-horizontal-list-item.component';
import { BookHorizontalListComponent } from './book-horizontal-list/book-horizontal-list.component';
import { BookVerticalListItemComponent } from './book-vertical-list-item/book-vertical-list-item.component';
import { BookVerticalListComponent } from './book-vertical-list/book-vertical-list.component';
import { BookLibraryOverviewPageComponent } from './book-library-overview/book-library-overview.component';
import { SearchResultsPageComponent } from './search-results/search-results-page.component';
import { BookDetailsDialogComponent } from './dialogs/book-details-dialog/book-details-dialog.component';
import { BookPreviewDialogComponent } from './dialogs/book-preview-dialog/book-preview-dialog.component';
import { BookPurchaseDialogComponent } from './dialogs/book-purchase-dialog/book-purchase-dialog.component';
import { WatchListDialogComponent } from './dialogs/watch-list-dialog/watch-list-dialog.component';
import { ErrorDialogComponent } from './dialogs/error-dialog/error-dialog.component';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BookEffects } from './shared/store/book/book.effects';
import { BookFacade } from './shared/store/book/book.facade';
import { bookFeatureKey, bookReducer } from './shared/store/book/book.reducer';
import { UserEffects } from './shared/store/user/user.effects';
import { UserFacade } from './shared/store/user/user.facade';
import { userFeatureKey, userReducer, userLibFeatureKey, userLibReducer } from './shared/store/user/user.reducer';
import { SearchInputComponent } from './search-input/search-input.component';
import { BookPurchasesListModule } from './book-purchases-list/book-purchases-list.module';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { LoadingSpinnerModule } from "../helpers/loading-spinner/loading-spinner.module";
import { PipeModule } from '../pipes/pipe.module';

@NgModule({
  declarations: [
    BookHorizontalListItemComponent,
    BookHorizontalListComponent,
    BookVerticalListItemComponent,
    BookVerticalListComponent,
    BookLibraryOverviewPageComponent,
    SearchResultsPageComponent,
    BookDetailsDialogComponent,
    BookPreviewDialogComponent,
    BookPurchaseDialogComponent,
    WatchListDialogComponent,
    ErrorDialogComponent,
    SearchInputComponent,
  ],
  entryComponents: [
    BookHorizontalListItemComponent,
    BookHorizontalListComponent,
    BookVerticalListItemComponent,
    BookVerticalListComponent,
    BookLibraryOverviewPageComponent,
    SearchResultsPageComponent,
    BookDetailsDialogComponent,
    BookPreviewDialogComponent,
    BookPurchaseDialogComponent,
    WatchListDialogComponent,
    ErrorDialogComponent,
    SearchInputComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    FormsModule, 
    ReactiveFormsModule,
    LoadingSpinnerModule,
    MatDialogModule,
    PipeModule,
    StoreModule.forRoot({
        [bookFeatureKey]: bookReducer,
        [userFeatureKey]: userReducer,
        [userLibFeatureKey]: userLibReducer,
      }),
    EffectsModule.forRoot([BookEffects, UserEffects]),
    BookPurchasesListModule,
    NgxExtendedPdfViewerModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    BookFacade,
    UserFacade
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [
  ],
  exports: [
      BookHorizontalListItemComponent,
      BookHorizontalListComponent,
      BookVerticalListItemComponent,
      BookVerticalListComponent,
      BookLibraryOverviewPageComponent,
      SearchResultsPageComponent,
      BookDetailsDialogComponent,
      BookPreviewDialogComponent,
      BookPurchaseDialogComponent,
      WatchListDialogComponent,
      ErrorDialogComponent,
      SearchInputComponent,
    ]
})
export class BookstoreModule {}
