<div class="settings container-fluid pt-3">
  <div class="settings_wrap row justify-content-center">
      <div class="col-md-12">
        <form [formGroup]="rForm" #formDirective="ngForm">
          <div class="row">
            <div *ngIf="isMentor" class="col-md-6">
              <div class="student_info">
                <div class="row">
                  <div class="col-md-12">
                    <h2>
                        Einstellungen von
                        <span class="heading_name_wrap">
                          <span *ngIf="user['firstname']">{{ user["firstname"] }}</span> <span *ngIf="user['lastname']">{{ user["lastname"] }}</span>
                          <span *ngIf="!user['firstname'] && !user['lastname']">{{ user["username"] }}</span>
                        </span>
                    </h2>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-sm-6 col-xs-12">
                    <mat-form-field>
                      <fieldset class="disable_student_field" [disabled]="true">  
                          <input matInput formControlName="firstname" type="text" placeholder="Vorname">
                      </fieldset>
                    </mat-form-field>
                  </div>
                  <div class="col-md-6 col-sm-6 col-xs-12">
                    <mat-form-field>
                       <fieldset class="disable_student_field" [disabled]="true"> 
                          <input matInput formControlName="lastname"  type="text" placeholder="Nachname">
                       </fieldset>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-sm-6 col-xs-12">
                    <mat-form-field>
                      <input matInput formControlName="email"  type="email" placeholder="Email der Eltern" >
                    </mat-form-field>
                  </div>
                  <div class="col-md-6 col-sm-6 col-xs-12">
                    <mat-form-field>
                      <input matInput formControlName="phoneNumber"  type="text" placeholder="Telefonnummer der Eltern">
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-sm-6 col-xs-12">
                    <mat-form-field>
                      <input matInput formControlName="mobileNumber"  type="text" placeholder="Mobiltelefon der Eltern">
                    </mat-form-field>
                  </div>
                  <div class="col-md-6 col-sm-6 col-xs-12">
                    <mat-form-field (click)="birthdaypicker.open()">
                      <mat-label>Geburtstag</mat-label>
                      <input matInput formControlName="birthday" type="text" [matDatepicker]="birthdaypicker"> 
                      <mat-datepicker-toggle matSuffix [for]="birthdaypicker"></mat-datepicker-toggle>
                      <mat-datepicker #birthdaypicker></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 col-sm-12 col-xs-12">
                    <mat-form-field>
                      <mat-label>Besuchte Schulklasse</mat-label>
                      <mat-select formControlName="schoolClass">
                        <mat-option value="1">1</mat-option>
                        <mat-option value="2">2</mat-option>
                        <mat-option value="3">3</mat-option>
                        <mat-option value="4">4</mat-option>
                        <mat-option value="5">5</mat-option>
                        <mat-option value="6">6</mat-option>
                        <mat-option value="7">7</mat-option>
                        <mat-option value="8">8</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-sm-6 col-xs-12">
                      <!-- <mat-form-field>
                        <mat-label>nextMeeting</mat-label>
                        <input matInput formControlName="nextMeeting" type="text" [matDatepicker]="nextMeetingpicker">
                        <mat-datepicker-toggle matSuffix [for]="nextMeetingpicker"></mat-datepicker-toggle>
                        <mat-datepicker #nextMeetingpicker></mat-datepicker>
                      </mat-form-field>  -->
                      <mat-form-field (click)="picker.open()">
                        <mat-label>Nächster Lesetermin</mat-label>
                        <input matInput formControlName="nextMeeting" type="text" [ngxMatDatetimePicker]="picker">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <ngx-mat-datetime-picker #picker [defaultTime]="defaultTime"></ngx-mat-datetime-picker>
                      </mat-form-field>
                  </div>
                  <div class="col-md-6 col-sm-6 col-xs-12">
                    <mat-slide-toggle formControlName="recurringMeeting" color="primary">wiederkehrend</mat-slide-toggle>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6" *ngIf="isMentor">
              <h2>Wie lange soll das Lesekind lesen?</h2>
              <p>{{ readingTime }} Minuten</p>
              <mat-slider  formControlName="readingTime" thumbLabel tickInterval="1000" min="1" max="30" (input)="changeReadingTimeInstantly($event)"></mat-slider>
              <h2>Informationen</h2>
              <p *ngIf="readingAbilityRate">Einschätzung der Lesefähigkeit: <strong>{{ readingAbilityRate }}</strong></p>
              <p *ngIf="loanedDeviceSerial">Seriennummer Leihgerät: <strong>{{ loanedDeviceSerial }}</strong></p>
              <p class="coordinator-info" *ngIf="appSettings?.showContactPerson && appSettings?.showContactCoordinator">
                <strong>Koordinator*in:</strong> {{ coordinator?.firstname }} {{ coordinator?.lastname }}<br>
                <strong>Telefon:</strong> {{ coordinator?.phoneNumber }}<br>
                <span *ngIf="coordinator?.mobileNumber"><strong>Mobil:</strong> {{ coordinator?.mobileNumber }}<br></span>
                <strong>E-Mail:</strong> {{ coordinator?.email }}<br>
              </p>
            </div>
    
            <div *ngIf="!isMentor" class="col-md-12">
                <h2 class="align-center">Suche Dir eine Figur aus.</h2>
                <p class="align-center">Während Du mit Deinem Mentor oder alleine liest, durchläuft deine Figur eine Verwandlung. Sammle erfolgreiche Verwandlungen um Spiele freizuschalten.</p>    
                <mat-radio-group  formControlName="avatar" aria-label="Select an option">
                  <div class="row">
                    <div class="col-md-4 col-sm-4 col-xs-12">
                      <mat-radio-button value="dino" checked>
                        <img src="assets/metamorphose/dino_thumbnail.png" />
                      </mat-radio-button>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12">
                      <mat-radio-button value="butterfly">
                        <img src="assets/metamorphose/butterfly_thumbnail.png" />
                      </mat-radio-button>     
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12">
                      <mat-radio-button value="roboter">
                        <img src="assets/metamorphose/roboter_thumbnail.png" />
                      </mat-radio-button>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12">
                      <mat-radio-button value="princess">
                        <img src="assets/metamorphose/princess_thumbnail.png" />
                      </mat-radio-button>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12">
                      <mat-radio-button value="werewolf">
                        <img src="assets/metamorphose/werewolf_thumbnail.png" />
                      </mat-radio-button>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12">
                      <mat-radio-button value="monster">
                        <img src="assets/metamorphose/monster_thumbnail.png" />
                      </mat-radio-button>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12">
                      <mat-radio-button value="cat">
                        <img src="assets/metamorphose/cat_thumbnail.png" />
                      </mat-radio-button>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12">
                      <mat-radio-button value="eagle">
                        <img src="assets/metamorphose/eagle_thumbnail.png" />
                      </mat-radio-button>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12">
                      <mat-radio-button value="frog">
                        <img src="assets/metamorphose/frog_thumbnail.png" />
                      </mat-radio-button>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12">
                      <mat-radio-button value="honeybee">
                        <img src="assets/metamorphose/honeybee_thumbnail.png" />
                      </mat-radio-button>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12">
                      <mat-radio-button value="unicorn">
                        <img src="assets/metamorphose/unicorn_thumbnail.png" />
                      </mat-radio-button>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12" *ngIf="clientId == 'app.litcam.lese'">
                      <mat-radio-button value="litcam-lion">
                        <img src="assets/metamorphose/litcam-lion_thumbnail.png" />
                      </mat-radio-button>
                    </div>
                  </div>
                </mat-radio-group>
            </div>
            <div class="col-md-6">

            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <button mat-button color="accent" type="button" (click)="submitForm(formDirective)" class="size-big">Speichern</button>
            </div>
            <div class="col-md-6">
                <button mat-button color="accent" [routerLink]="['/dashboard']" type="button" class="size-big">Abbrechen</button>
            </div>
          </div>
        </form>
        <br />
        <hr>
        <br />
        <app-analytics *ngIf="!isMentor"></app-analytics>
        <div class="button-group" *ngIf="isMentor">
          <h2>Weitere Einstellungen</h2>
          <button mat-button color="accent" (click)="changePasswordForStudent()" type="button" class="size-big reset_stundent_password">Kennwort des Lesekindes ändern</button>
        </div>
      </div>
  </div>
</div>