import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Subject,BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { formatDate } from '@angular/common';

export interface Chat {
  chatId?: string
  description?: string;
  recipient?: any;
  sender?: any;
  type?: string;
  status?: string;
  readAt?: any;
  createdAt?: any;
}
 
@Injectable({
  providedIn: 'root'
})

export class ChatService {
  private restServerUrl;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  chats: Chat[] = [];
  chatUpdated = new Subject<Chat[]>();
  chatBadgeCount = new BehaviorSubject<number>(0);
  currentchatBadgeCount = this.chatBadgeCount.asObservable();
  userId;
  recipientId;
  userType;
  student;

  constructor(
    private http: HttpClient,
    private authService: AuthService
    ) {
    let protocol = environment.ssl ? 'https://' : 'http://';
    this.restServerUrl = protocol + environment.restServer + ":" + environment.restServerPort ;
  }

  getChatUpdateListener() {
    return this.chatUpdated.asObservable();
  }

  createChat(formData) {
    return this.http.post<any>(this.restServerUrl + "/api/chat/create", formData)
      .pipe(
        takeUntil(this._unsubscribeAll)
      );
  }

 
  getChatList(mentor, student) {
    this.http.get<Chat[]>(this.restServerUrl + '/api/chat/list/',
    { params: { mentorId: mentor, studentId: student } })
    .pipe(map(data => data), takeUntil(this._unsubscribeAll))
        .subscribe(data => {
            this.chats = data;
            this.chatUpdated.next([...this.chats]);
            this.checkUnreadChats();
        }, (error) => { 
        //   this.authenticationService.showToastOnError("Keine Verbindung zum Server")
        });
  }

  chatStudent(id){

  }

  checkUnreadChats() {
    this.userId = this.authService.getCurrentID();
    this.userType = this.authService.getType();
    this.student = this.authService.getActiveStudent();

    // filter unread messages
    if (this.userType == 'mentor' || this.userType == 'coordinator') {
      let count = this.chats.filter(note => note?.readAt === null && note?.recipient._id === this.userId && note["sender"] == this.student._id).length;
      this.chatBadgeCount.next(count);

    }
    if (this.userType == 'student') {
      let count = this.chats.filter(note => note?.readAt === null && note?.recipient._id === this.userId ).length;
      this.chatBadgeCount.next(count);
    }  

  }
  

  updateChat(data){
    console.log(data)
    const notifacationData: Chat = { 
      // chatId : data.chatId,
      recipient : data.recipient,
      sender: data.sender,
      status: data.status
    };
    return this.http.post<Chat>(
            this.restServerUrl + "/api/chat/update",
            notifacationData,
          ).pipe(map(res => {
            // let index =this.chats.findIndex((item) => {
            //   return item['_id'] ===  data.chatId;
            // });
            // console.log(index)
            // if (index >= 0) {
            //   this.chats[index].readAt = formatDate(new Date(), 'yyyyMMddhhmmss', 'en');
            // }
            this.chatUpdated.next([...this.chats]);
            //this.decreaseChatBadgeCount();
            this.checkUnreadChats();
            return <Chat>res;
          }));
  }

  increaseChatBadgeCount() {
    var newcount:number = this.chatBadgeCount.value;
    this.chatBadgeCount.next(newcount + 1);
  }


  decreaseChatBadgeCount() {
    var newcount:number = this.chatBadgeCount.value;
    if ( this.chatBadgeCount.value > 0) {
      this.chatBadgeCount.next(newcount - 1);
    }
  }

  addChatSocketCallback(chat) {
    this.chats.push(chat);
    // Change order
    //this.chats.sort((a, b) => a.createdAt > b.createdAt ? 1 : -1);
    // Update list
    this.chatUpdated.next([...this.chats]);
    this.checkUnreadChats();
  }

  addChatSocketCounter(chat) {
    this.increaseChatBadgeCount();
  }

  resetChatBadgeCount() {
    this.chatBadgeCount.next(0);
  }

}