<div class="book_donation container-fluid pt-3">
  <div class="book_donation_wrap row">
    <div class="col-md-12">
      <div class="table-navigation">
        <div class="filter-area">
          <mat-form-field>
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Schule, Postleitzahl oder Stadt" #input>
          </mat-form-field>
        </div>
        <div class="button-area" *ngIf="isAdmin">
          <a [routerLink]="['/admin-book-donation-create']">
            <mat-icon >post_add</mat-icon> Spende anlegen
          </a>
        </div>
      </div>
      <mat-table [dataSource]="dataSource" class="mat-elevation-z1" matSort matSortActive="user" matSortDisableClear matSortDirection="desc">
        <ng-container matColumnDef="isbn">
            <mat-header-cell *matHeaderCellDef  mat-sort-header>ISBN</mat-header-cell>
            <mat-cell *matCellDef="let element">
                  {{ element.isbn}} 
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="donor">
          <mat-header-cell *matHeaderCellDef  mat-sort-header>Spender</mat-header-cell>
          <mat-cell *matCellDef="let element">
                {{ element.donor }} 
          </mat-cell>
        </ng-container>
      
        <ng-container matColumnDef="contingent">
          <mat-header-cell *matHeaderCellDef  mat-sort-header>Menge</mat-header-cell>
          <mat-cell *matCellDef="let element">
                {{ element.contingent }} 
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="usedContingent">
          <mat-header-cell *matHeaderCellDef  mat-sort-header>Genutzt</mat-header-cell>
          <mat-cell *matCellDef="let element">
                {{ element.usedContingent }} 
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="stockContingent">
          <mat-header-cell *matHeaderCellDef  mat-sort-header>Bestand</mat-header-cell>
          <mat-cell *matCellDef="let element">
                {{ element.stockContingent }} 
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="created">
          <mat-header-cell *matHeaderCellDef  mat-sort-header>Erstellt am</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.created | date:'dd.MM.yyyy' }} 
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="buttons">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element" >
              <div>
                  <button mat-icon-button [matMenuTriggerFor]="moreMenu" aria-label="More" *ngIf="isPermissionsDonationAdd">
                      <mat-icon class="secondary-text">more_vert</mat-icon>
                  </button>

                  <mat-menu #moreMenu="matMenu">
                      <button mat-menu-item aria-label="add-note" (click)="purchaseBookDonation(element.isbn, element.contingent)" [disabled]="element.contingent == element.usedContingent">
                          <mat-icon>shopping_cart</mat-icon>
                          <span>Bücher kaufen</span>
                      </button>
                  </mat-menu>
              </div>
          </mat-cell>
        </ng-container>
    
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <mat-paginator #paginator  [pageSize]="10" [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
    </div>
  </div>
</div>

