import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { UserService } from '../providers/user.service';
import { WebSocketService } from '../providers/websocket.service';
import { WebrtcService } from '../providers/webrtc.service';
import { CapacitorService } from "../providers/capacitor.service";
import { environment } from '../../environments/environment';
import { Subscription, Subject } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../helpers/confirm-dialog/confirm-dialog.component';
import { takeUntil } from 'rxjs/operators';
import moment from 'moment';
import { IframeComponent } from '../helpers/iframe/iframe.component';
import { ChatService } from '../providers/chat.service';
import { formatCurrency, formatNumber } from "@angular/common";

export interface Students {
  username: string;
  _id: string;
  role: string;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  restServerUrl;
  isMentor: boolean = true;
  room;
  students: Students[] = [];
  currentMentor: string;
  user;
  userId: string;
  userType;
  userName;
  activeMembership: boolean = false;
  firstname;
  appSettings;
  activeStudent;
  dialogConfig: any;
  activeUsers = [];
  CLIENT_ID = environment.clientId;
  BOOKSTORE_ACTIVE = environment.bookstoreModule;
  showInstructionVideos: boolean = true;
  private appSettingsSubs: Subscription;
  private studentsSub: Subscription;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  nextMeeting;
  isInThePast = false;
  clientLogo = environment.clientLogo;
  fileUrl: string = '/files/instruction-videos/';
  mentorId;

  private chatBadgeCountSub: Subscription;
  chatBadgeCount;

  constructor(
    private router: Router,
    private authService : AuthService,
    private userService : UserService,
    public capacitorService: CapacitorService,
    private webSocketService: WebSocketService,
    public webRTC: WebrtcService,
    public dialog: MatDialog,
    private chatService : ChatService
  ) {
    console.log(environment)
    let protocol = environment.ssl ? 'https://' : 'http://';
    this.restServerUrl = protocol + environment.restServer + ":" + environment.restServerPort ;
  }

  ngOnInit() {
    this.userId = this.authService.getCurrentID();
    console.log(this.userId)
    this.userType = this.authService.getType();
    console.log(this.userType)
    this.userName = this.authService.getUsername();

    if (this.userType == 'mentor' || this.userType == 'coordinator') {
      // this.userService.getStudentsList();
      this.userService.getAllStudensOfMentor(this.userId);
      this.studentsSub = this.userService.getMentorStudentsUpdateListener().pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe((students: Students[]) => {
          this.students = students;
        });
    }
    console.log(this.userType)
    if (this.userType == "student") {
      this.userService.getUserbyId(this.userId).pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe(data => {
        this.mentorId = data["mentor"] ? data["mentor"]["_id"] : null;
        this.nextMeeting = data["nextMeeting"];
        this.activeMembership = data["activeMembership"] ? data["activeMembership"] : false;
        this.firstname = data["firstname"]
        console.log(data["firstname"])
        if (data["mentor"] != null) {
          this.currentMentor = data["mentor"]["firstname"] + ' ' + data["mentor"]["lastname"];
          this.authService.setCurrentMentor(data["mentor"]["_id"]);
        }
        let currentTime = moment.utc().format("YYYY-MM-DDTHH:mm:00.000[Z]");
        // console.log(currentTime)
        // console.log(this.nextMeeting )
        // Compare current date with meeting date to show info
        if (this.nextMeeting > currentTime) {
          this.isInThePast = true;
        }
        // Mentor id, this.userId = Student id
        if(this.mentorId != null && this.userId != undefined) {
          this.chatService.getChatList(this.mentorId, this.userId);
        }
        });
    }

    this.socketSettings();

    if (this.userType == 'mentor' || this.userType == 'coordinator') {
      this.chatService.getChatList(this.userId, this.room);
      this.webSocketService.listen('active-users').pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe((users) => {
        console.log(users)
        if (users) {
          this.activeUsers = users;
        }
      });
      // Listen for new users for active status
      this.webSocketService.listen('active-user-loggedin').pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe((user) => {
        if (user) {
          // Add user to active users array
          this.activeUsers[user.socketId] = user.userId;
        }
      });
      // Listen for left users for active status
      this.webSocketService.listen('active-user-loggedout').pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe((user) => {
        if (user) {
          // Remove user from active users array
          delete this.activeUsers[user.socketId];
        }
      });
      this.webSocketService.emit('request-active-users', { userId: this.userId });
      // Listen for call acceptance
      this.webSocketService.listen('call-accepted').pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe((data) => this.callGotAccepted(data));
      this.webSocketService.listen('direct-connection-test').pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe((data) => console.log(data));
    }
    // Get app settings
    this.appSettings = this.authService.getAppSettings();
    this.appSettingsSubs = this.authService.getAppSettingsListener()
      .subscribe(appSettings => {
        this.appSettings = appSettings;
    });

    // if (this.CLIENT_ID == 'app.mentor.lese') {
    //   this.showInstructionVideos = true;
    // }

    this.chatBadgeCountSub = this.chatService.currentchatBadgeCount.subscribe(currentchatBadgeCount => {
      this.chatBadgeCount = currentchatBadgeCount;
    });
    this.webSocketService.listen('chat').pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((data) => this.handleChat(data));
  }

   /**
   * get the room settings by Id of mentor or student
   */
  socketSettings() {
    if (this.userType == 'mentor' || this.userType == 'coordinator') {
      this.activeStudent = this.authService.getActiveStudent();
      if (this.activeStudent != undefined) {
        console.log(this.activeStudent)
        this.room = this.activeStudent._id;
      }
      this.activeMembership = true;
    } else {
      this.isMentor = false;
      this.room = this.authService.getCurrentID();
    }
  }

   /**
   * selectStudent from the list and create room with the selected ID
   */
  selectStudent(student) {
    this.activeStudent = student;
    this.room = this.activeStudent._id;
    this.authService.setActiveStudent(this.activeStudent);
    if (this.BOOKSTORE_ACTIVE) {
      // this.authService.prepareBookstoreForUser(this.room);
      this.userService.fetchById(this.room).pipe(
        takeUntil(this._unsubscribeAll)
          ).subscribe((res) => {
            console.log('bookstore:')
            console.log(res)
            sessionStorage.setItem("userId", this.room);
            sessionStorage.setItem("userAge", res.age.toString());
            let readingAbility = res.readingAbility ? res.readingAbility : 1;
            sessionStorage.setItem("userReadingAbility", readingAbility.toString());
            sessionStorage.setItem("userClubId", res.clubId.toString())
            sessionStorage.setItem("userRole", res.role)
            sessionStorage.setItem("userWallet", formatCurrency(res.credit, "de-DE", "€"));
            // Update credit after purchase cause of subscription
            this.authService.getUserCredit();
            this.activeMembership = true;
            // this.getUserCredit();
        })
    }
    this.chatService.getChatList( this.userId, this.room);
    // this.chatService.checkUnreadChats();


  }

  /**
   * Get Student status if online or offline
   */
  checkIfOnline(id) {
    return Object.keys(this.activeUsers).find(k=>this.activeUsers[k]===id) !== undefined;
  }

  /**
   *  navigate to profile/settings page
   */
  goToProfile() {
    this.router.navigate(["/settings/" + this.room]);
  }

  goToChat() {
    this.router.navigate(["/chat/" + this.room]);
    this.updateChat();
    this.chatService.resetChatBadgeCount();
  }

    /**
   * Listen for new chat and pass it to chat list
   */
  handleChat(chat) {
    console.log(this.room)
    console.log(chat.recipient)
    if(chat.sender == this.room) {
      this.chatService.addChatSocketCounter(chat);
    }
  }

  updateChat() {
    this.chatService.updateChat({
      recipient :  this.userId,
      sender: this.isMentor ? this.room : this.mentorId,
      status : "read",
    }).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((res) => {
      console.log(res)
    });
  }

  /**
   *  navigate to profile/settings page
   */
  goToSession() {
    this.router.navigate(["/session/" + this.room]);
  }

  /**
   *  navigate to book library
   */
  goToBookLibrary() {
    if (environment.bookstoreModule) {
      this.router.navigate(["/book-preview/"+ this.room]);
    } else {
      this.router.navigate(["/book-preview/"]);
    }
  }

  /**
   *  navigate to room page
   */
  videoCall() {
    //this.router.navigate(["/room/" + this.room])
    if (this.userType == 'mentor' || this.userType == 'coordinator') {
      this.callWaitForAcceptDialog();
    }
  }

  /**
   * Open modal waiting for student to accept the call
   */  
  callWaitForAcceptDialog() {
      // Send call request to student with device info
      this.webSocketService.emitTo('call-request', this.room, this.capacitorService.getDeviceInfo());
      this.dialogConfig = new MatDialogConfig();
      this.dialogConfig.disableClose = true;
      this.dialogConfig.autoFocus = false;
      //this.dialogConfig.width = '400px';
      this.dialogConfig.data = {
        title: 'Rufe an',
        note: 'Warte auf '+ this.activeStudent.username + ' ...',
        accept: false,
        acceptIcon: '',
        acceptText: '',
        decline: true,
        declineIcon: 'phone_disabled',
        declineText: 'Auflegen'
      };
      this.dialog.closeAll();
      let dialogRef = this.dialog.open(ConfirmDialogComponent, this.dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result == 'decline') {
          // Inform student of cancelling call to close modal
          this.webSocketService.emitTo('call-cancelled', this.room, '');
        }
      });
  }

  /**
   * Call accepted action
   */  
  callGotAccepted(deviceInfo) {
      console.log('accepted')
      // Set deviceinfo of call receiver
      this.webRTC.setPartnerDeviceInfo(deviceInfo);
      // Close all dialogs and route to room
      this.dialog.closeAll();
      this.router.navigate(["/room/" + this.room]);
  }

  testMessageToMentor() {
    this.webSocketService.emitTo('direct-connection-test', this.room, 'hello from ' + this.room);
  }

  openIframeAsOverlay(url) {
    url = this.restServerUrl + url
    let params = false;
    const dialogRef = this.dialog.open(IframeComponent, {
      width: '100%',
      height: '80%',
      autoFocus: false,
      panelClass: 'iframe-overlay-pane',
      data: {
        url: url,
        // mentorId: this.userId,
        params: params
      }
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(res => {
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
