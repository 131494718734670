<!-- <ion-header>
  <app-main-header></app-main-header>
</ion-header> -->

<!-- <ion-content class="no-padding">
  <ion-list>
    <ng-container *ngFor="let item of notifications">
      <ion-item (click)="updateNotification(item)" [routerLink]="['/app/duel-detail/' + item.duel.secret_id]">
        <ion-icon name="notifications-outline" slot="start" *ngIf="item?.readAt === null"></ion-icon>
        {{ item?.description }}</ion-item>
    </ng-container>
  </ion-list>
</ion-content> -->


<!-- <ion-content>
  <ion-list> -->

  <!-- <ion-item-sliding *ngFor="let notification of notifications">
      <ion-item (click)="updateNotification(notification)" [routerLink]="['/app/duel-detail/' + notification.duel.secret_id]">
        <ion-icon name="notifications-outline" slot="start" *ngIf="notification?.readAt === null"></ion-icon>
        {{ notification?.description }}
      </ion-item>

      <ion-item-options>
        <ion-button type="button" color="danger"  (click)="deleteNotification(notification)"><ion-icon name="trash"></ion-icon> Delete</ion-button>
      </ion-item-options>
    </ion-item-sliding> -->
    <!-- <app-swipe-item *ngFor="let notification of notifications; let i = index;" [notifications]="notification" (delete)="deleteNotification(notification)"></app-swipe-item>
  </ion-list>
</ion-content> -->

<div>
  <mat-selection-list [multiple]="false">
    <mat-list-option *ngFor="let notification of notifications">
          <span > {{ notification?.description }}</span>
    </mat-list-option>
  </mat-selection-list>
</div> 

