<mat-dialog-content>
  <h2>Möchtest Du das Buch wirklich auf Deine Merkliste setzen?</h2>
  <p>Dort kannst Du alle Bücher finden, die Du Dir gemerkt hast.</p>

  <div class="action-buttons">
    <button [mat-dialog-close]="true" class="btn btn-sm btn-secondary">
      Zurück
    </button>
    <button class="btn btn-sm btn-primary" (click)="rememberBook()">Auf Merkliste setzen</button>
  </div>
</mat-dialog-content>
