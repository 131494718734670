import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as UserActions from './user.actions';
import { userSelectors, userLibSelectors } from './user.selectors';

@Injectable()
export class UserFacade {
  isFetchByIdLoading$ = this.store.select(userSelectors.isFetchByIdLoading);
  fetchByIdError$ = this.store.select(userSelectors.getFetchByIdError);
  user$ = this.store.select(userSelectors.getUser);
  isLibByIdLoading$ = this.store.select(userLibSelectors.isLibByIdLoading);
  libByIdError$ = this.store.select(userLibSelectors.getLibByIdError);
  userlib$ = this.store.select(userLibSelectors.getUserLib);

  constructor(private readonly store: Store) {}

  fetchById(userId: string): void {
    return this.store.dispatch(UserActions.fetchById({ userId }));
  }

  libById(userId: string, clubId: number): void {
    return this.store.dispatch(UserActions.libById({ userId, clubId }));
  }
}
