<mat-dialog-content>
  <div>
    <h2>{{ data.title }}</h2>

    <p>{{ data.message }}</p>

    <div *ngIf="data.backButtonText" class="action-buttons">
      <button [mat-dialog-close]="true" class="btn btn-sm btn-secondary">
        {{ data.backButtonText }}
      </button>
    </div>
  </div>
</mat-dialog-content>
