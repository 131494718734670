import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { BookWithIsbn } from "../shared/models/book-startpage";
import { formatCurrency } from "@angular/common";
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-book-horizontal-list',
  templateUrl: './book-horizontal-list.component.html',
  styleUrls: ['./book-horizontal-list.component.scss'],
})
export class BookHorizontalListComponent {
  @Input() title: string = '';
  @Input() books: BookWithIsbn[] = [];
  showPrice: boolean = false;;

  @ViewChild('bookListContent', { read: ElementRef })
  public bookListContent?: ElementRef<any>;

  constructor(
    private authService : AuthService
    ) {}

  public scroll(offset: number): void {
    this.bookListContent?.nativeElement.scrollTo({
      left: this.bookListContent.nativeElement.scrollLeft + offset,
      behavior: 'smooth',
    });
  }

  public getPrice(price:number, donation_available: boolean): string{
    if (this.showPrice) {
      return formatCurrency(price, "de-DE", "€");
    } else {
        return price > 0 ? (donation_available ? "0 Token" : "1 Token") : "0 Token";
    }
    // return formatCurrency(price, "de-DE", "€");
  }
}

