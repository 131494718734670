import { Component, OnInit, Inject, Optional, Input  } from '@angular/core';
import {  MatDialogRef, MatDialog,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '../providers/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router} from "@angular/router";
import { WebSocketService } from '../providers/websocket.service';
import { SessionService } from '../providers/session.service';

export interface DialogData {
  startPage: number;
  activePage: number;
  bookId: string;
  bookName: string;
}

@Component({
  selector: 'app-reading-overlay',
  templateUrl: './reading-overlay.component.html',
  styleUrls: ['./reading-overlay.component.scss'],
})
export class ReadingOverlayComponent implements OnInit {

  constructor(
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: any,

    ){}
  
  ngOnInit() {
   }

}
