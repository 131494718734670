import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { BookWithIsbn } from "../../shared/models/book-startpage";
import { CurrencyPipe, formatCurrency } from "@angular/common";
import { WatchListDialogComponent } from "../watch-list-dialog/watch-list-dialog.component";
import { PurchaseBody } from "../../shared/models/purchase-body";
import { environment } from "../../../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { UserFacade } from '../../../bookstore/shared/store/user/user.facade';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../../../auth/auth.service';
import { UserService } from "../../../providers/user.service";
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
@Component({
  selector: 'app-book-purchase-dialog',
  templateUrl: './book-purchase-dialog.component.html',
  styleUrls: ['./book-purchase-dialog.component.scss'],
})
export class BookPurchaseDialogComponent implements OnInit {
  CLUB_ID = environment.clubId;
  private restServerUrl;
  private bookstoreServerUrl;
  public sessionStorage = sessionStorage;
  userId;
  book: BookWithIsbn;
  bookPrice: any;
  isbn: bigint;
  remainingWallet: string;
  wallet: string|null;
  token: any = 0;
  enoughCredit: boolean;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  showPrice: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private readonly dialog: MatDialog,
    private dialogRef: MatDialogRef<BookPurchaseDialogComponent>,
    private http: HttpClient,
    public snackBar: MatSnackBar,
    private readonly userFacade: UserFacade,
    public userService: UserService,
    private authService : AuthService
  ) {
    let protocol = environment.ssl ? 'https://' : 'http://';
    this.bookstoreServerUrl = protocol + environment.bookstoreServerUrl + ":" + environment.bookstoreServerPort;
    this.restServerUrl = protocol + environment.restServer + ":" + environment.restServerPort;
    this.book = data.book;
    this.isbn = data.isbn
    this.wallet = sessionStorage.getItem("userWallet");
    this.token = sessionStorage.getItem("userToken");
    let walletNum = 0.0;

    if (this.showPrice) {
      this.bookPrice = data.book.price;
      if (this.wallet != null) {
        let tempString = this.wallet.replace(/[^0-9,-]+/g,"").replace(/[,]/,".")
        walletNum = Number(tempString);
      }
      if ((walletNum - this.book.price) < 0){
        this.enoughCredit = false;
        this.remainingWallet = ""
      } else {
        this.enoughCredit = true;
        this.remainingWallet = this.getPrice((walletNum - this.book.price))
      } 
    } else {
      //this.bookPrice = data.book.price > 0 ? 1 : 0;
      this.bookPrice = data.book.price > 0 ? (this.book.donation_available ? 0 : 1) : 0;
      if (this.token > 0 || this.bookPrice == 0) {
        this.enoughCredit = true;
        let remainingToken = this.bookPrice > 0 ? Number(this.token) - 1 : Number(this.token);
        this.remainingWallet = this.getPrice(remainingToken);
      }
    }
  }

  ngOnInit() {
    this.userId = this.authService.getCurrentID();
  }

  purchaseBook(): void {
    if (this.book.donation_available) {
      this.http.post<any>(this.restServerUrl + '/api/bookDonation/redeemPrePurchasedDonation', {
          isbn: this.isbn
        },
        { headers: new HttpHeaders().set('Content-Type', 'application/json') })
      .pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe(res => {
        this.dialog.closeAll();
        this.snackBar.open("Kauf erfolgreich. Viel Spaß mit dem neuen Buch.", "",{
          duration: 3000,
          horizontalPosition: 'right'
        });
      }); 
    } else {
      let body: PurchaseBody;
      body = { userId: this.userId, isbn: this.isbn, clubId: this.CLUB_ID };
      console.log(body)
      this.http.post<void>(
        `${this.bookstoreServerUrl}/book/purchase`,
        body,{observe : "response"}).subscribe(response => {
          console.log(response)
          if (response.status == 204 || response.status == 200 ){
            this.dialog.closeAll();
            // Get updated user credits
            // this.userFacade.fetchById(this.userId);
            this.userService.fetchById(this.userId).pipe(
              takeUntil(this._unsubscribeAll)
                ).subscribe((res) => {
                  sessionStorage.setItem("userWallet", formatCurrency(res.credit, "de-DE", "€"));
                  // Update credit after purchase cause of subscription
                  this.authService.getUserCredit();
              })
            // Get updated user token
            this.userService.getUserbyId(this.userId).pipe(
                takeUntil(this._unsubscribeAll)
              ).subscribe(res => {
                  let userToken = res["token"].filter(token => token.status == "active").length;
                  sessionStorage.setItem("userToken", userToken);
                  this.authService.getUserToken();
              });  
            this.snackBar.open("Kauf erfolgreich. Viel Spaß mit dem neuen Buch.", "",{
              duration: 3000,
              horizontalPosition: 'right'
            });
          }
        },
        error => {
          if (error.status == 400) {
            this.dialog.closeAll();
            this.snackBar.open("Du hast dieses Buch bereits gekauft.", "",{
              panelClass: 'snack-error',
              duration: 3000,
              horizontalPosition: 'right'
            });
          }
        });
    }
  }

  addToWatchList(): void {
    this.dialog.open(WatchListDialogComponent, {
      width: '500px', data: {isbn: this.isbn, book: this.book}
    });
    this.dialogRef.close();
  }

  showFreeBooks(): void {
    throw new Error('Not Implemented');
  }

  // public getPrice(price:number): string{
  //   return formatCurrency(price, "de-DE", "€");
  // }
  public getPrice(price:number): string{
    if (this.showPrice) {
      return formatCurrency(price, "de-DE", "€");
    } else{
      return price + " Token";
    }
  }
}
