import { Component, OnInit } from '@angular/core';
import { BookService } from "../../providers/book.service";
import { FormBuilder, FormGroup, Validators, NgForm, FormGroupDirective } from '@angular/forms';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-admin-book-donation-create',
  templateUrl: './admin-book-donation-create.component.html',
  styleUrls: ['./admin-book-donation-create.component.scss'],
})
export class AdminBookDonationCreateComponent implements OnInit {
  rForm: FormGroup;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    public bookService: BookService,
    private router: Router,
    private _formBuilder: FormBuilder,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.rForm = this._formBuilder.group({
      isbn  : ['', Validators.required],
      donor     : [''],
      contingent  : ['', Validators.required]
    });
  }

  /**
   * submit data
   */
  submitForm(formDirective: FormGroupDirective) {
    if (this.rForm.invalid) {
      return;
    }
    console.log(this.rForm.value)

    this.bookService.createBookDonation(
      this.rForm.value.isbn,
      this.rForm.value.donor,
      this.rForm.value.contingent
    ).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((res:any) => {
      console.log(res)
      var message = "Spende für ISBN " + this.rForm.value.isbn + " angelegt";
      this.snackBar.open(message, "",{
        duration: 3000,
        horizontalPosition: 'right'
      });
      formDirective.resetForm();
      this.rForm.reset();
      this.router.navigate(["/admin-book-donation-list"]);
    });
  }
}
