<div class="container-fluid pt-3">
  <div class="register row justify-content-center">
      <div class="col-md-6 col-sm-12">
        <h1 class="pt-3">Buchspende anlegen</h1>
        <div class="register_wrap">
          <form [formGroup]="rForm" #formDirective="ngForm">

            <mat-form-field>
              <input matInput formControlName="isbn" type="number" placeholder="ISBN" required>
            </mat-form-field>

            <mat-form-field>
              <input matInput formControlName="contingent"  type="number" placeholder="Anzahl">
            </mat-form-field>

            <mat-form-field>
              <input matInput formControlName="donor"  type="text" placeholder="Spender">
            </mat-form-field>

            <button mat-button color="accent" type="button" (click)="submitForm(formDirective)" class="size-big" aria-label="Speichern">
                  Erstellen
            </button>
          </form>
        </div>
    </div>
  </div>
</div>