import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { BookService } from '../../../../providers/book.service';
import * as BookActions from './book.actions';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class BookEffects {
  fetchByIsbn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookActions.fetchByIsbn),
      switchMap((action) =>
        this.bookService.fetchByIsbn(action.isbn).pipe(
          map((book) => BookActions.fetchByIsbnSuccess({ book })),
          catchError((error) => of(BookActions.fetchByIsbnFail({ error })))
        )
      )
    )
  );

  purchaseByIsbn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookActions.purchaseByIsbn),
      withLatestFrom(of('someId')), // TODO: Add real user id
      mergeMap(([action, userId]) =>
        this.bookService.purchaseByIsbn(userId, action.isbn, environment.clubId).pipe(
          map(() => BookActions.purchaseByIsbnSuccess()),
          catchError((error) => of(BookActions.purchaseByIsbnFail({ error })))
        )
      )
    )
  );

  search$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookActions.search),
      withLatestFrom(of('someId')), // TODO: Add real user id
      mergeMap(([action]) =>
        this.bookService.search(action.searchText, action.userAge, action.readingAbility).pipe(
          map((searchResults) => BookActions.searchSuccess({ searchResults: searchResults.books })),
          catchError((error) => of(BookActions.searchFail({ error })))
        )
      )
    )
  );

  fetchByClub$ = createEffect(() => this.actions$.pipe(
    ofType(BookActions.fetchByClub),
    switchMap((action) => this.bookService.fetchByClub(action.clubId, action.userAge).pipe(
      map(books => {
        console.log(books)
        return BookActions.fetchByClubSuccess({books})}),
      catchError(error => of(BookActions.fetchByClubFail({error}))),
    )),
  ));

  constructor(
    private readonly actions$: Actions,
    private readonly bookService: BookService
  ) {}
}
