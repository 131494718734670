import { Injectable } from "@angular/core";
import * as io from 'socket.io-client';
import { Observable } from "rxjs";
import  $ from "jquery";
import { AuthService } from '../auth/auth.service';
import { environment } from '../../environments/environment';
import { Players }  from  '../games/multiplayer/game-uno/react-components/players.component';
import { Deck }  from  '../games/multiplayer/game-uno/react-components/deck.component';
// import { Card }  from  '../games-multiplayer/game-uno/react-components/card.component';
import { Board }  from  '../games/multiplayer/game-uno/react-components/board.component';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class WebSocketService {
    socket: SocketIOClient.Socket;
    isConnected: boolean = false;
    player = {};
    yc = $('.your_color');
    oc = $('.opponent_color');
    yourturn: boolean = false;
    url = window.location.href.split('/');
    room = this.url[this.url.length-1];
    userId;
    playersComponent: Players;

    text = {
      'yt' : "Du bist am Zug!",
      'nyt' : "Warte auf Gegner",
      'popover_h2_win' : "Du hast gewonnen!",
      'popover_h2_lose' : "Du hast verloren."
    }

    constructor(
        private authService : AuthService,
        public snackBar: MatSnackBar,
    ) {}

    /**
     *  initSocket / connect
     */
    initSocket(userId) {
      this.userId = userId;
      let protocol = environment.ssl ? 'https://' : 'http://';
      //this.socket = io.connect(protocol + environment.socketServerUrl + ':' + environment.socketServerPort, { query: {customId : this.userId } });

      this.socket = io.connect(protocol + environment.socketServerUrl + ':' + environment.socketServerPort, {
          reconnection: true,
          reconnectionDelay: 1000,
          reconnectionDelayMax : 5000,
          reconnectionAttempts: Infinity
      });

      // Send userId to server for login
      this.socket.on('connect', () => {
          console.log('connected to socketServer')
          this.isConnected = true;
          this.socket.emit('login', {
            userId: this.userId,
            // token: "abc"
          });
          // Rejoin room
          this.joinRoom(this.room)
      });
  
      this.socket.on('disconnect', () => {
          this.isConnected = false;
          console.log('disconnected from SocketServer')
      });


      // this.socket.on('reconnect_attempt', () => {
      //   console.log('socket reconnected userId: ' + this.userId)
      //   this.socket.emit('login', { userId: this.userId });
      // });
    }

    /**
     *  disconnectFromSocket
     */
    disconnectFromSocket() {
      this.emitTo('call-hangup',  this.room, '');
      this.socket.disconnect();
    }

    checkSocketStatus() {
      return this.isConnected;
    }

    /**
     *  getCurrentRoom
     */
    getCurrentRoom() {
      return this.room;
    }

    /**
     *  listen / subscribe data
     */
    listen(eventname: string) : Observable<any> {
        return new Observable((subscriber) => {
            this.socket.on(eventname, (data) => {
                //console.log(eventname)
                subscriber.next(data);
            })
        })
    }

    /**
     *  emit data
     */
    emit(eventname: string, data: any) {
        this.socket.emit(eventname, data);
    }

    /**
     * emitTo / ID and data
     */
    emitTo(eventname: string, room: string, data: any) {
        this.socket.emit(eventname, {
          room: 'r-' + room,
          data: data
        });
    }

    /**
     * join Room (socket)
     */
    joinRoom(roomId) {
        this.room = roomId;
        console.log('socket room: r-' + this.room);
        this.socket.emit('room-join', 'r-' + this.room);
    }

    /**
     * leave Room (socket)
     */
    leaveRoom(roomId) {
        this.room = roomId;
        this.socket.emit('room-leave', 'r-' + this.room);
    }

    socketOff(eventname: string) {
        this.socket.off(eventname);
    }

    /**
     * connectFort game
     */
    connectFour(mentorId, studentId, isMentor) {
      var _that = this;
      let gameId = 'r-' + _that.room;
      let playerId = isMentor ? mentorId : studentId;
      let playerName = isMentor ? 'Mentor' : 'Lesekind';

      $(function () {
          init();
          
          if (isMentor) {
              // create game as host
              setTimeout(() => {
                _that.socket.emit("game-connect-four-create-game",{
                  name: playerName,
                  playerId: mentorId,
                  opponentId: studentId,
                  gameId: gameId
                });      
              }, 1000);

              _that.socket.on("game-connect-four-joined-game", function(data) {
                if (data.gameId == gameId) {
                    // Both ready, start game
                    _that.socket.emit("game-connect-four-assign-players",{
                        gameId: gameId,
                        players: data.game.player1
                    });
                }
              });
          }

          if (!isMentor) {
            _that.socket.on("game-connect-four-hosted-game", function(data) {
                    _that.socket.emit("game-connect-four-join-game",{
                      gameId: gameId,
                      name: playerName,
                      playerId: playerId
                    });
            });
          }

          // Gameplay
          _that.socket.on('game-connect-four-assign', function(data) {
              $('.status').html(_that.text.nyt);
              if (data.players["id"] == _that.userId) {
                _that.player["id"] = data.players["id"];
                _that.yc.addClass('red');
                _that.oc.addClass('yellow');
                _that.player["color"] = 'red';
                _that.player["opponent"] = 'yellow';
              } else {
                _that.player["id"] = data.players["opponent"];
                _that.yc.addClass('yellow');
                _that.oc.addClass('red');
                _that.oc.addClass('show');
                _that.player["color"] = 'yellow';
                _that.player["opponent"] = 'red';
              }
          });
          
          _that.socket.on('game-connect-four-winner', function(data) {
            _that.oc.removeClass('show');
            _that.yc.removeClass('show');
            change_turn(false);
            for(var i = 0; i < 4; i++){
              $('.cols .col .coin#coin_'+data.winner.winner_coins[i]).addClass('winner_coin');
            }
        
            if (data.winner.winner != _that.player["id"]) {
              $('.status').html(_that.text.popover_h2_win);
            } else {
              $('.status').html(_that.text.popover_h2_lose);
            }
          });

          _that.socket.on('game-connect-four-board-initialized', function(data) {
              _that.socket.emit('game-connect-four-assign-players', {
                gameId: 'r-' + _that.room,
                players: data
              });
          });

          _that.socket.on('game-connect-four-no-winner', function() {
            _that.oc.removeClass('show');
            _that.yc.removeClass('show');
            change_turn(false);
            $('.status').html('Das Spiel ist unentschieden!');
          });
        
          _that.socket.on('game-connect-four-start', function(data) {
            console.log('my turn')
              change_turn(true);
              _that.yc.addClass('show');
          });
          
          _that.socket.on('game-connect-four-reset-board', function(data) {
            reset_board();
          });
        
          _that.socket.on('game-connect-four-move', function(data) {
            make_move(data.col+1, true);
            change_turn(true);
            _that.yc.addClass('show');
            _that.oc.removeClass('show');
          });
        
          _that.socket.on('game-connect-four-opponent-move', function(data) {
            if(!_that.yourturn){
              _that.oc.css('left', parseInt(data.col)*100);
            }
          });
          
          $('.cols > .col').mouseenter(function(){
            if(_that.yourturn){
              _that.yc.css('left', $(this).index()*100);
              _that.socket.emit('my_move', {col: $(this).index()});
            }
          });
        
          $('.cols > .col').click(function(){
            if (parseInt($(this).attr('data-in-col')) < 6) {
              if (_that.yourturn){
                var col = $(this).index()+1;
                make_move(col);
                _that.socket.emit('game-connect-four-make-move', {
                  room: 'r-' + _that.room,
                  player: _that.userId,
                  col: col-1
                });
                change_turn(false);
                _that.yc.removeClass('show');
                _that.oc.addClass('show');
              }
            }
          });
        
          /**
           * make_move
           */
          function make_move(col, other?){
              if (!other) other = false;
              var col_elm = $('.cols > .col#col_'+col);
              var current_in_col = parseInt(col_elm.attr('data-in-col'));
              col_elm.attr('data-in-col', current_in_col+1);
              var color = (other) ? _that.player["opponent"] : _that.player["color"];
              var new_coin = $('<div class="coin '+color+'" id="coin_'+(5-current_in_col)+''+(col-1)+'"></div>');
              col_elm.append(new_coin);
              new_coin.animate({
                top : 100*(4-current_in_col+1),
              }, 400);
          }
          
          function init() {
              $('.status').html('');
              _that.player["id"] = _that.userId;
              _that.yc.addClass('red');
              _that.oc.addClass('yellow');
              _that.player["color"] = 'red';
              _that.player["opponent"] = 'yellow';
          }

          /**
           * reset_board
           */
          function reset_board() {
              $('.cols .col').attr('data-in-col', '0').html('');
              _that.player = {};
              _that.yourturn = false;
              _that.yc.removeClass('yellow red');
              _that.oc.removeClass('yellow red');
              _that.yc.removeClass('show');
              _that.oc.removeClass('show');
              init();
          }

          /**
           * change_turn
           */
          function change_turn(yt) {
            setTimeout(() => {
              if (yt) {
                _that.yourturn = true;
                $('.status').html(_that.text.yt);
              } else {
                _that.yourturn = false;
                $('.status').html(_that.text.nyt);
              } 
            }, 0);
          }
      });
    }

    /**
     * reset game
     */
    connectFourClearEventlistener() {
        this.socketOff('game-connect-four-board-initialized');
        this.socketOff('game-connect-four-assign');
        this.socketOff('game-connect-four-winner');
        this.socketOff('game-connect-four-no-winner');
        this.socketOff('game-connect-four-start');
        this.socketOff('game-connect-four-reset-board');
        this.socketOff('game-connect-four-move');
        this.socketOff('game-connect-four-opponent-move');
    }

    /**
     * reset game
     */
    connectFourReset() {
        var _that = this;
        $(function () {
            $('.cols .col').attr('data-in-col', '0').html('');
            _that.yc.removeClass('yellow red');
            _that.oc.removeClass('yellow red');
            _that.yc.removeClass('show');
            _that.oc.removeClass('show');
        });
    }

    /**
     * game uno init
     */
    unoInit(mentorId, studentId, isMentor) {
      console.log('uno init')
      var _that = this;
      $(function () {
        let width = innerWidth;
        let height = innerHeight;
        let gameId = 'r-' + _that.room;
        let players = [];
        let cards = [];
        let playerName = isMentor ? 'Mentor' : 'Lesekind';
        let playerId = isMentor ? mentorId : studentId;
        let playerIndex = isMentor ? 0 : 1;
        let rematch = false;
        let contentBG = <HTMLElement>document.querySelector("#uno-bg");
        let colorMap = {
            "red":"#ff5555",
            "yellow":"#ffaa00",
            "green":"#55aa55",
            "blue":"#5555fd"
        }
        let methodClick = "click";
      
        let unoChooseColor = document.querySelector('#uno-color-choice');
        let chooseColorBtn = document.querySelector("#choosColorBtn");
              
              if (isMentor) {
                console.log('host uno')

                  // create game as host
                  setTimeout(() => {
                    _that.socket.emit("game-uno-create-game",{
                        name: playerName,
                        number: 0,
                        playerId: playerId,
                        gameId: gameId
                    });            
                  }, 1000);

                  _that.socket.on("game-uno-joined-game", function(data) {
                    if (data.gameId == gameId) {
                        // Both ready, start game
                        _that.socket.emit("game-uno-start-game",{
                            gameId: gameId
                        });
                    }
                  });
              }

              //joinGameBtn.click(function() {
              if (!isMentor) {
                console.log('waiting for hosted game')
                _that.socket.on("game-uno-hosted-game", function(data) {
                  console.log('join uno')
                  console.log(data)
                        _that.socket.emit("game-uno-join-game",{
                            gameId: gameId,
                            name: playerName,
                            playerId: playerId
                        });
                });
              }

              _that.socket.on("game-uno-game-created", function(data) {
                  if(gameId != data.gameId) return;
                  players = [];
                  for(let player of data.players){
                      players.push({
                          name: player.name,
                          number: player.number,
                          score:player.score
                      });
                  }
                  ReactDOM.unmountComponentAtNode(document.querySelector(".players ul"));
                  ReactDOM.unmountComponentAtNode(document.querySelector(".board"));
                  if(width <= 600){
                
                      // let openPlayersBtn = <HTMLElement>document.querySelector('.open-players');
                      //     openPlayersBtn.style.display = "block";
                      //     openPlayersBtn.addEventListener('click',() => {
                      //     let players = <HTMLElement>document.querySelector('.players ul');
                      //     players.style.display = 'block';
                      //     setTimeout(()=>{
                      //         players.style.display = 'none';
                      //     },1500);
                      // })
                  }
                  window.addEventListener('resize',() => {
                      width=innerWidth;
                      // let openPlayersBtn = <HTMLElement>document.querySelector('.open-players');
                      let players = <HTMLElement>document.querySelector('.players ul');
                      if(width <= 600){
                          players.style.display = "none";

                          // openPlayersBtn.style.display = "block";
                          // openPlayersBtn.addEventListener('click',() => {
                          //     players.style.display = 'block';
                          //     setTimeout(()=>{
                          //         players.style.display = 'none';
                          //     },1500);
                          // })
                      }else{
                          // openPlayersBtn.style.display = "none";
                          players.style.display = "block";
                      }
                  })
                  document.querySelector(".col").innerHTML = "";

                  ReactDOM.render(
                      React.createElement(Players, {
                          players: players,
                          index: playerIndex,
                          currentTurn: data.currentPlayerTurn
                      }, null),
                      document.querySelector(".players ul")
                  );

                  ReactDOM.render(
                      React.createElement(Board, {
                          currentCard: {
                            value: data.currentCard.value,
                            color: data.currentCard.color,
                            isspecial: data.currentCard.isspecial
                          }
                      }, null),
                      document.querySelector(".board")
                  );
          
                  contentBG.style.backgroundColor = colorMap[data.currenColor];
                  let drawBtn = <HTMLElement>document.createElement("button");
                      drawBtn.className = "waves-effect waves-light btn";
                      drawBtn.innerText = "Karte ziehen";
                  let endTurnBtn = <HTMLElement>document.createElement("button");
                      endTurnBtn.className = "waves-effect waves-light btn";
                      endTurnBtn.innerText = "Zug beenden";

                  drawBtn.addEventListener("click",() => {
                    _that.socket.emit("game-uno-draw-card",{
                          gameId: gameId,
                          playerId: playerId,
                          playerIndex: playerIndex
                      });
                  });

                  endTurnBtn.addEventListener("click",() => {
                    _that.socket.emit("game-uno-end-turn", {
                          gameId:gameId,
                          playerId:playerId,
                          playerIndex: playerIndex
                      })
                  })
                  document.querySelector(".col").appendChild(drawBtn);
                  document.querySelector(".col").appendChild(endTurnBtn);
              });

              _that.socket.on("game-uno-game-updated", function(data) {
                  if(gameId != data.gameId) return;
                  if(playerIndex == data.currentPlayerTurn && !data.cardDrawn){
                      _that.snackBar.open("Du bist am Zug.", "", {
                        duration: 2000,
                        verticalPosition: 'bottom',
                        horizontalPosition: 'center'
                      });
                  }
                  ReactDOM.unmountComponentAtNode(document.querySelector(".players ul"));
                  ReactDOM.unmountComponentAtNode(document.querySelector(".board"));
                  let animated = "";
                  if(!data.cardDrawn) animated = "animate__animated animate__bounce"
                  players = [];
                  for(let player of data.players){
                      players.push({
                          name: player.name,
                          number: player.number,
                          score:player.score
                      });
                  }
                  
                  ReactDOM.render(
                      React.createElement(Players, {
                          players: players,
                          index: playerIndex,
                          currentTurn: data.currentPlayerTurn
                      }, null),
                      document.querySelector(".players ul")
                    );

                    ReactDOM.render(
                      React.createElement(Board, {
                          currentCard: {
                            value: data.currentCard.value,
                            color: data.currentCard.color,
                            isspecial:data.currentCard.isspecial
                          },
                          animated: animated
                      }, null),
                      document.querySelector(".board")
                    );
                    contentBG.style.backgroundColor = colorMap[data.currenColor];
              
              });

              _that.socket.on("game-uno-get-cards", function(data) {

                  if(playerId != data.playerId) return;
                  cards = data.cards;

                  ReactDOM.unmountComponentAtNode(document.querySelector("#frame"));
                  ReactDOM.render(
                      React.createElement(Deck, {
                          cards: cards
                      }, null),
                      document.querySelector("#frame")
                  );
       
                  let cardsDoc = document.querySelectorAll(".card-deck");
                      cardsDoc.forEach(card =>{
                          card.addEventListener(methodClick,(e:any)=>{
                              let data = {
                                  gameId: gameId,
                                  playerIndex: playerIndex,
                                  card:{
                                      isspecial: e.target.attributes.isspecial.value=="1"?true:false,
                                      value: Number(e.target.attributes.value.value),
                                      color: e.target.attributes.color.value
                                  },
                                  cardIndex: Number(e.target.attributes.index.value),
                                  playerId: playerId
                              }
                              _that.socket.emit("game-uno-play-card",data);
                          })
                      });
              });

              // _that.socket.on("queueChanged",(data)=>{
              //     if(gameId != data.gameId)return;
              //     players = data.players;
              //     let playersCollection  = document.querySelector("#queue");
              //     playersCollection.innerHTML = "";
              //     let i =0;
              //     for(let player of players){
              //         let playerList= document.createElement('li');
              //         playerList.classList.add("collection-item")
              //         playerList.innerText = player.name;
              //         if(player.index == playerIndex)playerList.innerText += " (You)";
              //         playersCollection.appendChild(playerList);
              //         i++;
              //     }
              // });

              _that.socket.on("game-uno-choose-color",(data)=>{
                  if(data.gameId != gameId) return;
                  if(playerId != data.playerId)return;

                  unoChooseColor['style'].display = 'block';
                  chooseColorBtn.addEventListener("click",(e)=>{
                    let color = (e.target as HTMLTextAreaElement).getAttribute('data-color');
                      _that.socket.emit("colorIsChosen",{
                          gameId:gameId,
                          playerId:playerId,
                          playerIndex:playerIndex,
                          color:color
                      });
                    unoChooseColor['style'].display = 'none';
                  });
              });

              _that.socket.on("game-uno-wrong-move",(data)=>{
                  if(data.gameId != gameId || data.playerId != playerId)return;
                  _that.snackBar.open("Dieser Zug ist nicht möglich.", "", {
                    panelClass: 'snack-error',
                    duration: 2000,
                    verticalPosition: 'bottom',
                    horizontalPosition: 'center'
                  });
              });

              _that.socket.on("game-uno-wrong-turn",(data)=>{
                  if(data.gameId != gameId || data.playerId != playerId)return;
                  _that.snackBar.open("Warte bis du am Zug bist!", "", {
                    panelClass: 'snack-error',
                    duration: 2000,
                    verticalPosition: 'bottom',
                    horizontalPosition: 'center'
                  });
              });

              _that.socket.on("game-uno-can-not-draw",(data)=>{
                  if(data.gameId != gameId || data.playerId != playerId)return;
                  _that.snackBar.open("Du kannst keine weitere Karte mehr ziehen.", "", {
                    panelClass: 'snack-error',
                    duration: 2000,
                    verticalPosition: 'bottom',
                    horizontalPosition: 'center'
                  });
              });

              _that.socket.on("game-uno-error-in-request",(data)=>{
                  _that.snackBar.open(data.msg, "", {
                    panelClass: 'snack-error',
                    duration: 2000,
                    verticalPosition: 'bottom',
                    horizontalPosition: 'center'
                  });
              });

              _that.socket.on("game-uno-game-end",(data) => {
                  if(data.gameId != gameId)return;
                      if(data.playerId == playerId){
                          // Interaction
                          _that.snackBar.open("Glüchwunsch! Du hast gewonnen!", "", {
                            duration: 2000,
                            verticalPosition: 'bottom',
                            horizontalPosition: 'center'
                          });
                          // Restart game
                          _that.emitTo('game-uno-rematch', _that.getCurrentRoom(), {
                            gameId: data.gameId
                          });
                          // console.log({
                          //   confirmButtonColor:"#2c3e50",
                          //   cancelButtonColor:"#2c3e50",
                          //     icon: 'success',
                          //     title:"congtatulations!! You Won",
                          //     showCancelButton:true,
                          //     cancelButtonText:"reload",
                          //     confirmButtonText:"rematch" ,
                          //     allowOutsideClick:false,
                          //     allowEscapeKey:false,
                          //     allowEnterKey:false,              
                          // }).then(e=>{
                          //     console.log(e);
                          //     if(e.isDismissed){
                          //         window.location.href = '/';
                          //     }else{
                                  
                          //       _that.socket.emit("rematch",{
                          //             data: { gameId: gameId }
                          //         })
                          //     } 
                          // });
                      }else{
                          _that.snackBar.open("Das Spiel ist vorbei!", "", {
                            duration: 2000,
                            verticalPosition: 'bottom',
                            horizontalPosition: 'center'
                          });
                      }
              });

              _that.socket.on("game-uno-say-uno",(data)=>{
                  if(data.gameId != gameId)return;            
                      _that.snackBar.open("Yalah!", "", {
                        duration: 2000,
                        verticalPosition: 'bottom',
                        horizontalPosition: 'center'
                      });
              });

              _that.socket.on("game-uno-player-diconnnected",(data)=>{
                  if(data.gameId != gameId) return;
                      _that.snackBar.open("Der andere Spieler wurde getrennt.", "", {
                        panelClass: 'snack-error',
                        duration: 2000,
                        verticalPosition: 'bottom',
                        horizontalPosition: 'center'
                      });
              });

              _that.socket.on("game-uno-draw-two",(data)=>{
                  if(data.gameId != gameId)return;
                      _that.snackBar.open("Du musstest zwei Karten ziehen!", "", {
                        duration: 2000,
                        verticalPosition: 'bottom',
                        horizontalPosition: 'center'
                      });
              });

              _that.socket.on("game-uno-draw-four",(data)=>{
                  if(data.gameId != gameId)return;
                      _that.snackBar.open("Du musstest vier Karten ziehen!", "", {
                        duration: 2000,
                        verticalPosition: 'bottom',
                        horizontalPosition: 'center'
                      });
              });
          
              _that.socket.on("game-uno-skip-turn",(data)=>{
                  if(data.gameId != gameId)return;
                      _that.snackBar.open("Du bist wieder dran.", "", {
                        duration: 2000,
                        verticalPosition: 'bottom',
                        horizontalPosition: 'center'
                      });
              });
          
              _that.socket.on("game-uno-reverse-turn",(data)=>{
                  if(data.gameId != gameId)return;
                      _that.snackBar.open("Die Spielrichtung hat sich geändert.", "", {
                        duration: 2000,
                        verticalPosition: 'bottom',
                        horizontalPosition: 'center'
                      });
              });
          
              _that.socket.on("game-uno-change-index",(data)=>{
                  if(data.gameId != gameId || data.playerId != playerId)return;
                  playerIndex = data.newIndex;
              });
      });
    }

    unoClearEventlistener() {
      this.socketOff('joinedGame');
      this.socketOff('hostedGame');
      this.socketOff('gameCreated');
      this.socketOff('gameUpdated');
      this.socketOff('getCards');
      this.socketOff('chooseColor');
      this.socketOff('wrongMove');
      this.socketOff('wrongTurn');
      this.socketOff('cannotDraw');
      this.socketOff('errorInRequest');
      this.socketOff('gameEnd');
      this.socketOff('uno');
      this.socketOff('drawTwo');
      this.socketOff('drawFour');
      this.socketOff('skipTurn');
      this.socketOff('reverseTurn');
      this.socketOff('changeIndex');
  }
}