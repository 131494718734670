import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NotificationService } from '../providers/notification.service';
import { takeUntil } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';


export interface Notification {
  description?: string;
  recipient?: any;
  type?: string;
}
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  notifications: Notification[] = [];
  private notificationSub: Subscription;
  private _unsubscribeAll: Subject<void> = new Subject<void>();
  readAt;
  

  constructor(
    private notificationService : NotificationService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.notificationService.getNotificationList();
    this.notificationSub = this.notificationService.getNotificationUpdateListener().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((notifications: Notification[]) => {
      this.notifications = notifications;
      console.log(this.notifications)
    });
    this.notificationService.resetNotificationBadgeCount();
}

  updateNotification(item) {
    if (item?.readAt === null) {
      this.notificationService.updateNotification({
        notificationId :  item._id,
        status : "read",
      }).pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe((res) => {
      });
    }
  }

  sortBy(prop: string, direction: string) {
    if (direction == 'DESC')  {
      return this.notifications.sort((a, b) => a[prop] < b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
    } else {
      return this.notifications.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
    }
  }

  deleteNotification(item) {
    this.notificationService.updateNotification({
      notificationId :  item._id,
      status : "delete",
    }).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((res) => {
      // Remove deleted item from list
      this.notifications.splice(this.notifications.findIndex((notification) => {
        return notification['_id'] === item._id;
      }), 1);
      this.changeDetector.detectChanges();
    });
  }
  
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
