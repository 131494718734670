
  <div class="row mb-0">
    <div class="col-xs-12">
      <div class="navbar-wrap">
        <div class="volumen-control-navbar" *ngIf="showVolumeControlNavbar">
          <div class="my-control-navbar">
            <button mat-icon-button aria-label="Ton ausschalten" color="primary" (click)="toggleMuteSpeaker()" *ngIf="isMentor">
              <mat-icon *ngIf="volumeActive">volume_up</mat-icon>
              <mat-icon *ngIf="!volumeActive">volume_off</mat-icon>
            </button>
            
            <button mat-icon-button aria-label="Microfon ausschalten" color="primary" (click)="toggleMuteMicrophone()" *ngIf="isMentor">
              <mat-icon *ngIf="microphoneActive">mic</mat-icon>
              <mat-icon *ngIf="!microphoneActive">mic_off</mat-icon>
            </button>
  
            <button mat-icon-button aria-label="Kamera ausschalten" color="primary" (click)="toggleVideoStream()" *ngIf="isMentor">
              <mat-icon *ngIf="cameraActive">videocam</mat-icon>
              <mat-icon *ngIf="!cameraActive">videocam_off</mat-icon>
            </button>
          </div>

          <div class="partner-control-navbar" *ngIf="isMentor && readingMode == 'Tandem'">
            <button mat-icon-button aria-label="Student Microfon ausschalten" color="accent" (click)="toggleMutePartnerMicrophone()">
              <mat-icon *ngIf="partnerMicrophoneActive">mic</mat-icon>
              <mat-icon *ngIf="!partnerMicrophoneActive">mic_off</mat-icon>
            </button>
  
            <button mat-icon-button aria-label="Student Kamera ausschalten" color="accent" (click)="togglePartnerVideoStream()">
              <mat-icon *ngIf="partnerCameraActive">videocam</mat-icon>
              <mat-icon *ngIf="!partnerCameraActive">videocam_off</mat-icon>
            </button>
          </div>

          <!-- <button mat-icon-button aria-label="Videos einschalten" color="primary" (click)="playVideoStreams()" *ngIf="debug">
            Videos einschalten
          </button> -->
        </div>
        <div class="record-navbar" *ngIf="showRecordNavbar && isMentor && !isIOSAppBugHide" [hidden]="!readingModeActive">
          <button mat-button aria-label="Aufnahme starten" color="accent" (click)="recordingAction()" *ngIf="!recordingFinished">
            <div class="icon_wrap" *ngIf="recordingPaused">
              <mat-icon>fiber_manual_record</mat-icon> Start
            </div>
            <div class="icon_wrap" *ngIf="!recordingPaused">
              <mat-icon>pause</mat-icon> Pause
            </div>
          </button>
          <button mat-button aria-label="Aufnahme stoppen" color="accent" (click)="stopRecording('clicked')" *ngIf="recordingStarted">
            <div class="icon_wrap">
              <mat-icon>stop</mat-icon> Fertig & Hochladen
            </div>
          </button>
          <button mat-button aria-label="Aufnahme herunterladen" color="primary" (click)="upload()" *ngIf="recordingFinished && blobIsReady">
            <mat-icon>cloud_upload</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="progress-fullscreen" *ngIf="showprogress">
    <app-loading-spinner></app-loading-spinner>
    <mat-progress-bar
      class="example-margin"
      [color]="color"
      [mode]="mode"
      [(value)]="uploadprogress">
      </mat-progress-bar>
  </div>