import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { WebSocketService } from '../providers/websocket.service';
import { WebrtcService } from '../providers/webrtc.service';
import { AuthService } from '../auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { BookListComponent } from '../books/book-list/book-list.component';
import { GameListMultiplayerComponent } from '../games/multiplayer/game-list/game-list-multiplayer.component';
import { EbooksEpubService } from "../providers/epub.service";
import { FileDownloadService } from "../providers/filedownload.service";
import { CapacitorService } from "../providers/capacitor.service";
import { SessionService } from '../providers/session.service';
import { UserService } from "../providers/user.service";
import { takeUntil, map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { Insomnia } from '@ionic-native/insomnia/ngx';
import { IframeComponent } from '../helpers/iframe/iframe.component';
import { ReadingOverlayComponent } from '../reading-overlay/reading-overlay.component';
import { QuizComponent } from '../quiz/quiz.component';
import { QuizService } from '../providers/quiz.service';
// Bookstore
import { BookPurchasesListComponent } from '../bookstore/book-purchases-list/book-purchases-list.component';
import { BookService } from '../providers/book.service';
import { BookLibraryOverviewPageComponent } from '../bookstore/book-library-overview/book-library-overview.component';

//import moment from 'moment';

@Component({
  selector: 'app-room',
  templateUrl: './room.component.html',
  styleUrls: ['./room.component.scss'],
})
export class RoomComponent implements OnInit, OnDestroy {
  IS_APP = environment.isApp;
  QUIZ_ACTIVE = environment.quizModule;
  BOOKSTORE_ACTIVE = environment.bookstoreModule;
  roomId: string;
  mentorId: string;
  username: string;
  firstname: string;
  isMentor: boolean = false;
  scientificSurvey: boolean = false;
  readingMode: boolean = false;
  readingModeReadyCount: number = 0;
  readingModeReadyForRecord: boolean = false;
  readingModeIsPDF = false;
  readingModeIsEpub = false;
  gamingMode: boolean = false;
  quizMode: boolean = false;
  isRecording: boolean = false;
  activeGame: string = '';
  surveyUrl: string = 'https://www.digi-sapiens.de/fragebogen-kind';
  _id: string;
  url: string;
  books;
  book;
  bookActivePage = 1;
  bookStartPage = 1;
  bookActivePart = 0;
  studentBooks: any = [];
  studentIsbnBooks: any = [];
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  quizIsHidden: boolean = true;
  silentReading: boolean = false;
  quizPages: any = [];
  quizAvailable: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public webRTC: WebrtcService,
    public dialog: MatDialog,
    private webSocketService: WebSocketService,
    private authService : AuthService,
    public ebookEpubService: EbooksEpubService,
    private bookService: BookService,
    public fileDownloadService: FileDownloadService,
    public capacitorService: CapacitorService,
    private sessionService : SessionService,
    public userService: UserService,
    private insomnia: Insomnia,
    private quizService: QuizService
  ) { }

  ngOnInit() {
    //this.capacitorService.checkUserMediaStatus();
    this.username = this.authService.getUsername();
    this.firstname = this.authService.getFirstname();
    this.roomId = this.route.snapshot.params['id'];
    if (this.authService.getType() == 'mentor' || this.authService.getType() == 'coordinator') {
      // Set extra bg class
      this.isMentor = true;
      this.mentorId = this.authService.getCurrentID();
      this.scientificSurvey = this.authService.getScientificSurveyStatus();
      // Load last read book
      this.userService.getStudentBooks(this.roomId).pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe(data => {
        if (this.BOOKSTORE_ACTIVE) {
          if (data.length > 0) {
            this.studentIsbnBooks = data;
            let isbn = this.studentIsbnBooks[0].book;
            this.bookService.fetchByIsbn(BigInt(isbn))
              .pipe(map(data => data), takeUntil(this._unsubscribeAll))
              .subscribe(data => {
                // Modify callback data from bookstore for reuse with old logic
                data['isbn'] = isbn;
                data['bookStructure'] = data['structure'];
                data['bookActivePage'] = this.studentIsbnBooks[0].activePage;
                data['bookActivePart'] = this.studentIsbnBooks[0].part;
                this.book = data;
                this.bookActivePage= this.book.bookActivePage;
            });
          }
        } else {
          if (data.length > 0) {
            this.studentBooks = data;
          }
        }
        // log info on server
        this.userService.logData('tandem-start', 'and ' + this.roomId);
      });
    } else {
      this.mentorId = this.authService.getMentorOfChild();
      this.webSocketService.listen('room-settings').pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe((data) => this.setRoomSettingsFromMentor(data));
    }
    // Listen for handup
    this.webSocketService.listen('call-hangup').pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((data) => this.hangupCall());
    // Listen for book ready on both ends
    this.webSocketService.listen('book-reader-ready').pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((data) => this.changeReadingReadyState(data.ready));
    // Listen for quiz start on both ends
    this.webSocketService.listen('quiz-start').pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((data) => this.startQuizDialog(data));
    // ).subscribe((data) => this.startQuiz());
    // Prevent screen from sleeping
    //if (this.IS_APP) {
      this.webSocketService.listen('open-bookstore').pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe((data) => this.openBookstoreDialog());
      this.webSocketService.listen('close-bookstore').subscribe((data) => this.handleBookstoreClose());
      this.insomnia.keepAwake();
    //}
  }

  checkForQuiz() {
    if (this.QUIZ_ACTIVE) {
      if (this.quizPages.includes(this.bookActivePage)|| this.quizPages.includes(this.bookStartPage) ) {
        this.quizAvailable = true;
      } else {
        this.quizAvailable = false;
      }
    }
  }

  startQuizDialog(data): void {
    let startPage = data.startPage;
    let activePage = data.activePage;

    if (startPage > activePage) {
      startPage = data.activePage;
      activePage = data.startPage;
    }

    this.sessionService.setLatestSession(data.sessionsId);
    const dialogRef = this.dialog.open(QuizComponent, {
      width: '100%',
      disableClose: true,
      panelClass: ['tandem-overlay-pane', 'tandem-overlay-pane--video' , 'quiz-overlay-panel'],
      autoFocus: false,
      data: data
    });
    this.quizIsHidden = true;
  }

  mentorStartQuiz(){
    this.webSocketService.emitTo('quiz-start', this.webSocketService.getCurrentRoom(), {
      ready : true,
      bookId: this.book._id,
      bookName: this.book.name,
      startPage: this.bookStartPage > this.bookActivePage ? this.bookActivePage : this.bookStartPage,
      activePage: this.bookStartPage > this.bookActivePage ? this.bookStartPage : this.bookActivePage,
      sessionsId:  this.sessionService.getLatestSession()
    });
  }

  /**
   * open quiz selection modal
   */
  startQuiz(): void {
    const dialogRef = this.dialog.open(ReadingOverlayComponent, {
      width: '100%',
      panelClass: ['tandem-overlay-pane', 'tandem-overlay-pane--video'],
      autoFocus: false,
      disableClose: true,
      data: { 
        bookId: this.book._id,
        bookName: this.book.name,
        startPage: this.bookStartPage,
        activePage: this.bookActivePage
      }
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(res => {
      switch (res) {
        case 'yes':
          this.webSocketService.emitTo('quiz-start', this.webSocketService.getCurrentRoom(), {
            ready : true,
            bookId: this.book._id,
            bookName: this.book.name,
            startPage:this.bookStartPage > this.bookActivePage ? this.bookActivePage : this.bookStartPage,
            activePage: this.bookStartPage > this.bookActivePage ? this.bookStartPage : this.bookActivePage,
            sessionsId:  this.sessionService.getLatestSession()
          });
          // this.startQuiz();
          this.quizIsHidden = true;
          this.bookStartPage = this.bookActivePage;
          this.switchToQuizMode();
          this.quizAvailable = false;
          break;
  
        case 'later':
          this.quizIsHidden = true;
          this.bookStartPage = this.bookActivePage;
          this.silentReading = true;
          this.checkForQuiz();
          break;
  
        case 'no':
          this.quizIsHidden = true;
          this.bookStartPage = this.bookActivePage;
          this.quizAvailable = false;
          break;

        default:
          break;
      }
     
      // Set current active page before switching to new book
      // this.setActivePageOnBook(book);
    });
  }

  /**
   * open book list modal
   */
  openBooksDialog(): void {
    let dialogRef;
    if (this.BOOKSTORE_ACTIVE) {
      dialogRef = this.dialog.open(BookPurchasesListComponent, {
        width: '100%',
        autoFocus: false,
        panelClass: 'bookselection-overlay-panel',
        data: {
          canChooseBook: this.isMentor,
          userId: this.roomId
        }
      });    
    } else {
      dialogRef = this.dialog.open(BookListComponent, {
        width: '100%',
        autoFocus: false,
        data: { canChooseBook: this.isMentor }
      });
    }
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(book => {
      console.log(book)
      if (this.isMentor && book != undefined) {
        // Set current active page before switching to new book
        this.setActivePageOnBook(book);
        this.quizIsHidden = false;
      }
    });
  }

    /**
   * open book list modal
   */
  openBookstoreDialog(): void {
    let dialogRef;
    if (this.BOOKSTORE_ACTIVE) {
      dialogRef = this.dialog.open(BookLibraryOverviewPageComponent, {
        width: '100%',
        autoFocus: false,
        panelClass: 'bookstore-overlay-panel',
        data: {
          canChooseBook: this.isMentor,
          userId: this.roomId
        }
      });    
    }
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(book => {
        this.dialog.closeAll();
        this.webSocketService.emitTo('close-bookstore', this.webSocketService.getCurrentRoom(), "");
    });
  }

  handleBookstoreOpen() {
    this.webSocketService.emitTo('open-bookstore', this.webSocketService.getCurrentRoom(), "");
  }

  handleBookstoreClose() {
    this.dialog.closeAll();
  }

  /**
   * open game list modal
   */
  openGamesDialog(): void {
    const dialogRef = this.dialog.open(GameListMultiplayerComponent, {
      width: '100%',
      autoFocus: false
      // data: {id: this._id, url: this.url}
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(game => {
      if (this.isMentor && game != undefined) {
        // Set active game to show
        this.activeGame = game;
        this.switchToGamingMode();
      }
    });
  }

  switchToQuizMode() {
    this.readingMode = false;
    this.gamingMode = false;
    this.quizMode = true;
    this.setActivePageOnBook();
    // Inform stundent with new settings
    this.sendSocketRoomSettings();
  }

  setReaderByType() {
    if (this.book.format == 'epub') {
      this.readingModeIsPDF = false;
      this.readingModeIsEpub = true; 
    } else {
      this.readingModeIsPDF = true;
      this.readingModeIsEpub = false; 
    }
  }

  setActivePageOnBook(book?) {
    //console.log(book)
    if (this.book != undefined) {
      // Find current book
      if (this.BOOKSTORE_ACTIVE) {
        var foundBook = this.studentIsbnBooks[this.studentIsbnBooks.findIndex((item) => {
          return item.book === this.book.isbn;
        })];
      } else {
        var foundBook = this.studentBooks[this.studentBooks.findIndex((item) => {
          return item.book === this.book._id;
        })];
      }
      // Change active page of current book
      if (foundBook) {
        foundBook.activePage = this.bookActivePage;
        foundBook.lastRead = new Date().toISOString();
        // Set active page for game switch back to reading
        this.book['bookActivePage'] = this.bookActivePage;
      } else {
        if (this.BOOKSTORE_ACTIVE) {
          this.studentIsbnBooks.push({
            book: this.book.isbn,
            part: this.bookActivePart,
            activePage: this.bookActivePage,
            lastRead: new Date().toISOString()
          });
        } else {
          this.studentBooks.push({
            book: this.book._id,
            activePage: this.bookActivePage,
            lastRead: new Date().toISOString()
          });
        }
      }
    }
    // Set new book
    if (book) {
      let bookId = '';
      if (this.BOOKSTORE_ACTIVE) {
        // Add data on book change to set active page
        let bookClone =  JSON.parse(JSON.stringify(book));
        bookClone['bookActivePage'] = this.ebookEpubService.getActivePageFromStoredBook(bookClone.isbn, this.studentIsbnBooks);;
        this.book = bookClone;
        bookId = this.book.isbn;
      } else {
        this.book = book;
        bookId = this.book._id;
      }
      // set reading mode
      this.switchToReadingMode();
    }
  }

  updateReadBooksOnStudent() {
    if (this.book) {
      this.setActivePageOnBook();
      // Update student books in db
      if (this.BOOKSTORE_ACTIVE) {
        this.userService.updateUser({
          _id : this.roomId,
          studentIsbnBooks : this.studentIsbnBooks
        }).pipe(
          takeUntil(this._unsubscribeAll)
        ).subscribe(res => {
          console.log(res)
        });
      } else {
        this.userService.updateUser({
          _id : this.roomId,
          studentBooks : this.studentBooks
        }).pipe(
          takeUntil(this._unsubscribeAll)
        ).subscribe(res => {
          console.log(res)
        });
      }
    }
  }

  getActivePage(page) {
    this.bookActivePage = page;
    console.log('activePage from child: ' +  this.bookActivePage)
    if (this.isRecording) {
      if (this.BOOKSTORE_ACTIVE) {

      } else {
        // Update page in current book
        this.sessionService.changeActivePageInReadBook(this.book._id, this.bookActivePage);
      }
    }
    //silent reading + quiz
    if (this.silentReading == true) {
      this.checkForQuiz() 
    }
  }

  getActivePart(part) {
    this.bookActivePart = part;
  }

  getIsRecording(status) {
    this.isRecording = status;
    this.setReadBook();
    
    if (status == false) {
      this.checkForQuiz();
      setTimeout(() => {
        if (this.quizAvailable === true) {
          this.startQuiz();
        }
      }, 0);
    }
  }

  /**
   * switchToReadingMode
   */
  switchToReadingMode() {
    if (this.book == undefined) {
      this.openBooksDialog();
    } else {
      // Check for active page to continue reading
      if (this.BOOKSTORE_ACTIVE) {
        this.bookActivePage = this.ebookEpubService.getActivePageFromStoredBook(this.book.isbn, this.studentIsbnBooks);
        // log info on server
        this.userService.logData('tandem-read', 'and ' + this.roomId + ' book ' + this.book.isbn);
      } else {
        this.bookActivePage = this.ebookEpubService.getActivePageFromStoredBook(this.book._id, this.studentBooks);
        this.setReadBook();
        // log info on server
        this.userService.logData('tandem-read', 'and ' + this.roomId + ' book ' + this.book._id);
      }
      this.setReaderByType();
      this.readingMode = true;
      this.gamingMode = false;
      // Inform stundent with new settings
      this.sendSocketRoomSettings();
    }
  }

  changeReadingReadyState(val) {
    this.readingModeReadyCount++;
    if (this.readingModeReadyCount == 2) {
      this.readingModeReadyForRecord = val;
      this.readingModeReadyCount = 0;
    }
  }

  setReadBook() {
    if (this.isRecording) {
      if (this.BOOKSTORE_ACTIVE) {
      } else {
        this.bookStartPage = this.bookActivePage;
        this.sessionService.setReadBook(this.book._id, this.bookStartPage, this.bookActivePage);
      }
    }
  }

  /**
   * download book for faster reading
   */
  downloadBookAnOpen() {
    if (this.book != undefined) {
      // Inform stundent with new settings
      //this.sendSocketRoomSettings();

      // // Capacitor file storage read file testing
      // // Save file
      // this.capacitorService.fileWrite(this.book._id + '.' + this.book.format, base64Data);
      // // Load file
      // this.capacitorService.fileRead().then(
      //   (file) => {
      //     console.log(file)
      //     this.bookUrl = file.data;
      //     if (this.book.format == 'pdf') {
      //       this.readingModeIsPDF = true;
      //       this.readingModeIsEpub = false; 
      //     } else {
      //       this.readingModeIsPDF = false;
      //       this.readingModeIsEpub = true; 
      //     }
      //     // Inform stundent with new settings
      //     //this.sendSocketRoomSettings();
      //     this.switchToReadingMode();   
      //   },
      //   (err) => {
      //     console.log('File not found', err);
      //   }
      // );

      // // LocalStorage file saving testing
      // this.fileDownloadService
      //     .getStoredFile('book-'+book._id, this.ebookEpubService.getBook(book.filename))
      //     .subscribe((base64Data: string) => {
      //       this.bookUrl = base64Data;
      //       if (this.book.format == 'pdf') {
      //         this.readingModeIsPDF = true;
      //         this.readingModeIsEpub = false; 
      //       } else {
      //         this.readingModeIsPDF = false;
      //         this.readingModeIsEpub = true; 
      //       }
      //       // Inform stundent with new settings
      //       //this.sendSocketRoomSettings();
      //       this.switchToReadingMode();    
      //     });
    }
  }

   /**
   * switchToGamingMode
   */
  switchToGamingMode() {
    this.readingMode = false;
    this.gamingMode = true;
    this.setActivePageOnBook();
    // Inform stundent with new settings
    this.sendSocketRoomSettings();
  }

  /**
   * hangup the call
   */
  hangupCall() {
    //this.webRTC.hangup();
    if (this.router.url.includes('/room')) { 
      this.router.navigate(["/dashboard"]);   
    }
  }

  /**
   * sendSocketRoomSettings/ book, mode, pdf or epub, 
   */
  sendSocketRoomSettings(): void {
    this.webSocketService.emitTo('room-settings', this.webSocketService.getCurrentRoom(), {
      book : this.book,
      bookActivePage: this.bookActivePage,
      readingMode : this.readingMode,
      readingModeIsPDF : this.readingModeIsPDF,
      readingModeIsEpub : this.readingModeIsEpub,
      gamingMode: this.gamingMode,
      activeGame: this.activeGame,
      quizMode: this.quizMode,
    }); 
  }

  /**
   * setRoomSettingsFromMentor/ book, mode, pdf or epub, 
   */
  setRoomSettingsFromMentor(data) {
    this.book = data.book;
    this.bookActivePage = data.bookActivePage
    this.readingMode = data.readingMode;
    this.readingModeIsPDF = data.readingModeIsPDF;
    this.readingModeIsEpub = data.readingModeIsEpub;
    this.gamingMode = data.gamingMode;
    this.activeGame = data.activeGame;
    this.quizMode = data.quizMode;
  }

  /**
   * open overlay for scietific survey
   */  
   startScientificSurvey(url) {
    const dialogRef = this.dialog.open(IframeComponent, { 
      width: '100%',
      height: '80%',
      autoFocus: false,
      panelClass: ['iframe-overlay-pane', 'iframe-overlay-pane--video'],
      data: {
        url: url,
        mentorId: this.mentorId,
        studentId: this.roomId,
        params: true
      }
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(res => {
    });
  }

  /**
   * ngOnDestroy
   */
  ngOnDestroy() {
    // Save read book only on mentor side
    if (this.isMentor) {
      this.updateReadBooksOnStudent();
      // log info on server
      this.userService.logData('tandem-end', 'and ' + this.roomId);
    }
    this.webRTC.hangup();
    this.webSocketService.emitTo('call-hangup', this.webSocketService.getCurrentRoom(), '');
    // Unsubscribe from all subscriptions
    setTimeout(() => {
      //console.log('room unsubscribe -------------')
      this._unsubscribeAll.next(true);
      this._unsubscribeAll.complete();
    }, 500);
    // Allow sleep mode again
    //if (this.IS_APP) {
      this.insomnia.allowSleepAgain();
    //}
  }
}