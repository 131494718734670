import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../environments/environment';
import { ConfirmDialogComponent } from '../helpers/confirm-dialog/confirm-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CapacitorService } from './capacitor.service';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
// Debug
//import { UserService } from "../providers/user.service";

export interface AppMaintenance {
    maintenance?: {
        enabled: boolean;
    };
    ios?: {
        latest: string;
        minimum: string;
        url: string;
        enabled: boolean;
    };
    android?: {
        latest: string;
        minimum: string;
        url: string;
        enabled: boolean;
    };
    alerts?: {
        maintenance?: {
            title: string;
            text: string;
            btn: string;
        };
        mandatory?: {
            title: string;
            text: string;
            btn: string;
            iosAddText: string;
            androidAddText: string;
        };
        optional?: {
            title: string;
            text: string;
            btn: string;
        };
    };
}
 
@Injectable({
  providedIn: 'root'
})

export class UpdateService {
  private maintenanceUrl;
  IS_APP = environment.isApp;
  dialogConfig: any;
  deviceInfo: any;
  mandatoryConfig: any;
  private _unsubscribeAll: Subject<any>;

    constructor(
        private http: HttpClient,
        private capacitorService: CapacitorService,
        public dialog: MatDialog,
        //public userService: UserService
    ) {
        this.maintenanceUrl = 'https://updates.digi-sapiens.de/lese-app/'+ environment.clientId +'/maintenance.json';
    }

    async checkForMaintenanceAndUpdates() {
        this.deviceInfo = this.capacitorService.getDeviceInfo();
        this.http.get(this.maintenanceUrl, {
            headers: new HttpHeaders()
              .set('Cache-Control', 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0')
              .set('Pragma', 'no-cache')
              .set('Expires', '0')
        }).pipe(map(data => data)).subscribe( async (data: AppMaintenance) => {
            this.dialog.closeAll();

                // App version
                let cVersion = this.deviceInfo.appVersion;
                // Android version
                let aVersion = data.android.latest;
                let aMinimumVersion = data.android.minimum;
                // this.userService.logData('debug FE', 'f update checked');
                // this.userService.logData('debug FE', 'f currentV ' + cVersion);
                // this.userService.logData('debug FE', 'f androidV ' + aVersion);
                // this.userService.logData('debug FE', 'f androidM ' + aMinimumVersion);
                           
            const maintenanceMode = data.maintenance.enabled;
            if (maintenanceMode) {
                this.presentAlert(data.alerts.maintenance.title, data.alerts.maintenance.text, data.alerts.maintenance.btn);
            } else {        
                if(this.IS_APP) {      
                    // App version
                    const currentVersion = this.deviceInfo.appVersion;
                    const iosUpdateEnabled = data.ios.enabled;
                    const androidUpdateEnabled = data.android.enabled;

                    if (androidUpdateEnabled) {
                        // Android version
                        const androidVersion = data.android.latest;
                        const androidMinimumVersion = data.android.minimum;
                        // console.log('currentV: ' + currentVersion)
                        // console.log('androidV: ' + androidVersion)
                        // console.log('androidM: ' + androidMinimumVersion)
                        // log info on server
                        // this.userService.logData('debug FE', 'currentV ' + currentVersion);
                        // this.userService.logData('debug FE', 'androidV ' + androidVersion);
                        // this.userService.logData('debug FE', 'androidM ' + androidMinimumVersion);
                        if (this.deviceInfo.platform.toLowerCase() === 'android') {
                            // Compare versions
                            if (this.capacitorService.compareOSVersion(currentVersion, androidVersion, '<')) {
                                if (this.capacitorService.compareOSVersion(androidMinimumVersion, androidVersion, '=')) {
                                    let mandatoryText = data.alerts.mandatory.text;
                                    if (data.alerts.mandatory.androidAddText != '') {
                                        mandatoryText += ' ' + data.alerts.mandatory.androidAddText;
                                    }
                                    this.presentAlert(data.alerts.mandatory.title, mandatoryText, data.alerts.mandatory.btn, data.android.url);
                                }
                            }
                        }
                    }
                    if (iosUpdateEnabled) {
                        // iOS version
                        const iosLatestVersion = data.ios.latest;
                        const iosMinimumVersion = data.ios.minimum;
                        if (this.deviceInfo.platform.toLowerCase() === 'ios') {
                            // Compare versions
                            if (this.capacitorService.compareOSVersion(currentVersion, iosLatestVersion, '<')) {
                                console.log('ios native version is smaller')
                                if (this.capacitorService.compareOSVersion(iosMinimumVersion, iosLatestVersion, '=')) {
                                    console.log('ios minimum equal')
                                    let mandatoryText = data.alerts.mandatory.text;
                                    if (data.alerts.mandatory.iosAddText != '') {
                                        mandatoryText += ' ' + data.alerts.mandatory.iosAddText;
                                    }
                                    this.presentAlert(data.alerts.mandatory.title, mandatoryText, data.alerts.mandatory.btn, data.ios.url);
                                }
                            }
                        }
                    }
                } 
            }
        });
    }

    openAppstoreEntry(url: string) {
        this.capacitorService.openAppUrl(url);
    }

    async presentAlert(header, message, buttontext= '', url='') {
          this.mandatoryConfig = new MatDialogConfig();
          this.mandatoryConfig.disableClose = true;
          this.mandatoryConfig.autoFocus = false;
          this.mandatoryConfig.data = {
            title: header,
            note: message,
            accept: true,
            acceptIcon: '',
            acceptText: buttontext,
            decline: false,
            declineIcon: '',
            declineText: ''
          };
           let dialogRef = this.dialog.open(ConfirmDialogComponent, this.mandatoryConfig);
           dialogRef.afterClosed().subscribe(res => {
            if (res == 'ok') {
                this.openAppstoreEntry(url);
               }
          });
    }

    /* App state listener */
    checkForMaintenanceAndUpdatesListener() {
        this.capacitorService.watchAppStateChange().pipe(
            //takeUntil(this._unsubscribeAll)
          ).subscribe(isActive => {
            if (this.IS_APP && isActive) {
                this.checkForMaintenanceAndUpdates();
            }
        });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(true);
        this._unsubscribeAll.complete();
      }
}