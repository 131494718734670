import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { environment } from "../../../../environments/environment";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { formatCurrency } from "@angular/common";
import { Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../../../auth/auth.service';
@Component({
  selector: 'app-book-preview-dialog',
  templateUrl: './book-preview-dialog.component.html',
  styleUrls: ['./book-preview-dialog.component.scss'],
})
export class BookPreviewDialogComponent implements OnInit {
  private bookstoreServerUrl;
  isbn: bigint;
  bookUrl = '';
  book: any;
  pdfOptions = {
    //width: "820px",
    height: "100%"
  };
  isLoading: boolean = true;
  public zoom: any = 'page-width';
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  public sessionStorage = sessionStorage;
  showPrice: boolean = false;
  isMentor: boolean = false;
  userId;
  userType;
  userToken;
  mentorCredit: string = '0';

  constructor(
    private http: HttpClient,
    private authService : AuthService,
    @Inject(MAT_DIALOG_DATA) private data: any,)
  {
    let protocol = environment.ssl ? 'https://' : 'http://';
    this.bookstoreServerUrl = protocol + environment.bookstoreServerUrl + ":" + environment.bookstoreServerPort;
    this.isbn = data.isbn
    this.book = data.book
    console.log(this.book)
    // Load preview
    this.http.get<Blob>(this.bookstoreServerUrl + '/preview/' + this.isbn,
    { headers: new HttpHeaders().set('Content-Type', 'application/json'), responseType: 'blob' as 'json' }).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(response => {
      if (response) {
        if (response.size > 0) {
          let dataType = response.type;
          let binaryData = [];
              binaryData.push(response);
          this.bookUrl = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
        }
        console.log(response)
      }
    });
  }

  ngOnInit(): void {
    this.userId = this.authService.getCurrentID();
    this.userType = this.authService.getType();
    // get token from user
    this.userToken = this.authService.getUserToken();
    if (this.userType == 'mentor' || this.userType == 'coordinator') {
      this.isMentor = true;
      var activeStudent = this.authService.getActiveStudent();
      this.mentorCredit = activeStudent["token"].filter(token => token.status == "active").length;
      // this.mentorCredit = formatCurrency(activeStudent["credit"], "de-DE", "€");
    }
  }

  // public getPrice(price:number): string{
  //   return formatCurrency(price, "de-DE", "€");
  // }
  public getPrice(price:number): string{
    if (this.showPrice) {
      return formatCurrency(price, "de-DE", "€");
    } else{
        return price > 0 ? "1 Token" : "0 Token";
    }
  }

  /**
   *  get the totalpage
   */
    onPdfLoaded(data) {
      console.log('loadeddd')
      this.isLoading = false;
    }

  /**
   * On destroy
   */
     ngOnDestroy(): void {
      this._unsubscribeAll.next(true);
      this._unsubscribeAll.complete();
  }
}
