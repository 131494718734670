<div class="session container-fluid pt-3">
  <div class="session_wrap row">
    <div class="col-md-12">
      <h2 class="align-center">Fortschritte auf einen Blick</h2>
      <ul>
        <li>Gesamte Lesezeit: {{ totalReadingTime | timeformat:'hours' }} Stunden / Davon alleine: {{ totalReadingTimeSolo | timeformat:'hours' }} Stunden</li>
        <li>Anzahl gelesener Bücher: {{ totalBookCount }}</li>
        <li>Anzahl gelesener Seiten: {{ totalPagesCount }} / Davon alleine: {{ totalPagesCountSolo }}</li>
        <li *ngIf="QUIZ_ACTIVE">Anzahl Quiz: {{ quizTotalRight }} von {{ quizTotal }} richtig  / Davon alleine: {{ quizSoloRight }} von {{ quizSolo }} richtig</li>
        <li>Anzahl gesammelter Punkte: {{ metamorphosePoints }}</li>
      </ul>
    </div>
  </div>
</div>