<div class="container-fluid pt-3">
  <div class="row">
    <div class="col-md-4 col-sm-12 px-3">
      <div class="record-container">
        <div class="record-wrap">
          <app-record-rtc [readingMode]="'Solo'" [studentId]="userId"
            [readingModeActive]="true" (isRecording)="getIsRecording($event)" *ngIf="readingModeIsPDF || readingModeIsEpub"></app-record-rtc>
        </div>
      </div>
      <app-record-timer [readingMode]="'Solo'" [partnerId]="userId"></app-record-timer>
      <app-metamorphose></app-metamorphose>
      <div class="button-group">
        <!-- <button mat-button color="accent" [routerLink]="['/dashboard']" class="size-big">
          zur Übersicht
        </button> -->
        <button mat-button color="accent" (click)="openDialog()" class="size-big">Meine Bücher</button>
         <button mat-button color="accent" class="size-big" (click)="startQuiz()" *ngIf="quizAvailable">Quiz starten</button>
        <!-- <button mat-button color="accent" (click)="updateReadBooksOnStudent()">
          Update book
        </button> -->
      </div>
    </div>
    <div class="col-md-8 col-sm-12">
      <!-- <app-bookreader [readingMode]="'Solo'" [bookUrl]="bookUrl" *ngIf="readingModeIsEpub"></app-bookreader> -->
      <app-pdfreader *ngIf="readingModeIsPDF"
          [readingMode]="'Solo'"
          [book]="book"
          [bookActivePage]="bookActivePage"
          [toolButtons]="false"
          [isRecording]="isRecording"
          (activePage)="getActivePage($event)"
          (activePart)="getActivePart($event)"
      ></app-pdfreader>
    </div>
  </div>
</div>