import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ErrorDialogComponent } from '../../../dialogs/error-dialog/error-dialog.component';
import { UserService } from '../../../../providers/user.service';
import * as UserActions from './user.actions';

@Injectable()
export class UserEffects {
  fetchById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.fetchById),
      switchMap((action) =>
        this.userService.fetchById(action.userId).pipe(
          map((user) => UserActions.fetchByIdSuccess({ user })),
          catchError((error) => of(UserActions.fetchByIdFail({ error })))
        )
      )
    )
  );

  fetchByIdFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.fetchByIdFail),
        tap(({ error }) => {
          this.matDialog.open(ErrorDialogComponent, {
            disableClose: false,
            data: {
              title: 'Es ist ein Fehler aufgetreten',
              message: `Login fehlgeschlagen (${error.statusText})`,
            },
          });
        })
      ),
    { dispatch: false }
  );

  libById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.libById),
      switchMap((action) =>
        this.userService.libById(action.userId).pipe(
          map((userlib) => UserActions.libByIdSuccess({ userlib })),
          catchError((error) => of(UserActions.libByIdFail({ error })))
        )
      )
    )
  );

  libByIdFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.libByIdFail),
        tap(({ error }) => {
          this.matDialog.open(ErrorDialogComponent, {
            disableClose: false,
            data: {
              title: 'Es ist ein Fehler aufgetreten',
              message: `Login fehlgeschlagen (${error.statusText})`,
            },
          });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly userService: UserService,
    private readonly matDialog: MatDialog
  ) {}
}
