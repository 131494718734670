import { Component, AfterViewInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { WebSocketService } from '../providers/websocket.service';
import { map, debounceTime, distinctUntilChanged } from "rxjs/operators";
import { Subject, BehaviorSubject, Subscription } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { NgxExtendedPdfViewerService, FindState, FindResultMatchesCount } from 'ngx-extended-pdf-viewer';
import { environment } from '../../environments/environment';
import { EbooksEpubService } from "../providers/epub.service";
import { SessionService } from '../providers/session.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-pdfreader',
  templateUrl: './pdfreader.component.html',
  styleUrls: ['./pdfreader.component.scss'],
})
export class PdfreaderComponent implements AfterViewInit {
  BOOKSTORE_ACTIVE = environment.bookstoreModule;
  userId;
  // pdfFile: any;
  displayPage = new BehaviorSubject<number>(1);
  displayPage$ = this.displayPage.asObservable();
  currentPage: number = 1;
  previousDisplayPage: number = 1;
  totalPages = 0;
  pdfOptions = {
    width: "820px",
    height: "100%" //665px
  };
  bookUrl = '';
  isLoading: boolean = true;
  navAtStart: boolean = true;
  navAtEnd: boolean = false;
  currentZoomFactor: number;
  minZoom = "0.5";
  maxZoom = "2";
  findState: any;
  public zoom: any = 'page-width';
  handTool: boolean = true;
  userInputChanged: Subject<string> = new Subject<string>();
  recordingStatusSub: Subscription;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  userType;
  //@ViewChild('pdfContentRef', {static: true}) pdfContentRef: ElementRef;
  highlightText = '';
  bookActivePart = 0;
  startReadingTimestamp;
  isMentor: boolean = false;

  @Input('book') book: any;
  @Input('readingMode') readingMode: string;
  @Input('bookActivePage') set value(page: number) {
    console.log('page change by parent bookActivePage: ' +  page)
    if (this.BOOKSTORE_ACTIVE) {
      console.log(this.book)
      if (this.book['bookActivePart'] != undefined) {
        this.bookActivePart = this.book['bookActivePart'] + 1;
        delete this.book['bookActivePart'];
        console.log('activePart: ' + this.bookActivePart)
      }
      if (this.book['bookActivePage'] != undefined) {
        this.displayPage.next(this.book['bookActivePage']);
        delete this.book['bookActivePage'];
        console.log('activePage: ' + this.displayPage.getValue())
      }
      this.previousDisplayPage = this.displayPage.getValue();
      this.checkBookPartChanged('input');
    } else {
      this.displayPage.next(page);
      this.previousDisplayPage = this.displayPage.getValue();
      this.currentPage = this.displayPage.getValue();
    }
  };
  @Input('toolButtons') toolButtons: boolean;
  @Input('previewMode') previewMode: boolean;
  @Input('isRecording') isRecording: any;
  @Output('activePage') informParentActivePage = new EventEmitter<number>();
  @Output('activePart') informParentActivePart = new EventEmitter<number>();

  constructor(
    private ngxExtendedPdfViewerService: NgxExtendedPdfViewerService,
    private webSocketService: WebSocketService,
    public ebookEpubService: EbooksEpubService,
    private authService : AuthService,
    private sessionService : SessionService,
  ) {
    this.userType = this.authService.getType();
    if (this.userType == "admin") {
      this.handTool = false;
    }

    var _this = this;
    this.userInputChanged.pipe(
      map(event => event),
      debounceTime(500),
      distinctUntilChanged()
    ).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe({
        next: function(userinput) {
            var val = Number(userinput);
            if ((!isNaN(val)) && (val > 0) && (val < _this.totalPages+1)) {
              _this.previousDisplayPage = _this.displayPage.getValue();
              _this.displayPage.next(val);
              if (_this.BOOKSTORE_ACTIVE) {
                _this.checkBookPartChanged('input');
              } else {
                _this.currentPage = _this.displayPage.getValue();
              }
              _this.sendSocketPdfReading();
            }
        }
      });
  }

  ngAfterViewInit() {
    this.isLoading = true;
    if (this.authService.getType() == 'mentor' || this.authService.getType() == 'coordinator') {
      this.isMentor = true;
    }
    // this.pdfFile = "../assets/books/Kinderzeitung-2020-506-S.pdf";
    this.webSocketService.listen('pdf-reading').pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((data) => this.updateCurrentPage(data));
    // listen for highlighted text from mentor
    this.webSocketService.listen('pdf-highlight-text').pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((data) => this.searchForStringInPDF(data.searchtext));
    // Listen to changes
    this.displayPage$.pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(displayPage => {
      this.onPageChange(displayPage);
    });
    // Listen to recording changes
    this.recordingStatusSub = this.sessionService.getRecordingStatusUpdateListener().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((status) => {
        if (status == 'start') {
          // Set last read page before saving session
          this.sessionService.resetReadBooksIsbn();
        }
        if (status == 'stop') {
          // Set last read page before saving session
          this.setReadBookIsbn(this.displayPage.getValue());
        }
      });
  }

  /**
   *  load the previous page
   */
  previousPage() {
    if (this.displayPage.getValue() > 1) {
      this.previousDisplayPage = this.displayPage.getValue();
      // Check if still same part on book
      if (this.BOOKSTORE_ACTIVE) {
        this.displayPage.next(this.displayPage.getValue() - 1);
        this.checkBookPartChanged('previous');
      } else {
        this.currentPage = this.currentPage - 1;
        this.displayPage.next(this.currentPage);
      }
      this.sendSocketPdfReading();
    }
  }

  /**
   *  load the next page
   */
  nextPage() {
    if (this.displayPage.getValue() < this.totalPages) {
      this.previousDisplayPage = this.displayPage.getValue();
      // Check if still same part on book
      if (this.BOOKSTORE_ACTIVE) {
        this.displayPage.next(this.displayPage.getValue() + 1);
        this.checkBookPartChanged('next');
      } else {
        this.currentPage = this.currentPage + 1;
        this.displayPage.next(this.currentPage);
      }

      console.log(this.displayPage);
      this.sendSocketPdfReading();
    }
  }

  /**
   *  pdf zoom in
   */
  zoomIn() {
    var factor = Number((this.currentZoomFactor + 0.1).toFixed(1)) *100;
    //console.log(factor)
    if (factor <= Number(this.maxZoom)*100) {
      this.zoom = factor;
    }
  }

  /**
   *  pdf zoom out
   */
  zoomOut() {
    var factor = Number((this.currentZoomFactor - 0.1).toFixed(1)) *100;
    //console.log(factor)
    if (factor >= Number(this.minZoom)*100) {
      this.zoom = factor;
    }
  }

  /**
   *  switch handtool to selection tool
   */
  toggleHandTool() {
    this.handTool = !this.handTool;
  }

  /**
   *  pdf zoom reset
   */
  zoomReset() {
    this.zoom = 'page-width';
  }

  /**
   *  on value change fire update logic
   */
  onCurrentPageChange(val) {
    this.userInputChanged.next(val);
  }
  

  /**
   *  set value of current zoom
   */
  updateZoomFactor(data) {
    //console.log(data)
    this.currentZoomFactor = data;
  }

  /**
   *  onPageRendered
   */
  onPageRendered(data) {
    //console.log('rendered')
    //console.log(data)
  }

  /**
   *  onPageChange
   * 
   */
  onPageChange(changedPage) {
    // console.log('changed')
    if (this.startReadingTimestamp && this.isRecording) {
      this.setReadBookIsbn(this.previousDisplayPage);
    }
  }

  /**
   *  add page to read pages for session
   */
  setReadBookIsbn(displayPage) {
    if (this.BOOKSTORE_ACTIVE) {
      //console.log(displayPage)
      // Add page change to session array
      let index = this.checkBookStructurePart(this.book.bookStructure.parts, displayPage);
      if (index != -1) {
        this.sessionService.setReadBookIsbn(
          this.book.isbn,
          this.book.title,
          this.book.bookStructure.parts[index],
          displayPage,
          this.getCurrentPageByDisplayPage(displayPage, this.book.bookStructure.parts[index]),
          this.startReadingTimestamp
        );
      }
      // Set new starting time
      this.startReadingTimestamp = formatDate(new Date(), 'yyyyMMddhhmmss', 'en');
    }
  }

  /**
   *  get the totalpage
   */
  onPdfLoaded(data) {
    //console.log('loadeddd')
    this.isLoading = false;
    if (this.previewMode) {
      this.totalPages = data.pagesCount > 10 ? 10 : data.pagesCount;
    } else {
      if (this.BOOKSTORE_ACTIVE) {
        this.totalPages = this.book.bookStructure.totalPages;
      } else {
        this.totalPages = data.pagesCount;
      }
    }
    // Update navigation buttons
    this.updateNavigation();
    // Notify other user that reader is ready
    this.webSocketService.emitTo('book-reader-ready', this.webSocketService.getCurrentRoom(), {
      ready : true
    });
  }

  /**
   *  send pdf data to socket
   */
  sendSocketPdfReading(): void {
    this.updateNavigation();
    // Scroll back to top of container
    //this.scrollTabContentToTop();
    if (this.readingMode == 'Tandem') {
        this.webSocketService.emitTo('pdf-reading', this.webSocketService.getCurrentRoom(), {
          page: this.displayPage.getValue()
        });
    }
    // Send data to parent component
    this.informParentActivePage.emit(this.displayPage.getValue());
    this.informParentActivePart.emit(this.bookActivePart);
  }

  /**
   *  update the current page
   */
  updateCurrentPage(data:any) {
    if(!!!data) return;
    if (this.displayPage.getValue() != data.page) {
      this.displayPage.next(data.page);
      if (this.BOOKSTORE_ACTIVE) {
        this.checkBookPartChanged('input');
      } else {
        this.currentPage = this.displayPage.getValue();
        this.updateNavigation();
      }
      // Send data to parent component
      this.informParentActivePage.emit(this.displayPage.getValue());
      this.informParentActivePart.emit(this.bookActivePart);
    }
  }

  /**
   *  update the navigation of the pdf (buttons)
   */
  updateNavigation() {
    if (this.displayPage.getValue() == 1) {
      this.navAtStart = true;
      this.navAtEnd = false;
    } else if (this.displayPage.getValue() == this.totalPages) {
        this.navAtStart = false;
        this.navAtEnd = true;
    } else {
      this.navAtStart = false;
      this.navAtEnd = false;
    }
  }

  /**
   *  highlight text
   */
   showSelectedText(id) {
    var text = "";
    if (window.getSelection()["extentOffset"] > 2 && window.getSelection()["type"] == "Range" ) {
      if (window.getSelection) {
        text = window.getSelection().toString();
        this.highlightText = text;
      }
    } else {
      this.highlightText = '';
    }
    this.webSocketService.emitTo('pdf-highlight-text', this.webSocketService.getCurrentRoom(), {
      searchtext : this.highlightText,
    });
  }  
  
  /**
   *  search for string in pdf
   */
  public searchForStringInPDF(searchtext) {
    let highlightAll = false;
    let matchCase = false;
    let wholeWords = false;
    let ignoreAccents = true;

    this.ngxExtendedPdfViewerService.find(searchtext, { highlightAll, matchCase, wholeWords, ignoreAccents });
  }

  public updateFindState(result: FindState) {
    console.log(result)
    this.findState = result;
  }

  public updateFindMatchesCount(result: FindResultMatchesCount) {
    console.log(result)
  }

  public clearFindState() {
    // Search for empty string to remove highlighting text
    this.searchForStringInPDF('');
  }

  // scrollTabContentToTop() : void {
	// 	this.pdfContentRef.nativeElement.scrollTo(100, 2000 );
	// }

  /**
   * Find index of current bookStructure by active page
   */
   checkBookStructurePart(parts, value) {
    return parts.findIndex((item) => {
      return value >= item.pageFrom && value <= item.pageTo;
    })
  }

  /**
   * Load book part from store by index
   */
   loadBookURLByPart(part, direction) {
    this.bookActivePart = part;
    this.ebookEpubService.getBookFromBookstore(this.authService.getCurrentID(), this.book.isbn, this.bookActivePart).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(response => {
      if (response) {
        let dataType = response.type;
        let binaryData = [];
            binaryData.push(response);
        // If direction used then reset currentPage
        if (direction == 'next') {
          this.currentPage = 1;
        }
        if (direction == 'previous') {
          let bookCurrentPart = this.book.bookStructure.parts[this.bookActivePart - 1];
          // Check if first part or calculation needed
          this.currentPage = this.getCurrentPageByDisplayPage(this.displayPage.getValue(), bookCurrentPart);
        }
        if (direction == 'input') {
          this.calculateCurrentPageByDisplayPage(direction);
        }
        this.bookUrl = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
      }
    })
  }

  /**
   * check if displayPage is still within the current book part
   */ 
  checkBookPartChanged(direction) {
    // Find book structure index of current page
    let index = this.checkBookStructurePart(this.book.bookStructure.parts, this.displayPage.getValue());
    // console.log('index: ' + index)
    // console.log('bookActivePart: ' + this.bookActivePart)
    // Load part if not already loaded
    if (index != -1 && (index + 1) != this.bookActivePart) {
      console.log('new part loading')
      // Reset bookUrl first (no errors while hidden)
      this.bookUrl = '';
      this.isLoading = true;
      this.loadBookURLByPart(index + 1, direction);
    } else {
      // Set new current page
      this.calculateCurrentPageByDisplayPage(direction);
    }
  }

  /**
   * calculate currentPage since each part starts with page 1
   */ 
  calculateCurrentPageByDisplayPage(direction) {
    if (direction == 'next') {
      this.currentPage = this.currentPage + 1;
    }
    if (direction == 'previous') {
      this.currentPage = this.currentPage - 1;
    }
    if (direction == 'input') {
      let bookCurrentPart = this.book.bookStructure.parts[this.bookActivePart - 1];
      console.log(bookCurrentPart)
      // Check if first part or calculation needed
      this.currentPage = this.getCurrentPageByDisplayPage(this.displayPage.getValue(), bookCurrentPart);
      console.log(this.currentPage)
    }
  }

  getCurrentPageByDisplayPage(page, part) {
    if (part.number > 1) {
      return (page + 1) - part.pageFrom;
    } else {
      return page;
    }
  }

  /**
   * ngOnChanges / set bookUrl
   */ 
   ngOnChanges(changes: SimpleChanges) {
    // Debug
    if (changes.currentPage) {
      console.log(changes.currentPage)
    }
    // Book change detection
    if (changes.book) {
      if (this.BOOKSTORE_ACTIVE) {
        this.bookUrl = '';
        this.isLoading = true;
        // Reset page on book change
        console.log('reset on book change')
        // this.previousDisplayPage = 1;
        // this.displayPage.next(1);
        // this.currentPage = 1;
        this.startReadingTimestamp = null;
        this.loadBookURLByPart((this.bookActivePart), 'none');
      } else {
        this.bookUrl = this.ebookEpubService.getBook(changes.book.currentValue.filename); 
      }
      console.log(changes.book.currentValue)
    }
    if(changes.isRecording) {
      console.log(changes.isRecording)
      if (changes.isRecording.currentValue === true) {
        // Set new starting time
        this.startReadingTimestamp = formatDate(new Date(), 'yyyyMMddhhmmss', 'en');
      }
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
      // Stop recording fix to save last read page in tandem
      this.sessionService.setRecordingStatus('stop');
      this.userInputChanged.unsubscribe();
      this._unsubscribeAll.next(true);
      this._unsubscribeAll.complete();
  }
}