<div class="list-item-container">
  <div class="flexbox">
    <div class="item item-cover-wrap">
      <img src="{{bookstoreServerUrl}}/picture/{{book.isbn}}" />
    </div>
    <div class="item item-center">
      <ul>
        <li><b>Titel:</b> {{ book.title }}</li>
        <li><b>Autor:</b> {{ book.author }}</li>
        <li><b>Anzahl Seiten:</b> {{ book.pages }}</li>
        <li>
          <b>Wie andere Kinder in Deinem Alter das Buch fanden:</b><br>
          <p *ngIf="!book.rating">Leider wurde noch keine Bewertungen abgegeben.</p>
          <app-review
            *ngIf="book.rating"
            [value]="book.rating"
            class="book-review"
          ></app-review>
        </li>
      </ul>
    </div>
    <div class="item item-center">
      <ul>
        <li>
          <b class="text-danger">Preis: {{ getPrice(book.price) }}</b>
        </li>
        <li><b>Für Leser ab: </b>{{ book.lower_age_barrier }} Jahren</li>
        <li style="float: left">
          <b *ngIf="book.difficulty != 'UNDEFINED'">Schwierigkeitsgrad: {{book.difficulty}}</b>
          <app-readability
            [readabilityScore]="book.difficulty"
            [width]="'80%'"
            [height]="'15px'"
          ></app-readability>
        </li>
      </ul>

      <button class="btn btn-primary view-book-btn" (click)="openBookDetails()">
        Buch anschauen
      </button>
    </div>
  </div>
</div>
