import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from '../app-routing.module';
import { MaterialModule } from '../material.module';
import { MatDialogModule } from '@angular/material/dialog';
import { AuthInterceptor } from "../auth/auth-interceptor";
import { ErrorInterceptor } from "../error/error-interceptor";
import { SingleReadingSilentComponent } from './single-reading-silent.component';
import { DragDropModule/*, CDK_DRAG_CONFIG, DragDropConfig*/ } from '@angular/cdk/drag-drop';
//import { BookReaderModule } from '../bookreader/bookreader.module';
import { PdfReaderModule } from '../pdfreader/pdfreader.module';
import { MetamorphoseModule } from '../metamorphose/metamorphose.module';
import { Insomnia } from '@ionic-native/insomnia/ngx';

@NgModule({
  declarations: [
    SingleReadingSilentComponent
  ],
  entryComponents: [
    SingleReadingSilentComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    MatDialogModule,
    DragDropModule,
    PdfReaderModule,
    //BookReaderModule,
    MetamorphoseModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    Insomnia
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [
  ],
  exports: [SingleReadingSilentComponent]
})
export class SingleReadingSilentModule {}
