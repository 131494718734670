import { HttpErrorResponse } from '@angular/common/http';
import { Action, createReducer, on } from '@ngrx/store';
import { User } from '../../models/user';
import { Userlib } from '../../models/userlib';
import * as UserActions from './user.actions';

export const userFeatureKey = 'user';
export const userLibFeatureKey = 'userlib';

export interface UserStateModel {
  isFetchByIdLoading: boolean;
  user?: User;
  fetchByIdError?: HttpErrorResponse;
}

export interface UserLibStateModel {
  isLibByIdLoading: boolean;
  userlib?: Userlib;
  libByIdError?: HttpErrorResponse;
}

export interface UserState {
  readonly [userFeatureKey]: UserStateModel;
}

export interface UserLibState {
  readonly [userLibFeatureKey]: UserLibStateModel;
}

export const userIntitalState: UserStateModel = {
  isFetchByIdLoading: false
};

export const userLibIntitalState: UserLibStateModel = {
  isLibByIdLoading: false
};

const reducer = createReducer(
  userIntitalState,
  on(UserActions.fetchById, (state) => ({
    ...state,
    isFetchByIdLoading: true,
  })),
  on(UserActions.fetchByIdSuccess, (state, action) => ({
    ...state,
    isFetchByIdLoading: false,
    fetchByIdError: undefined,
    user: action.user,
  })),
  on(UserActions.fetchByIdFail, (state, action) => ({
    ...state,
    isFetchByIdLoading: false,
    fetchByIdError: action.error,
    user: undefined,
  }))
);

export const userReducer = (
  state: UserStateModel | undefined,
  action: Action
): UserStateModel => reducer(state, action);

const reducerLib = createReducer(
  userLibIntitalState,
  on(UserActions.libById, (state) => ({
    ...state,
    isLibByIdLoading: true,
  })),
  on(UserActions.libByIdSuccess, (state, action) => ({
    ...state,
    isLibByIdLoading: false,
    libByIdError: undefined,
    userlib: action.userlib,
  })),
  on(UserActions.libByIdFail, (state, action) => ({
    ...state,
    isLibByIdLoading: false,
    libByIdError: action.error,
    user: undefined,
  }))
);

export const userLibReducer = (
  state: UserLibStateModel | undefined,
  action: Action
): UserLibStateModel => reducerLib(state, action);