import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Book } from '../../models/book';
import { BookList, BookWithIsbn } from "../../models/book-startpage";

export const fetchByIsbn = createAction(
  '@hack4good/book/fetchByIsbn',
  props<{ isbn: bigint }>()
);

export const fetchByIsbnSuccess = createAction(
  '@hack4good/book/fetchByIsbn/success',
  props<{ book: Book }>()
);

export const fetchByIsbnFail = createAction(
  '@hack4good/book/fetchByIsbn/fail',
  props<{ error: HttpErrorResponse }>()
);

export const purchaseByIsbn = createAction(
  '@hack4good/book/purchaseByIsbn',
  props<{ isbn: bigint }>()
);

export const purchaseByIsbnSuccess = createAction(
  '@hack4good/book/purchaseByIsbn/success'
);

export const purchaseByIsbnFail = createAction(
  '@hack4good/book/purchaseByIsbn/fail',
  props<{ error: HttpErrorResponse }>()
);

export const search = createAction(
  '@hack4good/book/search',
  props<{ searchText: string, userAge: number, readingAbility: string }>()
);

export const searchSuccess = createAction(
  '@hack4good/book/search/success',
  props<{ searchResults: BookWithIsbn[] }>()
);

export const searchFail = createAction(
  '@hack4good/book/search/fail',
  props<{ error: HttpErrorResponse }>()
);

export const fetchByClub = createAction(
  '@hack4good/book/fetchByClub',
  props<{ clubId: number, userAge: number }>()
);

export const fetchByClubSuccess = createAction(
  '@hack4good/book/fetchByClub/success',
  props<{ books: BookList }>()
);

export const fetchByClubFail = createAction(
  '@hack4good/book/fetchByClub/fail',
  props<{error: HttpErrorResponse}>()
);