import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../providers/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

export interface Students {
  username: string;
  _id: string;
  role: string;
}

@Component({
  selector: 'app-mentor-mapping',
  templateUrl: './mentor-mapping.component.html',
  styleUrls: ['./mentor-mapping.component.scss'],
})
export class MentorMappingComponent implements OnInit {
  mentorId;
  students: Students[] = [];
  mentorStudents: Students[] = [];
  mentorStudentsUntouched: Students[] = [];
  addStudentsArray: any = [];
  addStudentsPromise: boolean = true;
  removeStudentsArray: any = [];
  removeStudentsPromise: boolean = true;
  private StudentsSub: Subscription;
  private MentorStudentsSub: Subscription;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private userService : UserService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.mentorId = this.route.snapshot.params['id'];
    
    // get all students of mentor
    this.userService.getAllStudensOfMentor(this.mentorId);
    // subscribe to students of mentor
    this.MentorStudentsSub = this.userService.getMentorStudentsUpdateListener()
      .pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe((students: Students[]) => {
          console.log(students)
          this.mentorStudents = students;
          this.mentorStudentsUntouched = [...this.mentorStudents];
          // get all students
          this.userService.getStudentsList(true);
        });

    // subscribe to all students
    this.StudentsSub = this.userService.getStudentsUpdateListener()
      .pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe((students: Students[]) => {
          console.log(students)
            for( var i = students.length - 1; i >= 0; i--){
              for( var j=0; j<this.mentorStudents.length; j++){
                  if(students[i] && (students[i]._id === this.mentorStudents[j]._id)){
                    students.splice(i, 1);
                }
              }
          }
          this.students = students;
        });
  }
  
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      // Analyse data for later saving first
      let containerId = event.previousContainer.id;
      let droppedItem = event.previousContainer.data[event.previousIndex];
      this.collectChanges(containerId, droppedItem);
      // Move item in array
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
  }

  collectChanges(containerId, item) {
    if (containerId == 'studentsList') {
      this.addStudentsPromise = false;
      // Check if item is already in database
      if (!this.mentorStudentsUntouched.some(i => i._id === item._id)) {
          if (!this.addStudentsArray.some(i => i._id === item._id)) {
              this.addStudentsArray.push(item);
          }
      }
      // Check if item is in remove Array -> user changed his mind
      if (this.removeStudentsArray.some(i => i._id === item._id)) {
          this.removeStudentsArray.splice(this.removeStudentsArray.findIndex((i) => {
              return i._id === item._id;
          }), 1);
      }
    } else {
      this.removeStudentsPromise = false;
      // Check if item is already in database
      if (this.mentorStudentsUntouched.some(i => i._id === item._id)) {
           if (!this.removeStudentsArray.some(i => i._id === item._id)) {
              this.removeStudentsArray.push(item);
           }
      }
      // Check if item is in add Array -> user changed his mind
      if (this.addStudentsArray.some(i => i._id === item._id)) {
          this.addStudentsArray.splice(this.addStudentsArray.findIndex((i) => {
              return i._id === item._id;
          }), 1);
      }
    }
  }

  saveStudents() {
    // console.log(this.mentorStudentsUntouched)
    // console.log(this.mentorStudents)
    // console.log('------')
    // for ( var i = 0; i < this.mentorStudents.length; i++) {
    //   console.log(this.mentorStudents[i])
    //   console.log(this.mentorStudentsUntouched.some(item => item._id === this.mentorStudents[i]._id));
    // }
    // console.log('------')
    // console.log(this.addStudentsArray)
    // console.log(this.removeStudentsArray)

    // if (this.addStudentsArray.length > 0) {
    //   this.userService.addStudentsToMentor(this.mentorId, this.addStudentsArray.map(a => a._id))
    //     .pipe(
    //       takeUntil(this._unsubscribeAll)
    //     ).subscribe((res) => {
    //         console.log(res)
    //         this.addStudentsPromise = true;
    //         this.checkForRedirectOnSave();
    //       });
    // } else {
      this.addStudentsPromise = true;
      this.checkForRedirectOnSave();
    //}

    if (this.removeStudentsArray.length > 0) {
      this.userService.removeStudentsFromMentor(this.mentorId, this.removeStudentsArray.map(a => a._id))
        .pipe(
          takeUntil(this._unsubscribeAll)
        ).subscribe((res) => {
            console.log(res)
            this.removeStudentsPromise = true;
            this.checkForRedirectOnSave();
          });
    } else {
      this.removeStudentsPromise = true;
      this.checkForRedirectOnSave();
    }
  }

  checkForRedirectOnSave() {
    if (this.addStudentsPromise && this.removeStudentsPromise) {
      this.router.navigate(["/mentor-list"]);
    }
  }
}
