import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable, Subject,BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { PurchaseBody } from '../bookstore/shared/models/purchase-body';
import { User as BookstoreUser } from '../bookstore/shared/models/user';
import { Userlib as BookstoreUserlib } from '../bookstore/shared/models/userlib';

export interface User {
  _id: string;
  username: string;
  role: string;
  email: string;
  password: string;
  avatar: string;
  readingTime: string;
  studentBooks?: any;
  students?: string[];
  school?: string;
  passwordResetRequest?: boolean;
  token?: string[];
}

export interface Students {
    username: string;
    _id: string;
    role: string;
}

export interface Mentors {
  username: string;
  _id: string;
  role: string;
}

export interface Coordinators {
  _id: string;
  username: string;
  firstname?: string;
  lastname?: string;
  role: string;
}


export interface Admins {
  _id: string;
  username: string;
  firstname?: string;
  lastname?: string;
  role?: string;
}

export interface Token {
  user: any;
  status?: string;
}

@Injectable({
  providedIn: 'root'
})

export class UserService {
    private restServerUrl;
    private bookstoreServerUrl;
    BOOKSTORE_ACTIVE = environment.bookstoreModule;
    CLUB_ID = environment.clubId;
    user: User[] = [];
    students: Students[] = [];
    mentors: Mentors[] = [];
    admins: Admins[] = [];
    userUpdated = new Subject<User[]>();
    adminsUpdated = new Subject<Admins[]>();
    mentorStudents: Students[] = [];
    mentorsUpdated = new Subject<Mentors[]>();
    coordinators: Coordinators[] = [];
    coordinatorsUpdated = new Subject<Coordinators[]>();
    mentorsAndCoordinators: Mentors[] = [];
    mentorsAndCoordinatorsUpdated = new Subject<Mentors[]>();
    mentorStudentsUpdated = new Subject<Students[]>();
    studentsUpdated = new Subject<Students[]>();
    onMentorsChanged: BehaviorSubject<any>;
    onStudentsChanged: BehaviorSubject<any>;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private http: HttpClient,
        private authService : AuthService
    ) {
        this.onStudentsChanged = new BehaviorSubject([]);
        let protocol = environment.ssl ? 'https://' : 'http://';
	    	this.restServerUrl = protocol + environment.restServer + ":" + environment.restServerPort ;
        this.bookstoreServerUrl = protocol + environment.bookstoreServerUrl + ":" + environment.bookstoreServerPort;
    }

  getStudentsUpdateListener() {
		return this.studentsUpdated.asObservable();
  }

  getMentorStudentsUpdateListener() {
		return this.mentorStudentsUpdated.asObservable();
  }

  getMentorsUpdateListener() {
		return this.mentorsUpdated.asObservable();
  }

  getAdminsUpdateListener() {
		return this.adminsUpdated.asObservable();
  }

  getCoordinatorsUpdateListener() {
		return this.coordinatorsUpdated.asObservable();
  }

  getMentorsAndCoordinatorsUpdateListener() {
		return this.mentorsAndCoordinatorsUpdated.asObservable();
  }
  
  /**
   * edit user data
   */
  updateUser(data)  {
    console.log(data)
    return this.http.put(
      this.restServerUrl + '/api/user/editUser/' + data._id,
      JSON.stringify(data),
      { headers: new HttpHeaders().set('Content-Type', 'application/json') })
        .pipe(map(res => {
          return <User>res;
        }));
  }

    /**
   * edit user credits
   */
  updateUserCredits(id, data)  {
    console.log(data)
    return this.http.put(
      this.restServerUrl + '/api/user/updateUserCredits/' + id,
      JSON.stringify(data),
      { headers: new HttpHeaders().set('Content-Type', 'application/json') })
        .pipe(map(res => {
          return <User>res;
        }));
  }

  // createToken( user: string ) {
  //   const tokenData: Token = { 
  //       user: user
  //   };
  //   return this.http
  //       .post<any>(
  //           this.restServerUrl + "/api/token/createToken",
  //           tokenData
  //       );
  // }

  createToken(id) {

      return this.http.put(
        this.restServerUrl + '/api/user/createToken/' + id,
        { headers: new HttpHeaders().set('Content-Type', 'application/json') })
          .pipe(map(res => {
            return <User>res;
          }));
  }

  getUserbyId(id) {
    console.log(id)
    return this.http.get<User[]>(this.restServerUrl + '/api/user/profile/' + id)
      .pipe(map(data => data));
  }

  getCoordinatorBySchool(id) {
    return this.http.get<User[]>(this.restServerUrl + '/api/user/coordinatorBySchool/' + id)
      .pipe(map(data => data));
  }

  /**
   * get list of all students
   */
	getStudentsList(activeMembership) {
    let endpoint = '/api/user/students';
    if (activeMembership) {
      endpoint = '/api/user/students?activeMembership=true';
    }
		this.http.get<Students[]>(this.restServerUrl + endpoint)
    .pipe(map(data => data), takeUntil(this._unsubscribeAll))
			.subscribe(data => {
                this.students = data;
                this.studentsUpdated.next([...this.students]);
                this.onStudentsChanged.next(this.students);
			});
  }

  /**
   * get list of all mentors
   */
	getMentorList() {
		this.http.get<Mentors[]>(this.restServerUrl + '/api/user/mentorsList')
    .pipe(map(data => data), takeUntil(this._unsubscribeAll))
			.subscribe(data => {
                this.mentors = data;
                this.mentorsUpdated.next([...this.mentors]);
                // this.onMentorsChanged.next(this.mentors);
			});
  }

  /**
   * get list of all admins
   */
	getAdminList() {
		this.http.get<Admins[]>(this.restServerUrl + '/api/user/adminsList')
    .pipe(map(data => data), takeUntil(this._unsubscribeAll))
			.subscribe(data => {
                this.admins = data;
                this.adminsUpdated.next([...this.admins]);
                // this.onMentorsChanged.next(this.mentors);
			});
  }

  /**
   * get list of all coordinators
   */
	getCoordinatorsList() {
		this.http.get<Coordinators[]>(this.restServerUrl + '/api/user/coordinatorsList')
    .pipe(map(data => data), takeUntil(this._unsubscribeAll))
			.subscribe(data => {
                this.coordinators = data;
                this.coordinatorsUpdated.next([...this.coordinators]);
			});
  }

  /**
   * get list of all mentors and coordinators
   */
   getMentorsAndCoordinatorsList() {
		this.http.get<Coordinators[]>(this.restServerUrl + '/api/user/mentorsAndCoordinatorsList')
    .pipe(map(data => data), takeUntil(this._unsubscribeAll))
			.subscribe(data => {
                this.mentorsAndCoordinators = data;
                this.mentorsAndCoordinatorsUpdated.next([...this.mentorsAndCoordinators]);
			});
  }

  getAllStudensOfMentor(id) {
		this.http.get<Students[]>(this.restServerUrl + '/api/user/studentsList/' + id)
    .pipe(map(data => data), takeUntil(this._unsubscribeAll))
			.subscribe(data => {
        console.log(data)
                this.mentorStudents = data["students"];
                this.mentorStudentsUpdated.next([...this.mentorStudents]);
			});
  }

  /**
   * add students to mentor
   */
  addStudentsToMentor(mentorId, studentArray)  {
    const data = {
      mentorId: mentorId,
      studentArray: studentArray
    };

    return this.http.put(
      this.restServerUrl + '/api/user/addStudents', data)
        .pipe(map(res => {
          return <User>res;
        }));

    // const data = {
    //   students: studentArray
    // };
    // return this.http.put(
    //   this.restServerUrl + '/api/user/editUser/' + mentorId,
    //   JSON.stringify(data),
    //   { headers: new HttpHeaders().set('Content-Type', 'application/json') })
    //     .pipe(map(res => {
    //       return <User>res;
    //     }));
  }

  /**
   * add single student to mentor and change next meeting date
   */
   addSingleStudentToMentor(mentorId, studentId/*, nextMeeting*/)  {
    const data = {
      mentorId: mentorId,
      studentId: studentId,
      //nextMeeting: nextMeeting
    };

    return this.http.put(
      this.restServerUrl + '/api/user/addSingleStudent', data)
        .pipe(map(res => {
          return <User>res;
        }));
  }

  /**
   * remove students to mentor
   */
  removeStudentsFromMentor(mentorId, studentArray)  {
    const data = {
      mentorId: mentorId,
      studentArray: studentArray
    };

    return this.http.put(
      this.restServerUrl + '/api/user/removeStudents', data)
        .pipe(map(res => {
          return <User>res;
        }));
  }
  
  /**
   * get list of read books
   */
	getStudentBooks(id) {
    if (this.BOOKSTORE_ACTIVE) {
      return this.http.get<Students[]>(this.restServerUrl + '/api/user/isbnBooksList/' + id)
			.pipe(map(data => data));
    } else {
      return this.http.get<Students[]>(this.restServerUrl + '/api/user/booksList/' + id)
			.pipe(map(data => data));
    }
  }

  sendSmsToUser(messageTo, messageBody) {
    const formData = {
      messageTo: messageTo,
      messageBody: messageBody
    };

    return this.http.post<any>(this.restServerUrl + '/api/user/sendSmsToUser', formData,
      { headers: new HttpHeaders().set('Content-Type', 'application/json') })
        .pipe(map(res => {
          return res;
        }));
  }

  logData(action, message, level?) {
    const formData = {
      level: level ? level : 'info',
      action: action,
      message: message
    };

    this.http.post<any>(this.restServerUrl + '/api/user/logger', formData,
      { headers: new HttpHeaders().set('Content-Type', 'application/json') })
        .pipe(map(data => data), takeUntil(this._unsubscribeAll))
        .subscribe(data => {
          console.log(data)
        });
  }

  /**
   * set delete user
  */
  deleteUserAndRemoveRelation(id: string) {
    return this.http.delete(this.restServerUrl + '/api/user/' + id)
            .pipe(map(res => {
              return res;
            }));
  }

  // bookstore
  fetchById(userId: string): Observable<BookstoreUser> {
    console.log('fetch api call ' + userId)
    return this.http.get<BookstoreUser>(
      `${this.bookstoreServerUrl}/user/?userId=${userId}&clubId=${this.CLUB_ID}`
    );
  }

  libById(userId: string): Observable<BookstoreUserlib> {
    console.log('lib api call ' + userId)
    return this.http.get<BookstoreUserlib>(
      `${this.bookstoreServerUrl}/userlib/?userId=${userId}&clubId=${this.CLUB_ID}`,
      { headers: new HttpHeaders().set('Content-Type', 'application/json') }
    );
  }

  addToWishlist(requestBody: PurchaseBody): Observable<void> {
    return this.http.put<void>(
      `${this.bookstoreServerUrl}/user/wishlist`,
      requestBody
    );
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}