import { environmentRuntimeVariable } from "src/environments/environment-runtime-variable";

export const environment = {
    appVersion: require('../../package.json').version,
    hotfixVersion: require('../../package.json').hotfix,
    production: false,
    ssl: true,
    socketServerUrl: environmentRuntimeVariable("socketServerUrl", "api-dev-rs.mentor-hessen-app.de"),
    socketServerPort: 443,
    peerServerUrl: environmentRuntimeVariable("peerServerUrl", "api-dev-ps.mentor-hessen-app.de"),
    peerServerPort: 443,
    restServer: environmentRuntimeVariable("restServer", "api-dev-rs.mentor-hessen-app.de"),
    restServerPort: 443,
    isApp: false,
    clientId: environmentRuntimeVariable("clientId", "dev.mentor.lese"),
    clientTitle: environmentRuntimeVariable("clientTitle", "MENTOR Lese-App DEV"),
    clientLogo: environmentRuntimeVariable("clientLogo", "mentor-hessen-ev-logo.jpg"),
    clientLogoNavbar: environmentRuntimeVariable("clientLogoNavbar", "mentor-hessen-ev-schriftzug.jpg"),
    iceServers: [{
        urls: [ "stun:fr-turn1.xirsys.com" ]
    }, {
        username: "oD1FO9s7DXsChzWQm9tKoHSZaKb3AlZW2Auzn_Sju4jFJOiNe416nbW8HyBmndoAAAAAAGJQNoBkaWdpc2FwaWVucw==",
        credential: "97bbcfda-b73e-11ec-a06e-0242ac120004",
        urls: [
            "turn:fr-turn1.xirsys.com:80?transport=udp",
            "turn:fr-turn1.xirsys.com:3478?transport=udp",
            "turn:fr-turn1.xirsys.com:80?transport=tcp",
            "turn:fr-turn1.xirsys.com:3478?transport=tcp",
            "turns:fr-turn1.xirsys.com:443?transport=tcp",
            "turns:fr-turn1.xirsys.com:5349?transport=tcp"
        ]
    }], 
    quizModule: environmentRuntimeVariable("quizModule", false),
    bookstoreModule: environmentRuntimeVariable("bookstoreModule", true),
    bookstoreServerUrl: environmentRuntimeVariable("bookstoreServerUrl", "api-bs.mentor-hessen-app.de"),
    bookstoreServerPort: 443,
    clubId: environmentRuntimeVariable("clubId", 1),
    sentryDNS: 'https://71f2a3544b5948bd909ca9d955383159@o1330715.ingest.sentry.io/6593747',
    sentryEnvironment: 'development'
};

console.log('Runtime ENV:');
console.log(window['env']);