  import { Injectable, PLATFORM_ID, APP_ID, Inject } from '@angular/core';
  import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router
  } from "@angular/router";
  import { isPlatformBrowser } from '@angular/common';
  import { Observable } from "rxjs";
  import { AuthService } from "./auth.service";
  import { map } from 'rxjs/operators';
  import { UserFacade } from '../bookstore/shared/store/user/user.facade';
  
  @Injectable()
  export class AuthGuard implements CanActivate {
    isBrowser;
    constructor(
      @Inject(PLATFORM_ID) private platformId: string,
      @Inject(APP_ID) private appId: string,
      private authService: AuthService,
      private router: Router,
      private readonly userFacade: UserFacade
    ) {
      // check if server
      this.isBrowser = isPlatformBrowser(platformId);
      const platform = isPlatformBrowser(platformId) ?
        'in the browser' : 'on the server';
      console.log(`Running ${platform} with appId=${appId}`);
    }
  
    canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
    ): boolean | Observable<boolean> | Promise<boolean> {
      const isAuth = this.authService.getIsAuth();
      if (!isAuth) {
        this.router.navigate(['/login']);
      }
      return isAuth;
    }

    isTouchDevice() {
      if (this.isPlatformBrowser()) {
        if ('ontouchstart' in document.documentElement) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

    isPlatformBrowser() {
      return this.isBrowser;
    }
  }
  