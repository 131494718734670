<div>
  <div class="close_lexicon_overlay_wrap">
    <mat-icon class="close_lexicon_overlay" mat-dialog-close>close</mat-icon> 
  </div>
  <form [formGroup]="searchForm" (ngSubmit)="onSearch()">
    <mat-form-field appearance="fill" style="width: 400px">
      <mat-icon matPrefix>search</mat-icon>
      <mat-label>Was möchtest du im Lexikon suchen?</mat-label>

      <input matInput formControlName="searchText" />
      <mat-error
        *ngIf="isSubmitted && searchForm.controls.searchText.errors?.required">
        Bitte gib einen Suchtext ein
      </mat-error>
    </mat-form-field>
  </form>
</div>
