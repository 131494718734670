import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { User } from '../../models/user';
import { Userlib } from '../../models/userlib';

export const fetchById = createAction(
  '@hack4good/user/fetchById',
  props<{ userId: string }>()
);

export const fetchByIdSuccess = createAction(
  '@hack4good/user/fetchById/success',
  props<{ user: User }>()
);

export const fetchByIdFail = createAction(
  '@hack4good/user/fetchById/fail',
  props<{ error: HttpErrorResponse }>()
);

export const libById = createAction(
  '@hack4good/userlib/libById',
  props<{ userId: string, clubId: number }>()
);

export const libByIdSuccess = createAction(
  '@hack4good/userlib/libById/success',
  props<{ userlib: Userlib }>()
);

export const libByIdFail = createAction(
  '@hack4good/userlib/libById/fail',
  props<{ error: HttpErrorResponse }>()
);