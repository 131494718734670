<div class="container-fluid">
    <div class="col-md-12">
      <div class="chat_wrap" #content>
        <ng-container *ngFor="let chat of chat">
          <div class="chat-message" [ngClass]="{'sender': chat.sender == userId, 'recipient': chat.sender != userId}"> <span [innerHtml]="chat?.message | linebreaks | safeHtml:'br' "></span> <span class="message_timestamp">{{chat?.created | date:'medium'}}</span> </div>
        </ng-container>
      </div>
      <div class="chat_input">
        <form [formGroup]="rForm" #formDirective="ngForm" class="chat_form">
          <mat-form-field>
            <textarea matInput formControlName="chatMessage"  type="text" placeholder="Nachricht" required></textarea>
          </mat-form-field>
          <button mat-button color="accent" type="button" (click)="submitChat(formDirective)" class="size-small" aria-label="Nachricht senden">
            Senden
          </button>
        </form>
      </div>
    </div>
</div>