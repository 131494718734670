import { Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { Subject, BehaviorSubject } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
// import { AuthData } from "./auth-data.model";
import { environment } from '../../environments/environment';
import { takeUntil } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
// Bookstore
import { UserFacade } from '../bookstore/shared/store/user/user.facade';
import { formatCurrency, formatNumber } from "@angular/common";
import * as Sentry from "@sentry/browser";

export interface AuthData {
    username: string;
	email?: string;
	firstname?: string;
	lastname?: string;
	gender?: string;
	password: string;
	role?: string;
	schoolId?: string;
	coordinatorId?: string;
	phoneNumber?: string;
	mobileNumber?: string;
	birthday?: Date;
	availability?: string;
	supervisingSchools?: string[];
	readingAbilityRate? : string;
	schoolClass?: string;
	membershipType?: string;
	membershipPeriod?: string,
	terminationOfContract?: string,
	comment?: string;
	preferredTypeOfSchool?: string;
	loanedDeviceSerial?: string;
	studentsCapacity?: string;
	token?: string[];
}
export interface AppSettings {
    technicalSupport?: string;
}

export interface Students {
	username: string;
	_id: string;
	role: string;
}

@Injectable({ providedIn: "root" })
export class AuthService {
	private isAuthenticated = false;
	private user: any;
	private appSettings: any;
	private activeStudent: any;
	private activeStudentUsername: string;
	private token: string;
	private tokenTimer: any;
    private id: any;
	private authStatusListener = new Subject<boolean>();
	private socketListener = new Subject<boolean>();
	private appSettingsListener = new Subject<any>();
	private credit = new BehaviorSubject<string>('0,00 €');
	currentCredit = this.credit.asObservable();
	private userToken = new BehaviorSubject<string>('0,00 €');
	currentUserToken = this.userToken.asObservable();
	private restServerUrl;
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	constructor(
		private router: Router,
		private location: Location,
		private http: HttpClient,
		public snackBar: MatSnackBar,
		private __zone: NgZone,
		private readonly userFacade: UserFacade
	) {
		let protocol = environment.ssl ? 'https://' : 'http://';
		this.restServerUrl = protocol + environment.restServer + ":" + environment.restServerPort ;
	}

	getUsername() {
		if (this.user) {
			return this.user['username'];
		} else {
			return;
		}
	}

	getFirstname() {
		if (this.user) {
			return this.user['firstname'];
		} else {
			return;
		}
	}

	getLastname() {
		if (this.user) {
			return this.user['lastname'];
		} else {
			return;
		}
	}

	getFullname() {
		if (this.user) {
			return this.user['firstname'] + ' ' + this.user['lastname'];
		} else {
			return;
		}
	}

	getUsernameOfActiveStudent() {
		if (localStorage.getItem("activeStudent")) {
			var activeStudent = JSON.parse(localStorage.getItem("activeStudent"));
			if (activeStudent.username != undefined) {
				return activeStudent.username;
			} else {
				return;
			}
		} else {
			return;
		}
	}

	// Avatar
	getActiveAvatar() {
		if (this.user) {
			return this.user['avatar'];
		} else {
			return 'butterfly';
		}
	}

	getPasswordResetRequestStatus() {
		if (this.user) {
			return this.user['passwordResetRequest'];
		} else {
			return;
		}
	}

	setPasswordResetRequestStatus(status) {
		this.user['passwordResetRequest'] = status;
		localStorage.setItem("user", JSON.stringify(this.user));
	}

	getToken() {
		return this.token;
	}

	// Check if token is still valid
	validateToken() {
		if(this.isAuthenticated) {
			this.http
			.get<{ token: string; user: any }>(
				this.restServerUrl + "/api/user/validateToken"
			).pipe(
				takeUntil(this._unsubscribeAll)
			)
			.subscribe(response => {
				console.log(response)
				//this.authStatusListener.next(true);
			}, error => {
				this.logout();
				this.__zone.run(() => {
					this.snackBar.open("Sie haben sich an einem anderen Gerät angemeldet. Daher wurden Sie automatisch abgemeldet.", "",{
						panelClass: 'snack-error',
						duration: 3000,
						verticalPosition: 'bottom',
						horizontalPosition: 'right'
					});
				});
			});
		}
	}

	getCurrentID() {
		if (this.user) {
			return this.user['userId'];
		} else {
			return;
		}
	}

	getType() {
		if (this.user) {
			return this.user['userRole'];
		} else {
			return;
		}
	}

	getActiveMembership() {
		if (this.user) {
			return this.user['activeMembership'];
		} else {
			return false;
		}
	}

	getScientificSurveyStatus() {
		console.log(this.user)
		if (this.user) {
			return this.user['scientificSurvey'];
		} else {
			return false;
		}
	}

	getMentorOfChild() {
		if (this.user) {
			return this.user['mentor']['_id'];
		} else {
			return;
		}
	}

	checkIfStudentHasMentor() {
		if (this.user['mentor']) {
			return true;
		} else {
			return false;
		}
	}

	getStudentsOfMentor() {
		if (this.user) {
			return this.user['students'];
		} else {
			return;
		}
	}

	setFirstStudentOfMentorAsActive() {
		if (this.user) {
			if (this.user['students'].length > 0) {
				this.setActiveStudent(this.user['students'][0]);
			}
		}
	}

	getActiveStudent() {
		if (localStorage.getItem("activeStudent")) {
			this.activeStudent = JSON.parse(localStorage.getItem("activeStudent"));
			if (this.activeStudent) {
				return this.activeStudent;
			} else {
				return;
			}
		} else {
			return;
		}
	}

	setActiveStudent(student) {
		this.activeStudent = student;
		localStorage.setItem("activeStudent", JSON.stringify(student));
		this.socketListener.next(student._id);
	}

	// Avatar
	setActiveAvatar(name) {
		this.user['avatar'] = name;
		localStorage.setItem("user", JSON.stringify(this.user));
	}

	// Mentor if changed after login
	setCurrentMentor(id) {
		this.user['mentor'] = { _id: id };
		localStorage.setItem("user", JSON.stringify(this.user));
	}

	getIsAuth() {
		return this.isAuthenticated;
	}

	getAuthStatusListener() {
		return this.authStatusListener.asObservable();
	}

	getSocketListener() {
		return this.socketListener.asObservable();
	}

	getAppSettingsListener() {
		return this.appSettingsListener.asObservable();
	}

	async getUserToken() {
		const userToken = await sessionStorage.getItem("userToken");
		this.userToken.next(userToken);
		return userToken;
	}

    setUser(user, token, rememberMe) {
		if (token) {
			this.saveAuthData(token, user, rememberMe); // Save Auth Data to localStorage
			this.isAuthenticated = true;
			this.authStatusListener.next(true);
			// const now = new Date()
			// // const expiresIn = expirationDate.getTime() - now.getTime()  // Duration to expiration in Milliseconds
			// console.log("expiresIn: " + expiresIn + " Milliseconds")
			// this.setAuthTimer(expiresIn);   
		}    
	}
	
	private setAuthTimer(duration: number) {
		this.tokenTimer = setTimeout(() => {
			this.logout();
		}, duration);
	}

	// Save Auth Data in Browsers localStorage
	saveAuthData(token: string,  user: any, rememberMe: boolean) {
		this.user = user;
		localStorage.setItem("user", JSON.stringify(user));
		if (rememberMe) {
			localStorage.setItem("token", token);
		} else {
			sessionStorage.setItem("token", token);
		}
		// localStorage.setItem("expiration", expirationDate.toISOString()); // localStorage can only save Strings
	}

	// Clear Auth Data from Browsers localStorage
	private clearAuthData() {
		localStorage.removeItem("token");
		sessionStorage.removeItem("token");
		localStorage.removeItem("user");
		localStorage.removeItem("activeStudent");
		localStorage.removeItem("activeStudentUsername");
	}

	// Get localStorage Auth Data
	private getAuthData() {
		const token = localStorage.getItem("token") || sessionStorage.getItem("token")
		// const expirationDate = localStorage.getItem("expiration");
		const user = JSON.parse(localStorage.getItem("user"));
		if (!token) {
			// if (!token || !expirationDate) {
			return;
		}
		return {
			token: token,
			// expirationDate: new Date(expirationDate),
			user: user
		}
    }
    
	autoAuthUser() {
		const authInformation = this.getAuthData();
		if (!authInformation) {
			let currentLocation = this.location.path();
			if (currentLocation.includes('/reset-password')) {
			} else {
				this.router.navigate(["/login"]);
			}
			return;
		}
		// const now = new Date();
		// const expiresIn = authInformation.expirationDate.getTime() - now.getTime();
		// console.log("expiresIn: " + expiresIn + " Milliseconds")
		// if (expiresIn > 0) {
			this.token = authInformation.token;
			this.user = authInformation.user;
			this.isAuthenticated = true;
			// this.setAuthTimer(expiresIn);
			this.authStatusListener.next(true);
			// Go to dashboard if user is already logged in
		
			// if (this.router.url.includes('/login')) { 
			// 	this.router.navigate(["/dashboard"]);
			// }
		// } else {
			// this.logout();
		// }
		// Check for valid token
		this.validateToken();
		// Set sentry user credentials
		Sentry.setUser({ id: this.user.userId });
	}

	setAuthenticatedStatus() {
		this.authStatusListener.next(this.getIsAuth());
	}

	createUser(
		username: string,
		password: string,
		firstname: string,
		lastname: string,
		gender: string,
		email: string,
		role: string,
		school: string,
		//coordinator: string,
		phoneNumber: string,
		mobileNumber: string,
		birthday: Date,
		availability: string,
		supervisingSchools: string[],
		readingAbilityRate: string,
		comment: string,
		schoolClass: string,
		membershipType: string,
		membershipPeriod: string,
		terminationOfContract: string,
		preferredTypeOfSchool: string,
		loanedDeviceSerial: string,
		studentsCapacity: string
	) {
		const authData: AuthData = {
			username: username.toLowerCase(),
			password: password,
			firstname: firstname,
			lastname: lastname,
			gender: gender,
			email: email,
			role: role,
			schoolId: school,
			//coordinatorId: coordinator,
			phoneNumber: phoneNumber,
			birthday: birthday,
			mobileNumber: mobileNumber,
			availability: availability,
			supervisingSchools: supervisingSchools,
			readingAbilityRate: readingAbilityRate,
			comment: comment,
			schoolClass: schoolClass,
			membershipType: membershipType,
			membershipPeriod: membershipPeriod,
			terminationOfContract: terminationOfContract,
			preferredTypeOfSchool: preferredTypeOfSchool,
			loanedDeviceSerial: loanedDeviceSerial,
			studentsCapacity: studentsCapacity
		};
		this.http
			.post<{ token: string; exp: number, user: any }>(
				this.restServerUrl + "/api/user/createUser",
				authData
			).pipe(
				takeUntil(this._unsubscribeAll)
			)
			.subscribe((response) => {
				// this.user = response;
				// const user = response.user;
				// this.user = user;
				// const token = response.token;
				// this.token = token;
				// const expirationDateUnix = response.exp;                    // expiration Date as Unix timestamp
				// const expirationDate = new Date(expirationDateUnix * 1000)  // JavaScript-Time is in Milliseconds
				// this.setUser(user, token);
				// Role based routing
				if (role == 'student') {
					this.router.navigate(["/student-list"]);
				} else if (role == 'mentor') {
					this.router.navigate(["/mentor-list"]);
				} else if (role == 'mentor') {
					this.router.navigate(["/coordinator-list"]);
				} else {
					this.router.navigate(["/admin-dashboard"]);
				}
				var message = firstname + ' ' + lastname + ' angelegt';
				this.snackBar.open(message, "",{
				  duration: 3000,
				  horizontalPosition: 'right'
				});
			}, error => {
				if (error.error.message == 'username taken') {
					this.snackBar.open("Der Benutzername ist schon vergeben.", "",{
						panelClass: 'snack-error',
						duration: 3000,
						horizontalPosition: 'right'
					  });
				} else {
					this.authStatusListener.next(false);
				}

			});
	}

	
	// User login
	login(username: string, password: string, rememberMe: boolean) {
		const authData: AuthData = { username: username, password: password };
		this.http
			.post<{ token: string; exp: number, user: any }>(
				this.restServerUrl + "/api/user/login",
				authData
			).pipe(
				takeUntil(this._unsubscribeAll)
			)
			.subscribe(response => {
				const user = response.user;
				this.user = user;
				const token = response.token;
				this.token = token;
				const expirationDateUnix = response.exp;                    // expiration Date as Unix timestamp
				const expirationDate = new Date(expirationDateUnix * 1000)  // JavaScript-Time is in Milliseconds
				this.setUser(user, token, rememberMe);
				console.log(user);
				if (this.user['userRole'] != 'admin') {
					this.router.navigate(["/dashboard"]);
				} else {
					this.router.navigate(["/admin-dashboard"]);
				}
			}, error => {
				this.authStatusListener.next(false);
				this.__zone.run(() => {
					console.log(error.status)
					let errorText = "Benutzernamen/Passwort fehlerhaft";
					if (error.status == 0) {
						errorText = "Keine Verbindung zum Server.";
					}
					this.snackBar.open(errorText, "",{
						panelClass: 'snack-error',
						duration: 3000,
						horizontalPosition: 'right'
					});
				});
			});
	}

	logout() {
		this.isAuthenticated = false;
		this.authStatusListener.next(this.isAuthenticated);
		this.clearAuthData();
		this.router.navigate(["/login"]);
	}

	// User reset password
	requestPassword(username: string) {
			this.http
				.post(
					this.restServerUrl + "/api/user/requestPassword",
					{ username: username }
				).pipe(
					takeUntil(this._unsubscribeAll)
				)
				.subscribe(response => {
					this.router.navigate(["/login"]); 
					this.__zone.run(() => {
						this.snackBar.open("Die Änderung des Kennworts wurde beantragt.", "",{
							duration: 3000,
							horizontalPosition: 'right'
						});
					});
				}, error => {
					console.error(error);
				});
		}

	// User update password
	updatePassword(username: string, password: string) {
		const authData: AuthData = { username: username, password: password };
		this.http
			.post<{ token: string; exp: number, user: any }>(
				this.restServerUrl + "/api/user/master/updatePassword",
				authData
			).pipe(
				takeUntil(this._unsubscribeAll)
			)
			.subscribe(response => {
				if (this.activeStudent) {
					this.activeStudent.passwordResetRequest = false;
					this.setActiveStudent(this.activeStudent);
				}
				if (this.user['userRole'] != 'admin') {
					this.router.navigate(["/dashboard"]);
				} else {
					this.router.navigate(["/admin-dashboard"]);
				}
				this.__zone.run(() => {
					this.snackBar.open("Kennwort von " + response['username'] + " erfolgreich geändert.", "",{
						duration: 3000,
						horizontalPosition: 'right'
					});
				});
			}, error => {
				console.error(error);
			});
	}

	/**
	 * load app settings
	 */	
	loadAppSettings() {
		this.http
			.get<AppSettings>(
				this.restServerUrl + "/api/app/settings"
			).pipe(
				takeUntil(this._unsubscribeAll)
			)
			.subscribe(response => {
				this.appSettings = response;
				this.appSettingsListener.next(this.appSettings);
			});
	}

	/**
	 * update app settings
	 */
	updateAppSettings(data)  {
		return this.http.put(
			this.restServerUrl + '/api/app/settings',
			JSON.stringify(data),
			{ headers: new HttpHeaders().set('Content-Type', 'application/json') })
			.pipe(map(res => {
				this.appSettings = <AppSettings>res;
				this.appSettingsListener.next(this.appSettings);
				return this.appSettings;
			}));
	}

	getAppSettings() {
		if (this.appSettings) {
			return this.appSettings;
		} else {
			return;
		}
	}

	async getUserCredit() {
		const credit = await sessionStorage.getItem("userWallet");
		this.credit.next(credit);
		return credit;
	}

	// prepareBookstoreForUser(userId) {
	// 	var _this = this;
	// 	this.userFacade.fetchById(userId);
	// 	this.userFacade.user$.subscribe({
	// 	  next(user) {
	// 		if (user) {
	// 		  console.log('bookstore:')
	// 		  console.log(user)
	// 		  sessionStorage.setItem("userId", userId);
	// 		  sessionStorage.setItem("userAge", user.age.toString());
	// 		  let readingAbility = user.readingAbility ? user.readingAbility : 1;
	// 		  sessionStorage.setItem("userReadingAbility", readingAbility.toString());
	// 		  sessionStorage.setItem("userClubId", user.clubId.toString())
	// 		  sessionStorage.setItem("userRole", user.role)
	// 		  sessionStorage.setItem("userWallet", formatCurrency(user.credit, "de-DE", "€"));
	// 		  // Update credit after purchase cause of subscription
	// 		  _this.getUserCredit();
	// 		}
	// 	  }
	// 	})
	//   }

	ngOnDestroy(): void {
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}
}