<div class="row pdfreader">
  <div class="col-md-12">
    <div class="pdfreader-wrap">
      <div class="loading-screen" *ngIf="isLoading">
        <h1>Das Buch wird gerade im Hintergrund geladen ...</h1>
        <app-loading-spinner></app-loading-spinner>
      </div>
      <div class="pdf-navbar" *ngIf="totalPages > 0 && !isLoading">
        <button id="pdf-prev" mat-icon-button aria-label="Vorherige Seite" (click)="previousPage()" color="primary" [ngClass]="{'hidden_nav': navAtStart }">
          <mat-icon>navigate_before</mat-icon>
        </button>
        <button id="pdf-next" mat-icon-button aria-label="Nächste Seite" (click)="nextPage()" color="primary" [ngClass]="{'hidden_nav': navAtEnd }">
          <mat-icon>navigate_next</mat-icon>
        </button>
        <div class="page-zoom">
          <button mat-icon-button aria-label="Zoom in" (click)="zoomIn()" color="primary">
            <mat-icon>zoom_in</mat-icon>
          </button>
          <button mat-icon-button aria-label="Zoom reset" (click)="zoomReset()" color="primary">
            <mat-icon>search</mat-icon>
          </button>
          <button mat-icon-button aria-label="Zoom out" (click)="zoomOut()" color="primary">
            <mat-icon>zoom_out</mat-icon>
          </button>
          <button mat-icon-button aria-label="Tool" (click)="toggleHandTool()" color="primary" *ngIf="toolButtons">
            <mat-icon *ngIf="!handTool">pan_tool</mat-icon>
            <mat-icon *ngIf="handTool">spellcheck</mat-icon>
          </button>
        </div>
        <div class="page-navigation">
          Seite <input type="number" id="activePage" [ngModel]="displayPage.value" (ngModelChange)="onCurrentPageChange($event)" [disabled]="readingMode =='Tandem' && !isMentor"> von {{ totalPages }}
        </div>
      </div>
      <div id="pdf-viewer" [hidden]="isLoading">
        <ngx-extended-pdf-viewer
          [src]="bookUrl"
          [zoom]="zoom"
          minZoom="minZoom"
          maxZoom="maxZoom"
          [(page)]="currentPage"
          pageViewMode="single"
          backgroundColor="white"
          [useBrowserLocale]="true"
          [height]="pdfOptions.height"
          [showBorders]="false"
          [showPrintButton]="false"
          [showFindButton]="false"
          [showRotateButton]="false"
          [showPropertiesButton]="false"
          [showSecondaryToolbarButton]="false"
          [showSelectToolButton]="false"
          [showHandToolButton]="false"
          [textLayer]="true"
          [handTool]="handTool"
          [showScrollingButton]="false"
          [showSpreadButton]="false"
          [showPresentationModeButton]="false"
          [showSidebarButton]="false"
          [showDownloadButton]="false"
          [showBookmarkButton]="false"
          [showOpenFileButton]="false"
          [showZoomButtons]="false"
          [showSidebarOnLoad]="false"
          [showPagingButtons]="false"
          (pdfLoaded)="onPdfLoaded($event)"
          (pageRendered)="onPageRendered($event)"
          (pageChange)="onPageChange($event)"
          (currentZoomFactor)="updateZoomFactor($event)"
          (updateFindState)="updateFindState($event)"
          (mouseup)="showSelectedText(0)"
        ></ngx-extended-pdf-viewer>
      </div>
    </div>
  </div>
</div>