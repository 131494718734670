import { Component, OnInit, Inject } from '@angular/core';
import {  MatDialogRef, MatDialog,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuizService } from '../providers/quiz.service';
import { UserService } from '../providers/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { QuizResultService } from '../providers/quizResult.service';
import { SessionService } from '../providers/session.service';
import { Router } from "@angular/router";
import { WebSocketService } from '../providers/websocket.service';

export interface DialogData {
  startPage: number;
  activePage: number;
  bookId: string;
  bookName: string;
}
export interface Quiz {
  id: number;
  question: string;
  answers: Option[];
  answer: number;
  selected: number;
  freeTextAnswer: number;
}

export interface Option {
  optionid: number;
  answerText: string;
  rightAnswer: boolean
}

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss'],
})
export class QuizComponent implements OnInit {
  quizCheck: boolean = false;
  currentIndex:number;
  correct:any;
  currentQuizSet:any;
  start=false;
  quizFinished=false;
  bookName;
  buttonname = "Quiz starten";
  endQuiz = false;
  quiz: Quiz[];
  isHidden: boolean = false;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  quizSummary: any;
  isSummary: boolean = false;
  isfinished: boolean = false;
  userType;
  isStudent: boolean = false;
  sessionId;
  studentId;
  childIndex = 0;
  quizResult: boolean = false;
  quizEndResult;
  quizId;

  constructor(
    private matDialog: MatDialog,
    private quizService: QuizService,
    private userService : UserService,
    public snackBar: MatSnackBar,
    private authService : AuthService,
    private quizResultService : QuizResultService,
    private sessionService : SessionService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public injectedData: DialogData,
    private webSocketService : WebSocketService,
    ){

    }
  
  ngOnInit() {
    this.userType = this.authService.getType();
    if (this.userType == "student") {
      this.isStudent = true;
    }
    this.studentId = this.authService.getCurrentID(); 
    this.bookName = this.injectedData.bookName
    console.log(this.injectedData)
    this.quizService.generateQuiz({
      bookId: this.injectedData.bookId,
      startPage: this.injectedData.startPage,
      activePage: this.injectedData.activePage
    }).subscribe(res => {
      console.log(res)
      this.quizCheck = true;
      this.quiz = <Quiz[]>res;
      this.currentIndex = 0;
      this.currentQuizSet = this.quiz[this.currentIndex];

      if (this.router.url.includes('/room')) {
        setTimeout(() => {
          let data = {
            index: this.currentIndex,
            status: "start",
            result: null
          };
          this.sendQuizStatusToMentor(data);
        }, 500);
      }
    });

    // Listen for quiz sync
    this.webSocketService.listen('quiz-sync').pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((data) => this.syncQuiz(data));
  }

  next() {
    this.currentIndex = this.currentIndex + 1;
    this.currentQuizSet = this.quiz[this.currentIndex];
    console.log(this.currentQuizSet.answers.length)
    if (this.currentQuizSet.answers.length < 1) {
      this.isHidden = true;
      console.log(   this.isHidden)
    }

    if (this.currentIndex+1 == this.quiz.length) {
      // quiz summary
      this.quizSummary = this.quiz
      this.isfinished = true;
    }

    // if (this.userType == "student") {
    if (this.router.url.includes('/room')) {
      let data = {
        index: this.currentIndex,
        status: "active",
        result: null
      };
      this.sendQuizStatusToMentor(data);
    }
  }

  checkAnswer(i, selectedAnswer) {
    let check = this.quizSummary[i].answers.filter(selected => {
      if (selected._id == selectedAnswer) {
        return selected;
      }
    })
    if (check.length > 0) {
      if (check[0].rightAnswer) {
        return 'result-right';
      } else {
        return 'result-wrong';
      }
    } else {
      return 'result-unrated';
    }
  }

  showSummary() {
    this.isSummary = true;
    this.endQuiz = true;
  }


  submit() {
    this.endQuiz = false;
    if (this.currentIndex + 1 == this.quiz.length) {
      this.quizFinished = true;
      this.start = false;
      this.correct = 0;

      // Generate quiz result
      this.quiz.map(x => {
        let result = x.answers[x.answers.findIndex((item) => {
          return item['_id'] === x.selected;
        })];
        console.log(result)
        
        if (result && result.rightAnswer === true) {
          this.correct = this.correct + 1;
        }
      });

    // increase readingPoints
    if (this.correct / this.quiz.length * 100 >= 50 && this.userType == "student") {
        this.userService.getUserbyId(this.studentId).pipe(
          takeUntil(this._unsubscribeAll)
        ).subscribe(res => {
            //  Get current rading points
            let currentReadingPoints = res["readingPoints"] ? res["readingPoints"] : 0;
            // increase readingPoints
            currentReadingPoints++;
            // Save to server
            this.userService.updateUser({
              _id : this.studentId,
              readingPoints : currentReadingPoints
            }).pipe(
              takeUntil(this._unsubscribeAll)
            ).subscribe(res => {
              //console.log('new points earned')
              this.snackBar.open("Der Punktestand wurde um +1 erhöht.", "",{
                duration: 3000,
                horizontalPosition: 'right'
              });
            });
        });
      }

      let quizMode;
      if (this.router.url.includes('/single-reading')) {
        quizMode = "Solo"
      } else {
        quizMode = "Tandem"
      }

      // create QuizResult
      this.sessionId = this.sessionService.getLatestSession();
      this.quizResultService.createQuizResult({
        session: this.sessionId,
        student: this.studentId,
        mentor: this.authService.getMentorOfChild(),
        quizStatus: quizMode,
        quiz: this.quiz,
        result: this.correct / this.quiz.length * 100
      }).pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe((res:any) => {
        console.log(res)
        this.quizId = res._id;
        this.snackBar.open("Quiz wurde erfolgreich abgeschickt", "",{
          duration: 3000,
          horizontalPosition: 'right'
        });
        //single-reading
        this.quizResult = true;

        if (this.router.url.includes('/room')) {
          let data = {
            status: "finished",
            result: res.quiz, // quizSet
            correct: this.correct,
            id: this.quizId
          };
          this.sendQuizStatusToMentor(data);
        }
      });
    }

  }

  closeQuiz(){
    if (this.router.url.includes('/room')) {
      let data = {
        status: "end"
      };
      this.sendQuizStatusToMentor(data);
    }
    this.matDialog.closeAll();
  }

  startQuiz() {
    this.quizFinished = false;
    this.currentIndex = 0;
    this.currentQuizSet = this.quiz[this.currentIndex];
    if (this.currentQuizSet.answers.length < 1) {
      this.isHidden = true;
    }
    this.start = true;
    if (this.currentIndex+1 == this.quiz.length) {
      // quiz summary
      this.quizSummary = this.quiz
      this.isfinished = true;
    }
  }

  syncQuiz(data) {
    this.childIndex = data.index;
    this.quizId = data.id;
    this.quizEndResult = data.result;

    if (data.status == "finished") {
      this.quizFinished = true;
      this.correct = data.correct;
      this.quizSummary = data.result;
    }

    if (data.status == "start") {
      this.currentIndex = 0;
      this.startQuiz();
    }

    if (data.status == "end") {
      this.matDialog.closeAll();
    }

    // if (data.rusult != null) {
    //   console.log(data.result)
    //   this.quizResult = true;
    //   this.quizSummary = data.result;
    //   console.log(this.quizSummary)
    // }
   }

  sendQuizStatusToMentor(data) {
    this.webSocketService.emitTo('quiz-sync', this.webSocketService.getCurrentRoom(), data);
  }

  rightAnswer(element) {
    element.answers.push({
      rightAnswer : true
    })

      this.correct = 0;
      // Generate new quiz result
      this.quizEndResult.map(x => {
        let result = x.answers[x.answers.findIndex((item) => {
          return item['_id'] === x.selected;
        })];
        if (result && result.rightAnswer === true) {
          this.correct = this.correct + 1;
        }
      });

    // Update Quiz result
    this.quizResultService.updateQuizResult({
        _id : this.quizId,
         quiz : this.quizEndResult,
         result: this.correct / this.quizEndResult.length * 100
      }).pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe(res => {
        console.log(res)
        // this.correct =  Math.round(res["result"]);
        // this.quizEndResult["result"] = res["result"];
       // increase readingPoints
        let userId; 
        userId = res.student;
        console.log(res["result"])

        if(res["result"] >= 50 && res["result"] < 51 ) {
          this.userService.getUserbyId(userId).pipe(
            takeUntil(this._unsubscribeAll)
          ).subscribe(res => {
            console.log("______________________________")
            console.log(res)
              //  Get current rading points
              let currentReadingPoints = res["readingPoints"] ? res["readingPoints"] : 0;
              // increase readingPoints
              currentReadingPoints++;
              // Save to server
              this.userService.updateUser({
                _id : userId,
                readingPoints : currentReadingPoints
              }).pipe(
                takeUntil(this._unsubscribeAll)
              ).subscribe(res => {
                //console.log('new points earned')
                this.snackBar.open("Der Punktestand wurde um +1 erhöht.", "",{
                  duration: 3000,
                  horizontalPosition: 'right'
                });
              });
          });
        }
      });
   }

  wrongAnswer(element) {
    element.answers.push({
      rightAnswer : false
    })

    this.quizResultService.updateQuizResult({
        _id : this.quizId,
         quiz : this.quizEndResult
      }).pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe(res => {
        // this.quizEndResult.result = res["result"];
      });
   }
}
