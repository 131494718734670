// import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NotificationsComponent } from './notifications.component';
import { MaterialModule } from '../material.module';

@NgModule({
  imports: [
    // IonicModule,
    CommonModule,
    FormsModule,
    MaterialModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  declarations: [NotificationsComponent]
})
export class NotificationsModule {}
