import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BookDetailsDialogComponent } from '../dialogs/book-details-dialog/book-details-dialog.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-book-horizontal-list-item',
  templateUrl: './book-horizontal-list-item.component.html',
  styleUrls: ['./book-horizontal-list-item.component.scss'],
})
export class BookHorizontalListItemComponent {
  bookstoreServerUrl;
  @Input() readabilityScore?: string;
  @Input() price!: string;
  @Input() isbn!: number;
  @Input() donation_available?: boolean;

  constructor(private dialog: MatDialog) {
    let protocol = environment.ssl ? 'https://' : 'http://';
    this.bookstoreServerUrl = protocol + environment.bookstoreServerUrl + ":" + environment.bookstoreServerPort;
  }

  showBookDetails(): void {
    this.dialog.open(BookDetailsDialogComponent, {
      width: '80vw', restoreFocus: false, data:{
        isbn: this.isbn,
        donation_available: this.donation_available
      }
    });
  }
}
