import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Book } from '../bookstore/shared/models/book';
import { PurchaseBody } from '../bookstore/shared/models/purchase-body';
import { BookList } from "../bookstore/shared/models/book-startpage";

export interface BookDonation {
  _id?: string;
  isbn: bigint;
  donor?: string;
  contingent: number;
  usedContingent?: number;
  stockContingent?: number;
  prePurchased?: any;
  redeemed?: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class BookService {
  private restServerUrl;
  private bookstoreServerUrl;
  bookDonation: BookDonation [] = [];
  bookDonationUpdated = new Subject<BookDonation[]>();
  CLUB_ID = environment.clubId;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private readonly http: HttpClient
  ) {
    let protocol = environment.ssl ? 'https://' : 'http://';
    this.bookstoreServerUrl = protocol + environment.bookstoreServerUrl + ":" + environment.bookstoreServerPort;
    this.restServerUrl = protocol + environment.restServer + ":" + environment.restServerPort ;
  }

  fetchByIsbn(isbn: bigint): Observable<Book> {
    //console.log(isbn)
    return this.http.get<Book>(
      `${this.bookstoreServerUrl}/book/${isbn}`
    );
  }

  search(searchText: string, userAge: number, readingAbility: string): Observable<any> {
    return this.http.get<any>(
      `${this.bookstoreServerUrl}/book/search/`,
      { params: { search_text: searchText, user_age: userAge, reading_ability: readingAbility, clubId: this.CLUB_ID } }
    );
  }

  purchaseByIsbn(userId: string, isbn: bigint, clubId: number): Observable<void> {
    const requestBody: PurchaseBody = { userId, isbn: BigInt(isbn), clubId };
    return this.http.post<void>(
      `${this.bookstoreServerUrl.api}/book/purchase`,
      requestBody
    );
  }

  fetchByClub(clubId: number, userAge: number): Observable<BookList> {
    return this.http.get<BookList>(`${this.bookstoreServerUrl}/book/home/?clubId=${clubId}&user_age=${userAge}`);
  }

  getBookimage(isbn: bigint) {
    return `${this.bookstoreServerUrl}/picture/${isbn}`;
  }
  
  fetchBookDonationList() {
    return this.http.get<BookDonation[]>(this.restServerUrl + "/api/bookDonation/list");
  }

  getListOfBookDonations() {
    this.http.get<BookDonation[]>(this.restServerUrl + "/api/bookDonation/list")
        .pipe(map(data => data), takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        console.log(data)
        this.bookDonation = data;
        this.bookDonationUpdated.next(this.bookDonation);
    });
  }

  getBookDonationUpdateListener() {
    return this.bookDonationUpdated.asObservable();
  }

  createBookDonation(isbn: bigint, donor: string, contingent: number) {
    const bookDonationData: BookDonation = { 
      isbn: isbn,
      donor: donor,
      contingent: contingent
    };
    return this.http.post<any>(
            this.restServerUrl + "/api/bookDonation/create",
            bookDonationData
        );
  }
}
