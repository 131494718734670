
<div class="container-fluid pt-3">
    <ng-container *ngIf="booksLoaded && books.length > 0">
      <div class="book-shelf row">
        <div class="col-md-12">
          <div class="table-navigation">
            <div class="filter-area">
              <div class="filter-wrap">
                  <mat-form-field>
                    <mat-label>Filter</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Buchtitel" #input>
                  </mat-form-field>
                  <mat-form-field *ngIf="!BOOKSTORE_ACTIVE">
                    <mat-label>Altersempfehlung</mat-label>
                    <mat-select  placeholder="Altersempfehlung" (selectionChange)="recommendationSelectionFilter($event)">
                      <mat-option value=""></mat-option>
                      <mat-option value="6 bis 8">6 bis 8</mat-option>
                      <mat-option value="8 bis 10">8 bis 10</mat-option>
                      <mat-option value="Ab 10">Ab 10</mat-option>
                    </mat-select>
                  </mat-form-field>
              </div>
            </div>
            <div class="button-area" *ngIf="isAdmin">
              <a [routerLink]="['/book-create']">
                <mat-icon >post_add</mat-icon> Buch anlegen
              </a>
            </div>
          </div>
        </div>
      </div>
  
      <div class="book-shelf row" *ngIf="!isAdmin">
        <div class="col-lg-3  col-md-6 col-sm-6 col-xs-12" *ngFor="let book of booksObs | async">
            <mat-card>
              <mat-card-header>
                <mat-card-title>{{ book.title }}</mat-card-title>
              </mat-card-header>
              <div class="mat-card-image-wrap">
                <img mat-card-image src="{{ getBookImageUrl(book.isbn) }}" />
              </div>
              <mat-card-actions>
                <button mat-button [mat-dialog-close]="book" *ngIf="!disableBookSelection">Lesen</button>
              </mat-card-actions>
            </mat-card>
        </div>
        <mat-paginator #paginator [pageSize]="pageSize"></mat-paginator>
      </div>
    
      <div class="book-shelf row" *ngIf="isAdmin">
        <div class="col-md-12">
          <mat-table [dataSource]="dataSource" class="mat-elevation-z1" matSort matSortActive="name" matSortDisableClear matSortDirection="asc">
              <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Buchtitel</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{ element.name }}
                </mat-cell>
              </ng-container>
  
              <ng-container matColumnDef="buttons">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let element" >
                    <div>
                        <button mat-icon-button [matMenuTriggerFor]="moreMenu" aria-label="More">
                            <mat-icon class="secondary-text">more_vert</mat-icon>
                        </button>
    
                        <mat-menu #moreMenu="matMenu">
                            <button mat-menu-item aria-label="add-note" [routerLink]="['/book-edit/' + element._id]">
                                <mat-icon>create</mat-icon>
                                <span>Bearbeiten</span>
                            </button>
                            <!-- <button mat-menu-item aria-label="add-note" (click)="goToCreateQuestion(element._id)">
                                <mat-icon>question_answer</mat-icon>
                                <span>Quiz Frage erstellen</span>
                            </button> -->
                            <button *ngIf="quizIsVisible" mat-menu-item aria-label="add-note"  (click)="goToListQuestion(element._id)">
                              <mat-icon>list</mat-icon>
                              <span>Alle Fragen zum Buch</span>
                            </button>
                            <button mat-menu-item *ngIf="isAdmin" aria-label="remove" (click)="deleteBook(element._id)">
                              <mat-icon>delete</mat-icon>
                              <span>Löschen</span>
                          </button>
                        </mat-menu>
                    </div>
                </mat-cell>
              </ng-container>
          
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
          <mat-paginator #paginator [pageSize]="10"></mat-paginator>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="booksLoaded && books.length < 1 && isSingleReading">
      <div class="book-shelf row">
        <div class="col-md-12">
          <div class="student-area-purchasesBook">
            <h1>Du hast noch kein Buch, hier geht es zur Bibliothek.</h1>
            <button mat-button color="accent" [routerLink]="['/library/']" class="size-big" mat-dialog-close>Bibliothek</button>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="booksLoaded && books.length < 1 && !isSingleReading">
      <div class="book-shelf row">
        <div class="col-md-12">
          <div class="student-area-purchasesBook">
            <h1 *ngIf="isMentor">Ihr Kind hat noch kein Buch in seinem Besitz. Es muss zuvor ein Buch kaufen. Bspw. ein kostenloses aus der "Bücherkiste". Dies ist in der derzeitigen Version der Bibliothek leider nicht im getrennten Zustand möglich.</h1>
            <h1 *ngIf="!isMentor">Du hast noch kein Buch in deinem Besitz. Du musst zuvor ein Buch kaufen. Bspw. ein kostenloses aus der "Bücherkiste". Dies ist in der derzeitigen Version der Bibliothek leider nicht im getrennten Zustand möglich.</h1>
          </div>
        </div>
      </div>
    </ng-container>
  </div>