import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as BookActions from './book.actions';
import { bookSelectors } from './book.selectors';

@Injectable()
export class BookFacade {
  isFetchByIsbnLoading$ = this.store.select(bookSelectors.isFetchByIsbnLoading);
  fetchByIsbnError$ = this.store.select(bookSelectors.getFetchByIsbnError);
  book$ = this.store.select(bookSelectors.getBook);
  isPurchaseLoading$ = this.store.select(bookSelectors.isPurchaseLoading);
  purchaseError = this.store.select(bookSelectors.getPurchaseError);
  isSearchLoading$ = this.store.select(bookSelectors.isSearchLoading);
  searchError$ = this.store.select(bookSelectors.getSearchError);
  searchResults$ = this.store.select(bookSelectors.getSearchResults);
  startpageBookList$ = this.store.select(bookSelectors.getStartpageBookList);

  constructor(private readonly store: Store) {}

  fetchByIsbn(isbn: bigint): void {
    this.store.dispatch(BookActions.fetchByIsbn({ isbn }));
  }

  purchaseByIsbn(isbn: bigint): void {
    this.store.dispatch(BookActions.purchaseByIsbn({ isbn }));
  }

  search(searchText: string, userAge: number, readingAbility: string): void {
    this.store.dispatch(BookActions.search({ searchText, userAge, readingAbility }));
  }

  fetchByClub(clubId: number, userAge: number): void {
    this.store.dispatch(BookActions.fetchByClub({clubId, userAge}));
  }
}
