<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="close_gamelist_overlay_wrap" *ngIf="IsOverlay">
        <mat-icon class="close_gamelist_overlay" mat-dialog-close>close</mat-icon> 
      </div>
      <div class="bookstore-content-wrap">
        <div class="search-wrapper">
          <div class="vertically-center-content" *ngIf="!IsOverlay">
            <app-search-input></app-search-input>
          </div>
          <div class="vertically-center-content">
            <p class="balance-text" *ngIf="!isMentor">Dein Guthaben: {{ userToken }} Token</p>
            <p class="balance-text" *ngIf="isMentor">Guthaben Lesekind: {{ mentorCredit }} Token</p>
          </div>
        </div>
      
        <ng-container *ngIf="books; else loader">
          <app-book-horizontal-list *ngFor="let category of booksKeys" [title]="category" [books]="booksByKey(category)"></app-book-horizontal-list>
        </ng-container>
      
        <ng-template #loader>
          <div class="loading-screen">
            <app-loading-spinner></app-loading-spinner>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
