import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { NgForm, FormControl, FormGroup, FormGroupDirective, Validators } from "@angular/forms";
import { Subscription, Subject } from 'rxjs';
import { UserService } from "../providers/user.service";
import { SessionService } from "../providers/session.service";
import { environment } from '../../environments/environment';
import { takeUntil } from 'rxjs/operators';
import moment from 'moment';

export interface User {
  username?: string;
}
export interface Session {}
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  clientId = environment.clientId;
  isMentor = true;
  userId: string;
  userType;
  coordinator;
  passwordResetRequest: boolean = false;
  readingTime: string;
  readingAbilityRate: string;
  loanedDeviceSerial: string;
  user: User[] = [];
  rForm: FormGroup;
  subscription: Subscription;
  avatar: string;
  defaultTime: any;
  appSettings;
  private appSettingsSubs: Subscription;
  // session: Session[] = [];
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService : AuthService,
    public userService: UserService,
    // public sessionService: SessionService,
  ) { }

  ngOnInit() {
    // Set seconds to zero on timepicker
    var d = new Date(moment().format("YYYY-MM-DDTHH:mm:00"));
    this.defaultTime = [d.getHours(), 0 , 0];
    this.userId = this.route.snapshot.params['id'];
    this.userType =  this.authService.getType();
    if (!(this.userType == 'mentor' || this.userType == 'coordinator')) {
      this.isMentor = false;
    }
    this.userService.getUserbyId(this.userId).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(data => {
        this.user = data;
        console.log(this.user)
        if (this.user["passwordResetRequest"]) {
          this.passwordResetRequest = this.user["passwordResetRequest"];
        }
        this.readingTime = this.user["readingTime"];
        this.readingAbilityRate = this.user["readingAbilityRate"];
        this.loanedDeviceSerial = this.user["loanedDeviceSerial"];
        console.log(this.user['school'])
        this.userService.getCoordinatorBySchool(this.user['school']['_id']).pipe(
          takeUntil(this._unsubscribeAll)
        ).subscribe(data => {
            this.coordinator = data;
        });

      });
      // this.sessionService.getSessionUpdateListener()
      // .subscribe((session:Session[]) => {
      //   this.session = session;
      //   console.log(this.session)
      //   this.session.forEach(element => {
      //     console.log(element["student"])
      //     if(this.userId == element["student"]) {
      //       this.studentsSession = true;
      //     }
  
      //   })
      // });
  
    this.initForm();

    // Get app settings
    this.appSettings = this.authService.getAppSettings();
    this.appSettingsSubs = this.authService.getAppSettingsListener()
      .subscribe(appSettings => {
        this.appSettings = appSettings;
    });
  }

    // init form with data and fill input field
    initForm(){
      this.rForm = new FormGroup({
        avatar: new FormControl(this.avatar),
        readingTime: new FormControl(this.readingTime),
        firstname: new FormControl(),
        lastname: new FormControl(),
        email: new FormControl(),
        phoneNumber: new FormControl(),
        mobileNumber: new FormControl(),
        birthday: new FormControl(),
        nextMeeting: new FormControl(),
        schoolClass: new FormControl(),
        recurringMeeting: new FormControl()
      })
      // //fill input field with data from user object
      this.subscription = this.userService.getUserbyId(this.userId).pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe(user => {
        this.rForm.get('avatar').setValue(user['avatar']);
        this.rForm.get('readingTime').setValue(user['readingTime']);
        this.rForm.get('firstname').setValue(user['firstname']);
        this.rForm.get('lastname').setValue(user['lastname']);
        this.rForm.get('email').setValue(user['email']);
        this.rForm.get('phoneNumber').setValue(user['phoneNumber']);
        this.rForm.get('mobileNumber').setValue(user['mobileNumber']);
        this.rForm.get('birthday').setValue(user['birthday']);
        this.rForm.get('nextMeeting').setValue(user['nextMeeting']);
        this.rForm.get('schoolClass').setValue(user['schoolClass']);
        let recurringMeeting = true;
        if (user['recurringMeeting'] == undefined) {
          recurringMeeting = false;
        } else {
          recurringMeeting = user['recurringMeeting'];
        }
        this.rForm.get('recurringMeeting').setValue(recurringMeeting);
      })
    }


  // submit form with data and navigate to dashboard
	submitForm(formDirective: FormGroupDirective) {
    if (this.rForm.invalid) {
      return;
    }
    let userFields = {};
    if (this.userType != 'student') {
      userFields = {
        _id : this.userId,
        readingTime : this.rForm.value.readingTime,
        email : this.rForm.value.email,
        phoneNumber : this.rForm.value.phoneNumber,
        mobileNumber : this.rForm.value.mobileNumber,
        birthday : new Date(moment(this.rForm.value.birthday).format("YYYY-MM-DD")),
        nextMeeting : new Date(moment(this.rForm.value.nextMeeting).format("YYYY-MM-DDTHH:mm:00")),
        schoolClass : this.rForm.value.schoolClass,
        recurringMeeting: this.rForm.value.recurringMeeting
      };
    } else {
      userFields = {
        _id : this.userId,
        avatar : this.rForm.value.avatar
      };
    }
    this.userService.updateUser(userFields).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(res => {
      this.authService.setActiveAvatar(this.rForm.value.avatar);
      this.router.navigate(['/dashboard']);
    });
  }

  /**
   * change Reading Time
   */
  changeReadingTimeInstantly(event: any) {
      this.readingTime = event.value;
  }

  changePasswordForStudent() {
     this.router.navigate(["/change-password/" + this.userId]);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

}
