import { Component, OnInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { Platform } from '@ionic/angular';
import { Router, Event, NavigationEnd } from '@angular/router';
import { WebSocketService } from './providers/websocket.service';
import { WebrtcService } from './providers/webrtc.service';
import { AuthGuard } from './auth/auth.guard';
import { AuthService } from './auth/auth.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmDialogComponent } from './helpers/confirm-dialog/confirm-dialog.component';
import { IframeComponent } from './helpers/iframe/iframe.component';
import { LexiconComponent } from './lexicon/lexicon.component';
import { UserService } from "./providers/user.service";
import { environment } from '../environments/environment';
import { SplashScreen } from '@capacitor/splash-screen';
import { CapacitorService } from './providers/capacitor.service';
import { NotificationService } from './providers/notification.service';
import { formatCurrency, formatNumber } from "@angular/common";
import { MatSidenav } from '@angular/material/sidenav';
import { UpdateService } from './providers/update.service';
import { time } from 'console';

export interface Notification {
  description?: string;
  recipient?: any;
  type?: string;
}

declare let cordova: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  showTabletFrame: boolean = false;
  APP_VERSION = environment.appVersion;
  HOTFIX_VERSION = environment.hotfixVersion;
  IS_APP = environment.isApp;
  CLIENT_ID = environment.clientId;
  CLIENT_TITLE = environment.clientTitle;
  clientLogoNavbar = environment.clientLogoNavbar;
  IS_TOUCH_DEVICE: boolean = false;
  BOOKSTORE_ACTIVE = environment.bookstoreModule;
  splashScreenBrowser: boolean = false;
  hideHotfixVersion: boolean = true;
  hotfixCount = 0;
  hotfixClickDate: any = new Date().getTime();
  deviceInfo;
  networkStatusOnline: any = {
    connected: true
  };
  surveyUrl: string = 'https://www.digi-sapiens.de/fragebogen-fuer-mentorinnen';
  speedtestUrl: string = 'https://fast.com/de/';
  roomId;
  userId;
  userType;
  dialogConfig: any;
  isCoordinator: boolean = false;
  isMentor: boolean = false;
  scientificSurvey: boolean = false;
  hideBackbutton: boolean = false;
  // ringTone: any;
  ringTone: any;
  private _unsubscribeAll: Subject<any>;
  private authListenerSubs: Subscription;
  private socketListenerSubs: Subscription;
  userIsAuthenticated: boolean = false;
  private notificationBadgeCountSub: Subscription;
  notificationBadgeCount;
  notifications: Notification[] = [];
  private notificationSub: Subscription;
  notificationMenu;
  @ViewChild('sidenav') sidenav: MatSidenav;

  @ViewChild('ringtone') audioRef: ElementRef;
  @HostListener('window:message', ['$event'])
        private onWindowMessage(event) {
            // Close modal if CF7 submittion succeeded
            if (~event.origin.indexOf('https://www.digi-sapiens.de')) {
                if(event.data == 'wpcf7submit-success') {
                  setTimeout(() => {
                    this.dialog.closeAll();
                  }, 2000);
                }
            } else { 
                // The data hasn't been sent from https://www.digi-sapiens.de
                return; 
            }
        }

  constructor(
    private platform: Platform,
    public router: Router,
    private webSocketService: WebSocketService,
    public webRTC: WebrtcService,
    private authService : AuthService,
    public authGuard:AuthGuard,
    public dialog: MatDialog,
    private capacitorService: CapacitorService,
    public userService: UserService,
    private titleService: Title,
    private notificationService: NotificationService,
    private updateService: UpdateService
  ) {
    this.initializeApp();
    // Set the private defaults
    this._unsubscribeAll = new Subject();

    // Check for touch device
    this.IS_TOUCH_DEVICE = this.authGuard.isTouchDevice();
    if (this.IS_TOUCH_DEVICE) {
        document.documentElement.classList.add('touch_detected');
    }

    // Capacitor device info
    this.capacitorService.readDeviceInfo().then(
        (data) => {
          this.deviceInfo = data;
          const devicePlatform = this.deviceInfo.platform.toLowerCase();

          // Set device data for other components
          if(this.IS_APP) {
            document.documentElement.classList.add('is_app');
            // Check for maintenance mode and app update
            this.updateService.checkForMaintenanceAndUpdatesListener();
          } else {
            // Check for maintenance mode and app update
            this.updateService.checkForMaintenanceAndUpdates();
          }
          // Add platform class to html
          document.documentElement.classList.add('platform_'+ devicePlatform);
          // Capacitor network status
          if (devicePlatform !== 'web') {
              // Check network status
              this.capacitorService.getNetworkStatus();
              // Call network status event listener
              this.capacitorService.startListenToNetwork();
              // Watch network status changes
              this.capacitorService.watchNetWorkStatus().pipe(
                  takeUntil(this._unsubscribeAll)
                ).subscribe(event => {
                  this.networkStatusOnline = event;
                  console.log('Network', this.networkStatusOnline);
              });
              if (devicePlatform === 'android') {
                // Add back button logic
                this.capacitorService.androidBackButtonLogic();
              }
          } else {
              // Show web splashscreen for 5 seconds only in production
              if (!this.IS_APP && environment.production) {
                // Splash for Mentor hessen only
                if (this.CLIENT_ID == 'app.mentor.lese') {
                  this.splashScreenBrowser = true;
                  setTimeout(() => {
                    this.splashScreenBrowser = false;
                  }, 5000);
                }
              }
          }
          // Listen for app status change
          this.capacitorService.appStateChange();
      },
      (err) => {
        console.log(err);
      }
    );
    // Statusbar from cordova as long as capacitor does not support ios
    //this.capacitorService.setStatusBarBackgroundColor('#f5f5f5');
    //document.documentElement.classList.add('touch_detected');

    this.router.events.pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((event: Event) => {
        if (event instanceof NavigationEnd) {
          if (event.url.includes('/room')) { 
            this.hideBackbutton = true;
          } else {
            this.hideBackbutton = false;
          }
        }
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      SplashScreen.hide();
    });
  }

  ngOnInit(): void {
    // Update title
    // let title = this.titleService.getTitle();
    // this.titleService.setTitle(title + ' v' + environment.appVersion);
    if (this.IS_APP) {
      this.showTabletFrame = false;
    }
    // Socket listener for mentor switching student
    this.socketListenerSubs = this.authService.getSocketListener()
      .subscribe(room => {
        if (room) {
          this.joinSocketRoom();
        }
    });

    // Load app settings
		this.authService.loadAppSettings();

    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authService.autoAuthUser();
    this.authListenerSubs = this.authService.getAuthStatusListener()
      .subscribe(isAuthenticated => {
        if (isAuthenticated) {
          this.userIsAuthenticated = isAuthenticated;
          console.log(this.userIsAuthenticated)
          this.userId = this.authService.getCurrentID();
          this.userType = this.authService.getType();
          this.scientificSurvey = this.authService.getScientificSurveyStatus();
          this.createSocket();
          //NOTIFICATION
          this.notificationService.getNotificationList();
          this.notificationSub = this.notificationService.getNotificationUpdateListener().pipe(
            takeUntil(this._unsubscribeAll)
          ).subscribe((notifications: Notification[]) => {
            this.notifications = notifications;
            console.log(this.notifications)
          });
          this.notificationBadgeCountSub = this.notificationService.currentnotificationBadgeCount.subscribe(currentnotificationBadgeCount => {
            this.notificationBadgeCount = currentnotificationBadgeCount;
          });
          //this.notificationService.increaseNotificationBadgeCount();
          this.webSocketService.listen('notification').subscribe((data) => this.handleNotification(data));
          this.webSocketService.listen('open-lexicon').subscribe((data) => this.handleLexiconUrl(data));
          this.webSocketService.listen('close-lexicon').subscribe((data) => this.handleLexiconClose());
          if (this.userType == 'student') {
            console.log('listening for call')
            this.webSocketService.listen('call-request').subscribe((data) => this.callRequestDialog(data));
            if (this.BOOKSTORE_ACTIVE) {
              // Init bookstore
              // this.authService.prepareBookstoreForUser(this.userId);
              this.userService.fetchById(this.userId).pipe(
                takeUntil(this._unsubscribeAll)
                  ).subscribe((res) => {
                    console.log('bookstore:')
                    console.log(res)
                    sessionStorage.setItem("userId", this.userId);
                    sessionStorage.setItem("userAge", res.age.toString());
                    let readingAbility = res.readingAbility ? res.readingAbility : 1;
                    sessionStorage.setItem("userReadingAbility", readingAbility.toString());
                    sessionStorage.setItem("userClubId", res.clubId.toString())
                    sessionStorage.setItem("userRole", res.role)
                    sessionStorage.setItem("userWallet", formatCurrency(res.credit, "de-DE", "€"));
                    // Update credit after purchase cause of subscription
                    this.authService.getUserCredit();
                    // this.getUserCredit();
                })

                // Get updated user tokens
                this.userService.getUserbyId(this.userId).pipe(
                  takeUntil(this._unsubscribeAll)
                ).subscribe(res => {
                    let userToken = res["token"].filter(token => token.status == "active").length;
                    sessionStorage.setItem("userToken", userToken);
                    this.authService.getUserToken();
                });
            }
          }
          // this.webSocketService.emit('login', { userId: this.userId });
          // Listen for cancelled call on both ends
          this.webSocketService.listen('call-cancelled').subscribe((data) => this.callRequestGotCancelled());
          // Listen for multiple logins
          this.webSocketService.listen('validate-token').subscribe((data) => this.validateUserToken());

          // Reset passwordResetRequest if successfully logged in after request
          if (this.authService.getPasswordResetRequestStatus()) {
              this.userService.updateUser({
                _id : this.userId,
                passwordResetRequest : false,
              }).pipe(
                takeUntil(this._unsubscribeAll)
              ).subscribe(response => {
                this.authService.setPasswordResetRequestStatus(response['passwordResetRequest']);
              });
          }
        } else {
          // Disconnect from socket if not logged in
          if (this.webSocketService.checkSocketStatus()) {
            this.webSocketService.disconnectFromSocket();
          }
        }
    });
    this.authService.setAuthenticatedStatus();
    // Create ring tone
    //this.ringTone = new Audio();
    //this.ringTone.src = "/assets/sounds/simple_beep.mp3";
    //this.ringTone.load();

    this.ringTone = document.getElementById('ringtone');
    this.ringTone.muted = true;
    // Safari fix
    // window.addEventListener('touchstart', () => {
    //   this.ringTone.play();
    //   this.ringTone.pause();
    //   this.ringTone.muted = true;
    // });
  }

  updateNotification(item, $event) {
    $event.stopPropagation();
    if (item?.readAt === null) {
      this.notificationService.updateNotification({
        notificationId :  item._id,
        status : "read",
      }).pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe((res) => {
        console.log(res)
      });
    }
  }

  /**
   * Listen for new notification and pass it to notifications list
   */
  handleNotification(notification) {
    this.notificationService.addNotificationSocketCallback(notification);
  }

  /**
   * Open modal by call
   */  
  callRequestDialog(deviceInfo) {
    if (this.router.url != "/room/" + this.roomId) {
      // Only prompt incoming call if student has a mentor
      if (this.authService.checkIfStudentHasMentor()) {
        // Set device info from call request
        this.webRTC.setPartnerDeviceInfo(deviceInfo);
        // Generate dialog
        this.dialogConfig = new MatDialogConfig();
        this.dialogConfig.disableClose = true;
        this.dialogConfig.autoFocus = false;
        //this.dialogConfig.width = '400px';
        this.dialogConfig.data = {
          title: 'Eingehender Anruf',
          note: 'Willst du den Anruf entgegenehmen?',
          accept: true,
          acceptIcon: 'phone',
          acceptText: 'Ja',
          decline: true,
          declineIcon: 'phone_disabled',
          declineText: 'Nein',
        };
        this.ringTone.play();
        this.ringTone.muted = false;
        this.dialog.closeAll();
        let dialogRef = this.dialog.open(ConfirmDialogComponent, this.dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
          // Navigate to room if call got accepted
          if (result == 'ok') {
            this.router.navigate(["/room/" + this.roomId]);
            // Inform mentor that call got accepted and send device info of receiver
            this.webSocketService.emitTo('call-accepted', this.webSocketService.getCurrentRoom(), this.capacitorService.getDeviceInfo());
          } else if (result == 'decline') {
            // Inform mentor of cancelling call request
            this.webSocketService.emitTo('call-cancelled', this.webSocketService.getCurrentRoom(), '');
          } else {}
          // Stop ringtone and reset for new call
          this.ringTone.pause();
          this.ringTone.currentTime = 0;
        });
      } else {
        console.log('Mentor tried to call student but mentor is not set')
        setTimeout(() => {
          this.webSocketService.emitTo('call-cancelled', this.webSocketService.getCurrentRoom(), '');
        }, 1000);
      }
    }
  }


  /**
   * Close modal on cancelled call
   */ 
  callRequestGotCancelled() {
    this.dialog.closeAll();
  }

  /**
   * create socket with userId
   */  
  createSocket() {
    this.webSocketService.initSocket(this.userId);
    this.joinSocketRoom();
  }

  /**
   * join socket room with the ID of Student
   */  
  joinSocketRoom() {
    if (this.userType == 'mentor' || this.userType == 'coordinator') {
      // Set first student as active if not already set
      if (!this.authService.getActiveStudent()) {
        this.authService.setFirstStudentOfMentorAsActive();
      }
      var activeStudent = this.authService.getActiveStudent();
      if (activeStudent != undefined) {
        console.log(activeStudent)
        this.roomId = activeStudent._id;
      }
    } else {
      this.roomId  = this.authService.getCurrentID();
    }
    if (this.BOOKSTORE_ACTIVE) {
      // Init bookstore
      // this.authService.prepareBookstoreForUser(this.roomId);
      if (this.roomId != undefined) {
        this.userService.fetchById(this.roomId).pipe(
          takeUntil(this._unsubscribeAll)
            ).subscribe((res) => {
              console.log('bookstore:')
              console.log(res)
              sessionStorage.setItem("userId", this.roomId);
              sessionStorage.setItem("userAge", res.age.toString());
              let readingAbility = res.readingAbility ? res.readingAbility : 1;
              sessionStorage.setItem("userReadingAbility", readingAbility.toString());
              sessionStorage.setItem("userClubId", res.clubId.toString())
              sessionStorage.setItem("userRole", res.role)
              sessionStorage.setItem("userWallet", formatCurrency(res.credit, "de-DE", "€"));
              // Update credit after purchase cause of subscription
              this.authService.getUserCredit();
              // this.getUserCredit();
          })
      }
    }
    if (this.userType == 'coordinator') {
      this.isCoordinator = true;
      this.isMentor = false;
    } else if (this.userType == 'mentor') {
      this.isCoordinator = false;
      this.isMentor = true;
    } else {
      this.isCoordinator = false;
      this.isMentor = false;
    }

    console.log(`this.roomId: r-${this.roomId}`)
    this.webSocketService.joinRoom(this.roomId);
  }

  goToLink(url: string){
      window.open(url, "_blank");
  }

  goToBugReportUrl() {
    let url = 'https://leseallianz.de/support';
    window.open(url, "_blank");
  }

  closeDrawer() {
    this.sidenav.close();
  }

  /**
   * logout and disconnect from Socket 
   */  
   logout() {
    // log info on server
    this.userService.logData('logout', '');
    // logout
    this.userIsAuthenticated = false;
    this.webSocketService.disconnectFromSocket();
    this.authService.logout();
  }

  /**
   * ask user to logout 
   */  
  logoutQuestion() {
    this.dialogConfig = new MatDialogConfig();
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.data = {
      title: 'Achtung',
      note: 'Benutzer wirklich abmelden?',
      accept: true,
      acceptIcon: '',
      acceptText: 'Ja',
      decline: true,
      declineIcon: '',
      declineText: 'Nein'
    };
    this.dialog.closeAll();
    let dialogRef = this.dialog.open(ConfirmDialogComponent, this.dialogConfig);
    dialogRef.afterClosed().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(result => {
      if (result == 'ok') {
       this.logout();
      }
    });
  }

  /**
   * open overlay for scietific survey or speedtest
   */  
   openIframeAsOverlay(url) {
    let wrapperClass = "";
    if (this.router.url.includes('/room')) {
       wrapperClass = "bookstore-overlay-panel";
    } else {
      wrapperClass = "iframe-overlay-pane";
    }
    let params = false;
    if (url == this.surveyUrl) { params = true; }
    const dialogRef = this.dialog.open(IframeComponent, {
      width: '100%',
      height: '80%',
      autoFocus: false,
      panelClass: wrapperClass,
      data: {
        url: url,
        mentorId: this.userId,
        params: params
      }
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(res => {
      this.dialog.closeAll();
      if (this.router.url.includes('/room')) {
        this.webSocketService.emitTo('close-lexicon', this.webSocketService.getCurrentRoom(), '');
      }
    });
  }

  /**
   * open overlay for search
   */  
   openIframeSearchField() {
     let wrapperClass = "";
    if (this.router.url.includes('/room')) {
       wrapperClass = "bookstore-overlay-panel";
    }
    const dialogRef = this.dialog.open(LexiconComponent, {
      width: '490px',
      height: '195px',
      autoFocus: true,
      panelClass: wrapperClass,
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(data => {
      if (data.searchTerm != undefined) {
        this.openIframeAsOverlay('https://de.wikipedia.org/wiki/' + data.searchTerm);
        if (this.userType != 'student' && this.router.url.includes('/room')) {
          this.webSocketService.emitTo('open-lexicon', this.webSocketService.getCurrentRoom(), data.searchTerm);
        }
      }
    });
  }

  handleLexiconUrl(url) {
    this.openIframeAsOverlay('https://de.wikipedia.org/wiki/' + url);
  }

  handleLexiconClose() {
    this.dialog.closeAll();
  }

  validateUserToken() {
    this.authService.validateToken();
  }
  
  logoRouting() {
    if (this.userIsAuthenticated) {
      if(this.router.url != "/room/" + this.roomId){
        if (this.userType != 'admin') {
          this.router.navigate(["/dashboard"]);
        } else {
          this.router.navigate(["/admin-dashboard"]);
        }
      }
    }
  }

  /**
   * Developer helper showing hotfix version on five times title click
   */
  showHotfixVersion() {
    const currentTime = new Date().getTime();
    let timeDiff = currentTime - this.hotfixClickDate;
    console.log(timeDiff)
    if (timeDiff < 1000) {
      this.hotfixCount++;
    } else {
      this.hotfixCount = 0;
      this.hideHotfixVersion = true;
    }
    if (this.hotfixCount > 4) {
      this.hideHotfixVersion = false;
    }
    this.hotfixClickDate = currentTime;
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
      // Unsubscribe from all subscriptions
      this._unsubscribeAll.next(true);
      this._unsubscribeAll.complete();
      this.authListenerSubs.unsubscribe();
  }
}