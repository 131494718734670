import { Component, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { AuthService } from "../../auth/auth.service";
import { FormBuilder, FormControl, FormGroup, Validators, NgForm, FormGroupDirective } from '@angular/forms';
import { Router } from '@angular/router';
import { ReplaySubject, Subscription, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { UserService } from '../../providers/user.service';
import { SchoolService } from '../../providers/school.service';
import { Location } from '@angular/common';
import moment from 'moment';

interface School {
  _id: string;
  name: string;
  streetNr?: string;
  plz?: string;
  city?: string;
  typeOfSchool: string;
}

interface Coordinator {
  _id: string;
  username: string;
  firstname?: string;
  lastname?: string;
  role: string;
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  rForm: FormGroup;
  userType;
  isAdmin;
  isStudent = false;
  isMentor = false;
  isCoordinator = false;
  isRequired = true;
  roleInit = '';
  hideRole: boolean = true;
  loanedDevice: boolean = false;
  userId;
  schools : School[] = [];
  isPermissionsMembershipType: boolean = false;
  //coordinators : Coordinator[] = [];

  public school: FormControl = new FormControl();
  //public coordinator: FormControl = new FormControl();

  public schoolFilter: FormControl = new FormControl();
  //public coordinatorFilter: FormControl = new FormControl();
  /** list of schools filtered by search keyword */
  public filteredSchools: ReplaySubject<School[]> = new ReplaySubject<School[]>(1);
  //public filteredCoordinators: ReplaySubject<Coordinator[]> = new ReplaySubject<Coordinator[]>(1);
  //private coordinatorsSub: Subscription;
  private schoolsSub: Subscription;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    public authService: AuthService,
    private router: Router,
    private userService : UserService,
    private schoolService : SchoolService,
    private location: Location,
    private _formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.userType = this.authService.getType();
    this.userId = this.authService.getCurrentID();

    if (this.userType == "admin") {
      this.isAdmin = true;
    }

    this.userService.getUserbyId(this.userId).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(data => {
      if (data.hasOwnProperty('permissions')) {
        if (data["permissions"].includes("user:create:all")) {
          this.isPermissionsMembershipType = true;
        } else {
          this.isPermissionsMembershipType = false;;
        }
      }
    });

    // Set role depending on current slug
    if (this.location.path() == '/student-create') {
      this.roleInit = 'student';
    } else if (this.location.path() == '/coordinator-create') {
      this.roleInit = 'coordinator';
    } else if (this.location.path() == '/mentor-create') {
      this.roleInit = 'mentor';
    } else {
      this.roleInit = 'admin';
      //this.hideRole = false;
    }

    this.rForm = this._formBuilder.group({
      username  : ['', Validators.required],
      firstname     : ['', Validators.required],
      lastname     : ['', Validators.required],
      gender     : ['', Validators.required],
      email     : [''],
      school     : ['' , [Validators.required]],
      schoolFilter: [],
      //coordinator     : [''],
      password  : ['', [Validators.required, Validators.minLength(3)]],
      role      : [ this.roleInit, [Validators.required]],
      phoneNumber     : [''],
      mobileNumber     : [''],
      birthday : [''],
      supervisingSchools: [''],
      readingAbilityRate: [''],
      comment: [''],
      schoolClass:[''],
      membershipType:[''],
      membershipPeriod: [''],
      terminationOfContract: [''],
      preferredTypeOfSchool: [''],
      studentsCapacity: [''],
      loanedDeviceSerial: [''],
      availabilityMo  : [''],
      availabilityTu  : [''],
      availabilityWe  : [''],
      availabilityTh  : [''],
      availabilityFr  : [''],
      availabilitySa  : [''],
      availabilitySu  : ['']
    });

    // Get coordinators from api
    /*
    this.userService.getCoordinatorsList();
    this.coordinatorsSub = this.userService.getCoordinatorsUpdateListener().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((coordinators: Coordinator[]) => {
      console.log(coordinators)
        this.coordinators = coordinators;
        // set select for coordinator
        this.coordinator.patchValue(this.coordinators);
        // refresh coordinators search filter
        this.filteredCoordinators.next(this.coordinators.slice());
      });

    this.coordinatorFilter.valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.filterCoordinators();
      });
    */

    // Get schools from api
    if(this.roleInit == 'student') {
      this.schoolService.getSchoolList();
    } else {
      this.schoolService.getAvailableSchoolList();
    }
    this.schoolsSub = this.schoolService.getSchoolUpdateListener().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((schools: School[]) => {
        this.schools = schools;
        // set select for coordinator
        this.school.patchValue(this.schools);
        // refresh coordinators search filter
        this.filteredSchools.next(this.schools.slice());
        // Fire student and coordinator logic
        this.getSelectedRole({ value: this.roleInit });
      });


    this.schoolFilter.valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.filterSchools();
      });
  }

  // selected role value
  getSelectedRole(event) {
    // Reset dynamic fields
    this.rForm.get('school').reset();
    //this.rForm.get('coordinator').reset();
    this.rForm.get('supervisingSchools').reset();

    // show school select and set validator required
    if (event.value == "student") {
      this.isStudent = true;
      this.rForm.get('school').setValidators([Validators.required]);
      this.rForm.get('school').updateValueAndValidity();
    } else {
      this.isStudent = false;
      this.rForm.get('school').clearValidators();
      this.rForm.get('school').updateValueAndValidity();
    }

    // show coordinator select and set validator required
    if (event.value == "mentor") {
      this.isMentor = true;
      //this.rForm.get('coordinator').setValidators([Validators.required]);
      //this.rForm.get('coordinator').updateValueAndValidity();
    } else {
      this.isMentor = false;
      //this.rForm.get('coordinator').clearValidators();
      //this.rForm.get('coordinator').updateValueAndValidity();
    }

    // show school select and set validator required
    if (event.value == "coordinator") {
      this.isCoordinator = true;
      this.rForm.get('supervisingSchools').setValidators([Validators.required]);
      this.rForm.get('supervisingSchools').updateValueAndValidity();
    } else {
      this.isCoordinator = false;
      this.rForm.get('supervisingSchools').clearValidators();
      this.rForm.get('supervisingSchools').updateValueAndValidity();
    }
  }

  filterSchools() {
    if (!this.schools) {
      return;
    }
    // get the search keyword
    let search = this.schoolFilter.value;
    if (!search) {
      this.filteredSchools.next(this.schools.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the schools by name
    this.filteredSchools.next(
      this.schools.filter(school => school.name.toLowerCase().indexOf(search) > -1)
    );
  }

  /*
  filterCoordinators() {
    if (!this.coordinators) {
      return;
    }
    // get the search keyword
    let search = this.coordinatorFilter.value;
    if (!search) {
      this.filteredCoordinators.next(this.coordinators.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the coordinators by (firstname/ lastname)
    this.filteredCoordinators.next(
      this.coordinators.filter(coordinator => coordinator.firstname.toLowerCase().indexOf(search) > -1 || coordinator.lastname.toLowerCase().indexOf(search) > -1)
    );
  }
  */

  /**
   * submitForm
   */
  submitForm(formDirective: FormGroupDirective) {
    if (this.rForm.invalid) {
      this.rForm.get("firstname").markAsTouched();
      this.rForm.get("username").markAsTouched();
      this.rForm.get("lastname").markAsTouched();
      this.rForm.get("gender").markAsTouched();
      this.rForm.get("school").markAsTouched();
      this.rForm.get("role").markAsTouched();
      this.rForm.get("password").markAsTouched();
      //this.rForm.get("coordinator").markAsTouched();
      this.rForm.get("supervisingSchools").markAsTouched();
      
      return
    }
    let birthday = null; 
    if (this.rForm.value.birthday) {
      birthday = new Date(moment(this.rForm.value.birthday).format("YYYY-MM-DD"));
    }
    let availability = {
      monday: this.rForm.value.availabilityMo,
      tuesday: this.rForm.value.availabilityTu,
      wednesday: this.rForm.value.availabilityWe,
      thursday: this.rForm.value.availabilityTh,
      friday: this.rForm.value.availabilityFr,
      saturday: this.rForm.value.availabilitySa,
      sunday: this.rForm.value.availabilitySu,
    };

    let loanedDeviceSerial = this.rForm.value.loanedDeviceSerial;
    if (!this.loanedDevice) {
      loanedDeviceSerial = '';
    }

    let readingAbilityRate = this.rForm.value.readingAbilityRate;
    if (!readingAbilityRate) {
      readingAbilityRate = 'keine Angaben';
    }

    this.authService.createUser(
      this.rForm.value.username,
      this.rForm.value.password,
      this.rForm.value.firstname,
      this.rForm.value.lastname,
      this.rForm.value.gender,
      this.rForm.value.email,
      this.rForm.value.role,
      this.rForm.value.school,
      //this.rForm.value.coordinator,
      this.rForm.value.phoneNumber,
      this.rForm.value.mobileNumber,
      birthday,
      JSON.stringify(availability),
      this.rForm.value.supervisingSchools,
      readingAbilityRate,
      this.rForm.value.comment,
      this.rForm.value.schoolClass,
      this.rForm.value.membershipType,
      this.rForm.value.membershipPeriod,
      this.rForm.value.terminationOfContract,
      this.rForm.value.preferredTypeOfSchool,
      this.rForm.value.loanedDeviceSerial,
      this.rForm.value.studentsCapacity,
      );
    // formDirective.resetForm();
    // this.rForm.reset();
  }

  ngOnDestroy() {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
