<div class="container-fluid pt-3">
  <div class="row">
    <div class="col-md-4 col-sm-12 px-3">
      <div class="button-group">
        <!-- <button mat-button color="accent" [routerLink]="['/dashboard']" class="size-big">
          zur Übersicht
        </button> -->
        <button mat-button color="accent" (click)="openDialog()" class="size-big">Buch auswählen</button>
        <button mat-button color="accent" (click)="startQuizDialog()" class="size-big" *ngIf="quizAvailable">Quiz</button>
        <!-- <button mat-button color="accent" (click)="updateReadBooksOnStudent()">
          Update book
        </button> -->
      </div>
    </div>
    <div class="col-md-8 col-sm-12">
      <!-- <app-bookreader [readingMode]="'Solo'" [bookUrl]="bookUrl" *ngIf="readingModeIsEpub"></app-bookreader> -->
      <app-pdfreader [readingMode]="'Solo'" [bookUrl]="bookUrl" [bookActivePage]="bookActivePage" [toolButtons]="false" (activePage)="getActivePage($event)" *ngIf="readingModeIsPDF"></app-pdfreader>
    </div>
  </div>
</div>