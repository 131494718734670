<div class="c-book-list-single" (click)="showBookDetails()">
  <img
    src="{{bookstoreServerUrl}}/picture/{{isbn}}"
    width="150px"
    height="200px"
  />
  <div class="text-center">
    <div>
      <b class="price-text">{{ price }}</b>
      <app-readability
        [readabilityScore]="readabilityScore"
        [width]="'70%'"
        [height]="'15px'"
      ></app-readability>
    </div>
  </div>
</div>
