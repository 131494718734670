<div class=" container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="reading-button-wrap">
        <h1>Hast du Lust auf ein Quiz?</h1>
        <button mat-button color="accent" class="size-large" [mat-dialog-close]="'yes'">Ja, bitte</button>
        <button mat-button color="accent" class="size-large" [mat-dialog-close]="'later'">Leise weiter lesen und dann Quiz</button>
        <button mat-button color="accent" class="size-large"[mat-dialog-close]="'no'">Nein, danke</button>
      </div>
    </div>
  </div>
</div>