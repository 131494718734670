import { createFeatureSelector, createSelector } from '@ngrx/store';
import { userFeatureKey, userLibFeatureKey, UserStateModel, UserLibStateModel } from './user.reducer';

const getUserState = createFeatureSelector<UserStateModel>(userFeatureKey);
const getUserLibState = createFeatureSelector<UserLibStateModel>(userLibFeatureKey);

const isFetchByIdLoading = createSelector(
  getUserState,
  (userState: UserStateModel) => userState.isFetchByIdLoading
);

const getFetchByIdError = createSelector(
  getUserState,
  (userState: UserStateModel) => userState.fetchByIdError
);

const getUser = createSelector(
  getUserState,
  (userState: UserStateModel) => userState.user
);

const isLibByIdLoading = createSelector(
  getUserLibState,
  (userLibState: UserLibStateModel) => userLibState.isLibByIdLoading
);

const getLibByIdError = createSelector(
  getUserLibState,
  (userLibState: UserLibStateModel) => userLibState.libByIdError
);

const getUserLib = createSelector(
  getUserLibState,
  (userLibState: UserLibStateModel) => userLibState.userlib
);

export const userSelectors = {
  isFetchByIdLoading,
  getFetchByIdError,
  getUser
};

export const userLibSelectors = {
  isLibByIdLoading,
  getLibByIdError,
  getUserLib
};
