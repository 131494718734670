import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SpeedTestComponent } from './speed-test.component';
import { SpeedTestService } from './services/speed-test.service';
import { MaterialModule } from '../../material.module';

@NgModule({
  declarations: [
    SpeedTestComponent
  ],
  entryComponents: [
    SpeedTestComponent
  ],
  imports: [
    BrowserModule,
    MaterialModule
  ],
  providers: [
    SpeedTestService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [SpeedTestComponent]
})
export class SpeedTestModule {}
