<ng-container *ngIf="isLoading === false; else loader">
  <ng-container *ngIf="error">
    {{ error.statusText }}
  </ng-container>

  <ng-container *ngIf="book">
    <div mat-dialog-title>
      <span class="book-title">{{ book.title }}</span>
      <span class="user-credits-purchase"><span *ngIf="isMentor">Guthaben Lesekind: {{mentorCredit}} </span><span *ngIf="!isMentor">Dein Guthaben : {{sessionStorage.getItem("userToken")}}</span> Token | <b class="book-price">Preis: {{getPrice(book.price)}}</b></span>
    </div>
    <mat-dialog-content>
      <div class="flexbox">
        <div class="book-dialog-image">
          <img
            src="{{bookstoreServerUrl}}/picture/{{isbn}}"
            height="250"
          />
        </div>
        <div class="flex-1">
          <ul class="book-dialog-details">
            <li><b class="bold-class-for-bold-tag">Autor:</b> {{ book.author }}</li>
            <li><b class="bold-class-for-bold-tag">Anzahl Seiten:</b> {{ book.pages }}</li>
            <li *ngIf="book.lower_age_barrier"><b class="bold-class-for-bold-tag">Für Leser ab: </b>{{ book.lower_age_barrier }} Jahren</li>
            <li *ngIf="book.difficulty"><b class="bold-class-for-bold-tag">Schwierigkeitsgrad:</b> {{ book.difficulty }}</li>
            <li>
              <b class="bold-class-for-bold-tag">
                Wie andere Kinder das Buch fanden:
              </b>
              <b *ngIf="!book.rating"><br>Leider wurden noch keine Bewertungen abgegeben.</b>
              <app-review
                *ngIf="book.rating"
                [value]="book.rating"
                class="book-review"
              ></app-review>
            </li>
          </ul>
        </div>
      </div>

      <div class="book-content-preview">
        <b>Darum geht es in dem Buch:</b>
        <span [innerHtml]="book.description | safeHtml:'br'"></span>
      </div>

      <div class="action-buttons">
        <button class="btn btn-block btn-primary" (click)="openPreview()">
          Vorschau
        </button>
        <!-- <button
          class="btn btn-block btn-primary"
          (click)="setBookonWatchlist()"
        >
          Auf Merkliste setzen
        </button> -->
        <button [mat-dialog-close]="true" class="btn btn-block btn-secondary">
          Zurück
        </button>
        <button class="btn btn-block btn-danger" (click)="buyBook()" *ngIf="!isMentor && bookPurchaseable && !isLoading">
          Kaufen
        </button>
        <button class="btn btn-block" *ngIf="!bookPurchaseable && !isLoading" disabled>
          Dieses Buch hast Du bereits gekauft
        </button>
      </div>
    </mat-dialog-content>
  </ng-container>
</ng-container>

<ng-template #loader>
  <mat-spinner [diameter]="50" style="margin: 0 auto"></mat-spinner>
</ng-template>
