
<div class="container-fluid pt-3">
    <div class="row justify-content-center">
        <div class="col-md-6 col-sm-12">
            <h1>Neues Buch erstellen</h1>
            <form [formGroup]="rForm" #formDirective="ngForm">
                <mat-form-field>
                    <input matInput formControlName="isbn"  type="text" placeholder="Buch ISBN" required>
                </mat-form-field>

                <button mat-raised-button color="primary" type="button" (click)="submitForm(formDirective)">Speichern</button>
            </form>
        </div>
    </div>
</div>