import { Component, Inject, OnInit } from '@angular/core';
import { UserService } from "../../../providers/user.service";
import { PurchaseBody } from "../../shared/models/purchase-body";
import { identity } from "rxjs";
import { getIifeBody } from "@angular/compiler-cli/ngcc/src/host/esm2015_host";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { BookWithIsbn } from "../../shared/models/book-startpage";
import { UserFacade } from "../../shared/store/user/user.facade";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";

@Component({
  selector: 'app-watch-list-dialog',
  templateUrl: './watch-list-dialog.component.html',
  styleUrls: ['./watch-list-dialog.component.scss'],
})
export class WatchListDialogComponent {
  private bookstoreServerUrl;
  book: BookWithIsbn;
  isbn: bigint;
  CLUB_ID = environment.clubId;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private httpClient: HttpClient,
    private dialogRef: MatDialogRef<WatchListDialogComponent >
  ) {
    let protocol = environment.ssl ? 'https://' : 'http://';
    this.bookstoreServerUrl = protocol + environment.bookstoreServerUrl + ":" + environment.bookstoreServerPort;
    this.book = data.book
    this.isbn = data.isbn
  }

  rememberBook() {
    let body: PurchaseBody;
    let id = sessionStorage.getItem("userId");
    let userId = "";
    if (id) {
      userId = id;
    }
    body = { userId: userId, isbn: this.isbn, clubId: this.CLUB_ID };
    console.log(body)
    this.httpClient.post<void>(
      `${this.bookstoreServerUrl}/user/wishlist`,
      body,{observe : "response"}).subscribe(response => {
      if(response.status == 204){
        this.dialogRef.close();
      }
    });
  }
}
