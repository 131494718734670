import { Component, AfterViewInit, ViewChild, ElementRef, Input } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { WebSocketService } from '../providers/websocket.service';
import { AuthService } from '../auth/auth.service';
import { UserService } from '../providers/user.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-metamorphose',
  templateUrl: './metamorphose.component.html',
  styleUrls: ['./metamorphose.component.scss'],
})
export class MetamorphoseComponent implements AfterViewInit {

  constructor(
    //public elRef: ElementRef,
    private webSocketService: WebSocketService,
    private authService : AuthService,
    private userService : UserService,
    private ref: ChangeDetectorRef
  ) { }

  public metamorphose;
  videoDuration = 0;
  debug: boolean = false;
  playTime = 3000;
  avatar;
  selectedmetamorphose;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  @Input('userType') isMentor;
  @Input('partnerId') partnerId: string;
  @ViewChild("metamorphose", {static: false}) metamorphoseRef: ElementRef;

  ngAfterViewInit() {
      if (this.isMentor) {
        if (this.partnerId) {
          this.userService.getUserbyId(this.partnerId).pipe(
            takeUntil(this._unsubscribeAll)
          ).subscribe(res => {
            this.selectedmetamorphose = res["avatar"] ? res["avatar"] : '';
            this.initMetamorphose();
          });
        }
      } else {
        this.selectedmetamorphose = this.authService.getActiveAvatar();
        this.initMetamorphose();
      }
  }

  initMetamorphose() {
    // Check first if ViewChild is loaded
    this.ref.detectChanges();
    if (this.selectedmetamorphose != undefined) {
        this.metamorphose = this.metamorphoseRef.nativeElement;
        console.log(this.metamorphose)
        var _this = this;
        this.metamorphose.onloadeddata = (event) => {
          _this.metamorphose.muted = true;
          const {
            // videoHeight,
            // videoWidth,
            duration
          } = event.srcElement;
          _this.videoDuration = duration;
          _this.playTime = _this.videoDuration*1000/4; // Float -> if problems accure, use Math.floor() for intager
          console.log(this.playTime)
          console.log(this.videoDuration)
          // Android fix
          _this.metamorphose.play();
          setTimeout(function(){
            _this.metamorphose.pause(); 
          }, 250);
        };
        // Listen for play event
        this.webSocketService.listen('metamorphose-play').pipe(
          takeUntil(this._unsubscribeAll)
        ).subscribe((data) => this.playVideo());
        // Listen for reset event
        this.webSocketService.listen('metamorphose-reset').pipe(
          takeUntil(this._unsubscribeAll)
        ).subscribe((data) => this.resetVideo());
    }
  }

  /**
   *  start metamophose video
   */
  playVideo() {
    console.log('play triggered from meta play')
    this.metamorphose.play();
    setTimeout(function(){
        this.metamorphose.pause(); 
    }, this.playTime);
  }

  /**
   *  reset metamophose video
   */
  resetVideo() {
    this.metamorphose.pause(); 
    this.metamorphose.currentTime = 0;
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    setTimeout(() => {
      //console.log('metamorphose unsubscribe -------------')
      this._unsubscribeAll.next(true);
      this._unsubscribeAll.complete();
    }, 100);
  }
}
