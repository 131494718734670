import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../providers/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ReplaySubject, Subscription, Subject } from 'rxjs';
import { NgForm, FormControl, FormGroup, FormGroupDirective, Validators } from "@angular/forms";
import { SchoolService } from '../../../providers/school.service';
import { takeUntil } from 'rxjs/operators';

export interface Coordinator {}
interface School {
  _id: string;
  name: string;
  streetNr?: string;
  plz?: string;
  city?: string;
  typeOfSchool: string;
}
@Component({
  selector: 'app-coordinator-edit',
  templateUrl: './coordinator-edit.component.html',
  styleUrls: ['./coordinator-edit.component.scss'],
})
export class CoordinatorEditComponent implements OnInit {
  id;
  coordinator: Coordinator[] = [];
  rForm: FormGroup;
  subscription: Subscription;
  username: string;
  schools : School[] = [];
  public schoolFilter: FormControl = new FormControl();

  public filteredSchools: ReplaySubject<School[]> = new ReplaySubject<School[]>(1);
  protected _onDestroy = new Subject<void>();
  private schoolsSub: Subscription;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private userService : UserService,
    private schoolService : SchoolService,
    private router: Router,
    private route:ActivatedRoute,
  ) { }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.userService.getUserbyId(this.id).subscribe(res => {
      this.coordinator = res;
      console.log(res)
    });

    this.initForm();
  }

  initForm(){
    this.rForm = new FormGroup({
      firstname: new FormControl(null, {
        validators: [Validators.required]
      }),
      lastname: new FormControl(null, {
        validators: [Validators.required]
      }),
      gender: new FormControl(null, {
        validators: [Validators.required]
      }),
      email: new FormControl(null, {
        validators: []
      }),
      phoneNumber: new FormControl(null, {
        validators: []
      }),
      mobileNumber: new FormControl(null, {
        validators: []
      }),
      birthday: new FormControl(null, {
        validators: []
      }),
      comment: new FormControl(null, {
        validators: []
      }),
      supervisingSchools: new FormControl(null, {
        validators: [Validators.required]
      }),
      activeMembership: new FormControl(null, {
        validators: []
      }),
      scientificSurvey: new FormControl(null, {
        validators: []
      }),
      preferredTypeOfSchool: new FormControl(null, {
        validators: []
      }),
      studentsCapacity: new FormControl(null, {
        validators: []
      }),
      availabilityMo  : new FormControl(null, {
        validators: []
      }),
      availabilityTu  : new FormControl(null, {
        validators: []
      }),
      availabilityWe  : new FormControl(null, {
        validators: []
      }),
      availabilityTh  : new FormControl(null, {
        validators: []
      }),
      availabilityFr  : new FormControl(null, {
        validators: []
      }),
      availabilitySa  : new FormControl(null, {
        validators: []
      }),
      availabilitySu  : new FormControl(null, {
        validators: []
      })
    });

    // Get schools from api
    //this.schoolService.getAvailableSchoolListById(this.id);
    this.schoolService.getSchoolList();
    this.schoolsSub = this.schoolService.getSchoolUpdateListener().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((schools: School[]) => {
      console.log(schools)
        this.schools = schools;
        this.filteredSchools.next(this.schools.slice());
      });


    this.schoolFilter.valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.filterSchools();
      });

    //fill input field with data from user object
    this.subscription = this.userService.getUserbyId(this.id).subscribe(coordinator => {
      this.username = coordinator['username'];
      this.rForm.get('firstname').setValue(coordinator['firstname']);
      this.rForm.get('lastname').setValue(coordinator['lastname']);
      this.rForm.get('gender').setValue(coordinator['gender']);
      this.rForm.get('email').setValue(coordinator['email']);
      this.rForm.get('phoneNumber').setValue(coordinator['phoneNumber']);
      this.rForm.get('mobileNumber').setValue(coordinator['mobileNumber']);
      this.rForm.get('birthday').setValue(coordinator['birthday']);
      this.rForm.get('comment').setValue(coordinator['comment']);
      this.rForm.get('supervisingSchools').setValue(coordinator['supervisingSchools']);
      let activeMembership = true;
      if (coordinator['activeMembership'] == undefined) {
        activeMembership = false;
      } else {
        activeMembership = coordinator['activeMembership'];
      }
      this.rForm.get('activeMembership').setValue(activeMembership);
      let scientificSurvey = false;
      if (coordinator['scientificSurvey'] == undefined) {
        scientificSurvey = false;
      } else {
        scientificSurvey = coordinator['scientificSurvey'];
      }
      this.rForm.get('scientificSurvey').setValue(scientificSurvey);
      this.rForm.get('preferredTypeOfSchool').setValue(coordinator['preferredTypeOfSchool']);
      this.rForm.get('studentsCapacity').setValue(coordinator['studentsCapacity']);
      if (coordinator['availability']) {
        let availability = JSON.parse(coordinator['availability']);
        this.rForm.get('availabilityMo').setValue(availability.monday);
        this.rForm.get('availabilityTu').setValue(availability.tuesday);
        this.rForm.get('availabilityWe').setValue(availability.wednesday);
        this.rForm.get('availabilityTh').setValue(availability.thursday);
        this.rForm.get('availabilityFr').setValue(availability.friday);
        this.rForm.get('availabilitySa').setValue(availability.saturday);
        this.rForm.get('availabilitySu').setValue(availability.sunday);
      }
    })
  }

  filterSchools() {
    if (!this.schools) {
      return;
    }
    // get the search keyword
    let search = this.schoolFilter.value;
    if (!search) {
      this.filteredSchools.next(this.schools.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the schools by name
    this.filteredSchools.next(
      this.schools.filter(school => school.name.toLowerCase().indexOf(search) > -1)
    );
  }

  compareById(object1: any, object2: any) {
      return object1 && object2 && object1._id == object2._id;
  }

  submitForm(formDirective: FormGroupDirective) {
    if (this.rForm.invalid) {
      this.rForm.get("firstname").markAsTouched();
      this.rForm.get("lastname").markAsTouched();
      this.rForm.get("gender").markAsTouched();
      this.rForm.get("supervisingSchools").markAsTouched();
      return;
    }
    this.userService.updateUser({
      _id : this.id,
      firstname : this.rForm.value.firstname,
      lastname : this.rForm.value.lastname,
      gender : this.rForm.value.gender,
      email : this.rForm.value.email,
      phoneNumber : this.rForm.value.phoneNumber,
      mobileNumber : this.rForm.value.mobileNumber,
      birthday : this.rForm.value.birthday,
      comment: this.rForm.value.comment,
      availability : this.rForm.value.availability,
      activeMembership : this.rForm.value.activeMembership,
      scientificSurvey: this.rForm.value.scientificSurvey,
      preferredTypeOfSchool : this.rForm.value.preferredTypeOfSchool,
      supervisingSchools: this.rForm.value.supervisingSchools,
      studentsCapacity: this.rForm.value.studentsCapacity
    }).subscribe(res => {
      this.router.navigate(['/coordinator-list']);
    });
  }

  ngOnDestroy() {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
