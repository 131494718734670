<div mat-dialog-container>
  <div mat-dialog-title>
    <span class="book-title">Ein kurze Vorschau auf das Buch</span>
    <span class="user-credits-purchase"><span *ngIf="isMentor">Guthaben Lesekind: {{mentorCredit}} </span><span *ngIf="!isMentor">Dein Guthaben : {{sessionStorage.getItem("userToken")}}</span> Token | <b class="book-price">Preis: {{getPrice(book.price)}}</b></span>
  </div>
  <div class="book-preview-container">
    <div class="header-container">

    </div>
    <div id="pdf-viewer" class="item-center" [hidden]="isLoading">
      <ngx-extended-pdf-viewer
        [src]="bookUrl"
        [zoom]="zoom"
        pageViewMode="single"
        backgroundColor="white"
        [useBrowserLocale]="true"
        [height]="pdfOptions.height"
        [showBorders]="false"
        [showPrintButton]="false"
        [showFindButton]="false"
        [showRotateButton]="false"
        [showPropertiesButton]="false"
        [showSecondaryToolbarButton]="false"
        [showSelectToolButton]="false"
        [showHandToolButton]="false"
        [showScrollingButton]="false"
        [showSpreadButton]="false"
        [showPresentationModeButton]="false"
        [showSidebarButton]="false"
        [showDownloadButton]="false"
        [showBookmarkButton]="false"
        [showOpenFileButton]="false"
        [showZoomButtons]="false"
        [showSidebarOnLoad]="false"
        [showPagingButtons]="true"
        (pdfLoaded)="onPdfLoaded($event)"
      ></ngx-extended-pdf-viewer>
    </div>
    <div class="item-center" *ngIf="bookUrl == ''">
      <span class="preview-error-message">Leider kannst Du für dieses Buch keine Vorschau ansehen.</span>
    </div>
    <div class="buttons">
      <button class="btn btn-secondary" [mat-dialog-close]="true">Zurück</button>
    </div>
  </div>
</div>
