import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
//import * as Sentry from "@sentry/capacitor";
import * as SentryAngular from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.hasOwnProperty('sentryDNS') && environment.hasOwnProperty('sentryEnvironment')) {
  const sentryOptions = {
    dsn: environment.sentryDNS,
    release: 'mla-frontend@' + require('../package.json').version,
    environment: environment.sentryEnvironment,
    dist: require('../package.json').hotfix,
    tracesSampleRate: 1.0
  };
  if (environment.isApp) {
    //Sentry.init(sentryOptions, SentryAngular.init);
    SentryAngular.init;
  } else {
    sentryOptions['integrations'] = [
      new BrowserTracing({
        tracingOrigins: ['localhost', /^\//],
        routingInstrumentation: SentryAngular.routingInstrumentation,
      }),
    ];
    SentryAngular.init(sentryOptions);
  }
}

if (environment.production) {
  enableProdMode();
  // Don't log to console in production environment.
  if(window){
    window.console.log = window.console.warn = window.console.info = function(){};
  }
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
