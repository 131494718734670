import { Component, OnInit, ViewChild } from '@angular/core';
import { takeUntil, tap, retryWhen } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import { MatTableDataSource,} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { BookService } from '../../providers/book.service';
import { AuthService } from "../../auth/auth.service";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { PurchaseBody } from "../../bookstore/shared/models/purchase-body";
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '../../providers/user.service';

export interface BookDonation {
  _id: string;
}

@Component({
  selector: 'app-admin-book-donation-list',
  templateUrl: './admin-book-donation-list.component.html',
  styleUrls: ['./admin-book-donation-list.component.scss'],
})
export class AdminBookDonationListComponent implements OnInit {
  private bookstoreServerUrl;
  CLUB_ID = environment.clubId;
  userId;
  displayedColumns: string[] = ['isbn', "donor", "contingent", 'usedContingent', 'stockContingent', 'created', 'buttons'];
  dataSource:any;
  bookDonation: any;
  isPermissionsDonationAdd: boolean = false;
	@ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  isAdmin = false;
  userType;


  private bookDonationSub: Subscription;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
     private bookService : BookService,
     public authService: AuthService,
     private http: HttpClient,
     private snackBar: MatSnackBar,
     private userService: UserService
  ) {
    let protocol = environment.ssl ? 'https://' : 'http://';
    this.bookstoreServerUrl = protocol + environment.bookstoreServerUrl + ":" + environment.bookstoreServerPort;
  }

  ngOnInit() {
    this.userId = this.authService.getCurrentID();
    this.userType = this.authService.getType();
    if (this.userType == 'admin') {
      this.isAdmin = true;
    }
    this.bookService.getListOfBookDonations();
    this.bookDonationSub = this.bookService.getBookDonationUpdateListener().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((bookDonation) => {
        this.bookDonation = bookDonation;
        this.dataSource = new MatTableDataSource<BookDonation>(this.bookDonation);
        // pagination and sort table
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (value, sortHeaderId) => value[sortHeaderId];
        this.dataSource.paginator = this.paginator;
      });

    this.userService.getUserbyId(this.userId).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(data => {
      if (data.hasOwnProperty('permissions')) {
        if (data["permissions"].includes("donation:add")) {
          this.isPermissionsDonationAdd = true;
        } else {
          this.isPermissionsDonationAdd = false;
        }
      }
    });
  }
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  purchaseBookDonation(isbn, contingent): void {
    let body: PurchaseBody;
    body = { userId: this.userId, isbn: isbn, clubId: this.CLUB_ID };
    console.log(body)
    let count = 0;
    this.http.post<void>(
      `${this.bookstoreServerUrl}/book/purchase`,
      body,{observe : "response"}).pipe(
        tap(res => {
          count++;
          // Throw error to buy again until contingent was reached
          if (count < contingent) {
            // error will be picked up by retryWhen
            throw count;
          }
          return res;
        }),
        retryWhen(errors =>
          errors.pipe(
            //log error message
            tap(val => console.log(`Contingent ${val} not yet reached, buy again!`)),
          )
        )
      ).subscribe(response => {
        console.log(response)
        if (response.status == 204 || response.status == 200 ){
          this.snackBar.open("Kauf von ISBN " + isbn + " erfolgreich durchgeführt.", "",{
						duration: 3000,
						horizontalPosition: 'right'
					});
        }
      },
      error => {
        if (error.status == 400) {
          this.snackBar.open("Der Kauf von ISBN " + isbn + " konnte nicht durchgeführt werden.", "",{
            panelClass: 'snack-error',
						duration: 3000,
						horizontalPosition: 'right'
					});
        }
      });
  }
}
