import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { ChatService } from '../providers/chat.service';
import { takeUntil } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import { FormBuilder, FormGroup, FormGroupDirective } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { UserService } from "../providers/user.service";
import { WebSocketService } from '../providers/websocket.service';

export interface Chat {
  description?: string;
  recipient?: any;
  type?: string;
}
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit {
  chat: Chat[] = [];
  private chatSub: Subscription;
  private _unsubscribeAll: Subject<void> = new Subject<void>();
  readAt;
  rForm: FormGroup;
  studentId: string;
  mentorId: string;
  firstLoad: boolean = true;
  userType;
  userId;

  @ViewChild('content') content : ElementRef;

  constructor(
    private chatService : ChatService,
    private changeDetector: ChangeDetectorRef,
    private _formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private authService : AuthService,
    public userService: UserService,
    private webSocketService: WebSocketService
  ) { }

  ngOnInit() {
    this.userType = this.authService.getType();
    this.studentId = this.route.snapshot.params['id'];
    if (this.userType == 'student') {
      this.mentorId = this.authService.getMentorOfChild();
    }
    if (this.userType == 'mentor' || this.userType == 'coordinator') {
      this.mentorId = this.authService.getCurrentID();
    }
    this.userId = this.authService.getCurrentID();
    this.chatService.getChatList(this.mentorId, this.studentId);
    this.chatSub = this.chatService.getChatUpdateListener().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((chat: Chat[]) => {
      this.chat = chat;
      let time = this.firstLoad ? 1000 : 0;
      this.firstLoad = false;
      console.log(this.chat)
      setTimeout(() => {
        this.scrollToBottom();
      }, time);
    });
    this.chatService.resetChatBadgeCount();

    this.rForm = this._formBuilder.group({
      chatMessage  : ['']
    });
    this.webSocketService.listen('chat').pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((data) => this.handleChat(data));
}

  updateChat(item) {
    if (item?.readAt === null) {
      this.chatService.updateChat({
        chatId :  item._id,
        status : "read",
      }).pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe((res) => {
      });
    }
  }

  sortBy(prop: string, direction: string) {
    if (direction == 'DESC')  {
      return this.chat.sort((a, b) => a[prop] < b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
    } else {
      return this.chat.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
    }
  }

  deleteChat(item) {
    this.chatService.updateChat({
      chatId :  item._id,
      status : "delete",
    }).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((res) => {
      // Remove deleted item from list
      this.chat.splice(this.chat.findIndex((chat) => {
        return chat['_id'] === item._id;
      }), 1);
      this.changeDetector.detectChanges();
    });
  }

  submitChat(formDirective: FormGroupDirective) {
    console.log("----------------------------------")
    if (this.rForm.invalid) {
      return
    }
    console.log("###################################")
    this.chatService.createChat({
      message : this.rForm.value.chatMessage,
      recipient : this.userType == 'student' ? this.mentorId : this.studentId,
      sender: this.userType == 'student' ? this.studentId : this.mentorId
    })
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe(response => {
      console.log(response)
      this.rForm.reset();
    });
  }

  handleChat(message) {
    this.chatService.addChatSocketCallback(message);
  }

  scrollToBottom() {
    try {
        this.content.nativeElement.scrollTop = this.content.nativeElement.scrollHeight;
    } catch (err) {}
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
