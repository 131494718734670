import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../providers/user.service';
import { takeUntil } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import { MatTableDataSource,} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { StudentViewComponent } from '../../student/student-view/student-view.component';
import { MentorViewComponent } from '../mentor-view/mentor-view.component';
import { MentorAddStudentComponent } from '../../mentor/mentor-add-student/mentor-add-student.component';

export interface Mentor {}
export interface Student {}

@Component({
  selector: 'app-mentor-matching',
  templateUrl: './mentor-matching.component.html',
  styleUrls: ['./mentor-matching.component.scss'],
})
export class MentorMatchingComponent implements OnInit {
  displayedColumns: string[] = ['preview','firstname', 'lastname', 'school', "monday", "tuesday" , "wednesday" , "thursday", "friday", "saturday", "sunday", 'match-button'];
  displayedMentorColumns: string[] = ['preview','firstname', 'lastname', 'coordinator', "monday", "tuesday" , "wednesday" , "thursday", "friday", "saturday", "sunday"];
  dataSource:any;
  dataSourceMentor:any;
  mentorAvailableDays = [];

	@ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  
  isAdmin = false;
  filterValues = {
    search: '',
    availability: false,
    mentor: false
  };
  id;
  student: Student[] = [];
  mentor: Mentor[] = [];
  private studentSub: Subscription;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private userService : UserService,
    private router: Router,
    private route:ActivatedRoute,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {    
    this.id = this.route.snapshot.params['id'];
    this.userService.getUserbyId(this.id).subscribe(res => {
      this.mentor = res;
      this.dataSourceMentor = new MatTableDataSource<Mentor>([res]);
      // Read available days
      if (this.mentor['availability']) {
        let mentorAvailability = JSON.parse(this.mentor['availability']);
        if (!(mentorAvailability.monday == null || mentorAvailability.monday == '')) { this.mentorAvailableDays.push('monday'); }
        if (!(mentorAvailability.tuesday == null || mentorAvailability.tuesday == '')) { this.mentorAvailableDays.push('tuesday'); }
        if (!(mentorAvailability.wednesday == null || mentorAvailability.wednesday == '')) { this.mentorAvailableDays.push('wednesday'); }
        if (!(mentorAvailability.thursday == null || mentorAvailability.thursday == '')) { this.mentorAvailableDays.push('thursday'); }
        if (!(mentorAvailability.friday == null || mentorAvailability.friday == '')){ this.mentorAvailableDays.push('friday'); }
        if (!(mentorAvailability.saturday == null || mentorAvailability.saturday == '')) { this.mentorAvailableDays.push('saturday'); }
        if (!(mentorAvailability.sunday == null || mentorAvailability.sunday == '')) { this.mentorAvailableDays.push('sunday'); }
      }
    });


    this.userService.getStudentsList(true);
    this.studentSub = this.userService.getStudentsUpdateListener().pipe(
        takeUntil(this._unsubscribeAll)
    ).subscribe((student: Student[]) => {
        this.student = student;
        this.dataSource = new MatTableDataSource<Student>(this.student);
        // pagination and sort table
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (value, sortHeaderId) => value[sortHeaderId];
        this.dataSource.paginator = this.paginator;
        // Filter in nested array as well
        this.dataSource.filterPredicate = (data: any, filter) => {
          let availabilityFound = false;
          // Check if children without mentor is set
          if (filter.availability) {
            if (data['availability'] == undefined || data['availability'] == '' || typeof this.isJson(data['availability']) !== 'object') {
              availabilityFound = false;
            } else {
              let studentAvailability = JSON.parse(data['availability']);
              if (!(studentAvailability.monday == null || studentAvailability.monday == '') && this.mentorAvailableDays.includes('monday')) { availabilityFound = true; }
              if (!(studentAvailability.tuesday == null || studentAvailability.tuesday == '') && this.mentorAvailableDays.includes('tuesday')) { availabilityFound = true; }
              if (!(studentAvailability.wednesday == null || studentAvailability.wednesday == '') && this.mentorAvailableDays.includes('wednesday')) { availabilityFound = true; }
              if (!(studentAvailability.thursday == null || studentAvailability.thursday == '') && this.mentorAvailableDays.includes('thursday')) { availabilityFound = true; }
              if (!(studentAvailability.friday == null || studentAvailability.friday == '') && this.mentorAvailableDays.includes('friday')) { availabilityFound = true; }
              if (!(studentAvailability.saturday == null || studentAvailability.saturday == '') && this.mentorAvailableDays.includes('saturday')) { availabilityFound = true; }
              if (!(studentAvailability.sunday == null || studentAvailability.sunday == '') && this.mentorAvailableDays.includes('sunday')) { availabilityFound = true; }
            }
          }
          // Check if children without mentor is set
          let withoutMentorFound = false;
          if (filter.mentor) {
            if (data['mentor'] == undefined) {
              withoutMentorFound = true;
            }
          }
          // Find position for search word
          let positionFound = JSON.stringify(data).toLowerCase().indexOf(filter.search) != -1;
          if (filter.availability || filter.mentor) {
              // Both active
              if (filter.availability && filter.mentor) {
                  return withoutMentorFound && availabilityFound && positionFound;
              } // Mentor selected
              else if (!filter.availability && filter.mentor) {
                return withoutMentorFound && positionFound;
              } // Availability selected
              else {
                return availabilityFound && positionFound;
              }
          } else {
              return positionFound;
          }
        };
    });
  }

  isJson(str) {
      try {
          return JSON.parse(str);
      } catch (e) {
          return false;
      }
  }

  extractNameFromJson(obj, day){
    if (obj != undefined && obj != "") {
      obj = JSON.parse(obj);
      switch(day) { 
        case 'monday': { 
          return obj.monday;
        } 
        case 'tuesday': { 
          return obj.tuesday;
        }
        case 'wednesday': { 
          return obj.wednesday;
        } 
        case 'thursday': { 
          return obj.thursday;
        } 
        case 'friday': { 
          return obj.friday;
        } 
        case 'saturday': { 
          return obj.saturday;
        } 
        case 'sunday': { 
          return obj.sunday;
        } 
        default: { 
           return;
        } 
     }
    }  else {
      return '';
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filterValues['search'] = filterValue.trim().toLowerCase()
    this.dataSource.filter = this.filterValues;

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  mentorSelectionFilter(el) {
    this.filterValues['mentor'] = el.checked
    this.dataSource.filter = this.filterValues;
  }

  availabilityMatchSelectionFilter(el) {
    this.filterValues['availability'] = el.checked
    this.dataSource.filter = this.filterValues;
  }

  openStudentProfil(id): void {
    const dialogRef = this.dialog.open(StudentViewComponent, {
      width: '100%',
      autoFocus: false,
      data: { id: id }
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(res => {
    });
  }

  openMentorProfil(id): void {
    const dialogRef = this.dialog.open(MentorViewComponent, {
      width: '100%',
      autoFocus: false,
      data: { id: this.id }
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(res => {
    });
  }

  openMatch(element): void {
    const dialogRef = this.dialog.open(MentorAddStudentComponent, {
      width: '1000px',
      autoFocus: false,
      data: { 
        mentor: this.mentor,
        student: element
      }
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(res => {
    });
  }
}