<div>
  <form [formGroup]="searchForm" (ngSubmit)="onSearch()">
    <mat-form-field appearance="fill" style="width: 400px">
      <mat-icon matPrefix>search</mat-icon>
      <mat-label>Welches Buch suchst Du?</mat-label>

      <input matInput formControlName="searchText" />
      <mat-error
        *ngIf="isSubmitted && searchForm.controls.searchText.errors?.required"
      >
        Bitte gib einen Suchtext ein
      </mat-error>
    </mat-form-field>
  </form>
</div>
