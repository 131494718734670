import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders } from "@angular/common/http";
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Subject,BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { formatDate } from '@angular/common';

export interface Session {
    file?: File;
    student: string[];
    mentor: string;
    filename?: string;
    sessionReadTime?: number;
    sessionGoalTime?: number;
    quizResult?: string;
}

@Injectable({
  providedIn: 'root'
})

export class SessionService {
  private restServerUrl;
  session: Session[] = [];
  sessionUpdated = new Subject<Session[]>();
  //onSessionChanged: BehaviorSubject<any>;
  recordingStatusUpdated: Subject<string> = new Subject<string>();
  sessionReadTime;
  sessionGoalTime;
  readBooks:any = [];
  readBooksIsbn:any = [];
  latestSession;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
      private http: HttpClient
  ) {
      //this.onSessionChanged = new BehaviorSubject([]);
      let protocol = environment.ssl ? 'https://' : 'http://';
      this.restServerUrl = protocol + environment.restServer + ":" + environment.restServerPort;
  }

  getSessionUpdateListener() {
      return this.sessionUpdated.asObservable();
  } 

  getRecordingStatusUpdateListener() {
		return this.recordingStatusUpdated.asObservable();
	}

  setRecordingStatus(status) {
    this.recordingStatusUpdated.next(status);
  }

  /**
   * list session
  */
  getListOfSession(id?:string) {
    var param = id ? '/'+ id : '';
    this.http.get<Session[]>(this.restServerUrl + "/api/session/list" + param)
     	.pipe(map(data => data), takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        console.log(data)
        this.session = data;
        this.sessionUpdated.next(this.session);
    });
  }

  /**
   * get session analytics
    */
  getAnalyticsOfSession(id?:string) {
    var param = id ? '/'+ id : '';
    return this.http.get<{
      totalReadingTime,
      totalPagesCount,
      totalBookCount,
      totalReadingTimeSolo,
      totalPagesCountSolo,
      quizSolo,
      quizSoloRight,
      quizTotal,
      quizTotalRight
    }>(this.restServerUrl + "/api/session/analytics" + param);
  }

  setReadBook(bookId, startPage, activePage) {
    var lastBook = [...this.readBooks].pop();
    if (lastBook == undefined || lastBook?.book != bookId) {
      this.readBooks.push({
        book: bookId,
        startPage: startPage,
        activePage: activePage
      });
    }
  }

  setReadBookIsbn(isbn, title, part, displayPage, currentPage, startTime) {
    var lastBook = [...this.readBooksIsbn].pop();
    if (lastBook == undefined || lastBook?.book != isbn, part) {
      this.readBooksIsbn.push({
        book: isbn,
        title: title,
        part: part,
        displayPage: displayPage,
        page: currentPage,
        startTime: startTime,
        endTime: formatDate(new Date(), 'yyyyMMddhhmmss', 'en')
      });
    }
    console.log(this.readBooksIsbn);
  }

  changeActivePageInReadBook(bookId, activePage) {
    var lastBook = [...this.readBooks].pop();
    // Change active page of current book
    if (lastBook != undefined) {
      if (lastBook.book == bookId) {
        lastBook.activePage = activePage;
      }
    }
  }

  getReadBooks() {
    return this.readBooks;
  }

  getReadBooksIsbn() {
    return this.readBooksIsbn;
  }

  resetReadBooks() {
    this.readBooks.length = 0;
  }

  resetReadBooksIsbn() {
    this.readBooksIsbn.length = 0;
  }

  /**
   * get audio file for audio player
  */
  getAudio(filename: string) {
    return this.restServerUrl + "/api/session/download/" + filename;
  }

  /**
   * set delete flag in session
  */
  deleteSession(data) {
    this.http.put(
      this.restServerUrl + '/api/session/' + data._id,
      JSON.stringify(data),
      { headers: new HttpHeaders().set('Content-Type', 'application/json') }).pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe(res => {
				// Update local list
				this.session.splice(this.session.findIndex((item) => {
						return item['_id'] === data._id;
        }), 1);
        this.sessionUpdated.next(this.session);
			}, (error) => {
				console.error("Error deleting session: ", error);
			});
  }

  updateSession(data)  {
		return this.http.put(this.restServerUrl + '/api/session/' + data._id,
			JSON.stringify(data), { headers: new HttpHeaders().set('Content-Type', 'application/json') })
				.pipe(map(res => {
					return <Session>res;
				}));
  }
  
  getSessionbyId(id) {
		return this.http.get<Session[]>(this.restServerUrl + '/api/session/' + id)
		.pipe(map(data => data));
	}

  setSessionTimer(time, goal){
    this.sessionReadTime = time;
    this.sessionGoalTime = goal;
  }

  getSessionReadTime() {
    return this.sessionReadTime;
  }

  /**
   * save session and audio file on server
  */
  createSession(element) {
    if (element.mentorDevice == undefined) {
      element.mentorDevice = {};
    }
    if (element.studentDevice == undefined) {
      element.studentDevice = {};
    }
    const formData = new FormData();
          formData.append('originalname', element.filename);
          if (element.readingMode != 'Solo') {
            formData.append('mentorId', element.mentor);
            formData.append('mentorDevice', JSON.stringify(element.mentorDevice));
          }
          formData.append('studentId', element.student);
          formData.append('studentDevice', JSON.stringify(element.studentDevice));
          formData.append('sessionReadTime', this.sessionReadTime);
          formData.append('sessionGoalTime', this.sessionGoalTime);
          formData.append('readingMode', element.readingMode);
          if (element.chunkHash) {
            formData.append('chunkHash', element.chunkHash);
          }
          if (environment.bookstoreModule) {
            formData.append('readBooksIsbn', JSON.stringify(this.readBooksIsbn));
          } else {
            formData.append('readBooks', JSON.stringify(this.readBooks));
          }
          formData.append('file', element.file, element.filename);

    return this.http.post<any>(this.restServerUrl + "/api/session/create", formData, {
      reportProgress: true,
      observe: 'events'
      })
      .pipe(map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              const progress = Math.round(100 * event.loaded / event.total);
              return { status: 'progress', message: progress };

            case HttpEventType.Response:
              // Slice all books except last one for new upload
              if (this.readBooks.lengt < 1) {
                this.readBooks.splice(this.readBooks.length - 1,1);
              }
              // Set sessionId for later usage (quiz)
              if(event.body._id) {
                this.latestSession = event.body._id;
              }
              return { status: 'finished', data: event.body };
            default:
              return { status: 'unhandled', type: event.type };
          }
      })
    );
  }

  uploadSessionChunks(formData) {
    this.http.post<any>(this.restServerUrl + "/api/session/uploadChunks", formData, {
      // reportProgress: true,
      // observe: 'events'
      })
      .pipe(map(data => data), takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        console.log(data)
      });
  }

  setLatestSession(session) {
    this.latestSession = session;
  }

  getLatestSession() {
    return this.latestSession;
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}