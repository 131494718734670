import { Component, OnInit, ViewChild, HostListener, Inject, Optional } from '@angular/core';
import { EbooksEpubService } from "../../providers/epub.service";
import { BookService } from "../../providers/book.service";
import { Subscription, Subject } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource,} from '@angular/material/table';
import { ConfirmDialogComponent } from '../../helpers/confirm-dialog/confirm-dialog.component';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';
import { UserFacade } from '../shared/store/user/user.facade';
import { UserService } from "../../providers/user.service";

export interface DialogData {
  name: string;
  _id: string;
  url: string;
}

export interface Book {
  _id?: string;
  name: string;
  file?: File;
  type?: string;
  size?: string;
  originalname?: string;
  path?: string;
}

@Component({
  selector: 'app-book-purchases-list',
  templateUrl: './book-purchases-list.component.html',
  styleUrls: ['./book-purchases-list.component.scss'],
})
export class BookPurchasesListComponent implements OnInit {
  CLUB_ID = environment.clubId;
  displayedColumns: string[] = ['name', 'buttons'];
  dataSource:any;
  dialogConfig: any;
  books: any = [];
  private bookSub: Subscription;
  userType;
  userId;
  isAdmin = false;
  isMentor = false;
  quizIsVisible = false;
  id;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  screenHeight: number;
  screenWidth: number;
  pageSize = 4;
  disableBookSelection: boolean = false;
  filterValues = {
    search: '',
    recommendation: ''
  };
  booksLoaded: boolean = false;
  BOOKSTORE_ACTIVE = environment.bookstoreModule;
  isSingleReading: boolean = false;

	@ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  booksObs: Observable<any>;

  constructor(
   public ebookEpubService: EbooksEpubService,
   public bookService: BookService,
   private readonly userFacade: UserFacade,
   private authService : AuthService,
   private router: Router,
   public dialog: MatDialog,
   public userService: UserService,
   @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: any
  ) { 
    // Disable hide book button for child in tandem mode
    if (injectedData) {
      if (typeof(injectedData['canChooseBook'])!=="undefined") {
        this.disableBookSelection = !injectedData.canChooseBook
      }
      if (typeof(injectedData['userId'])!=="undefined") {
        this.userId = injectedData.userId
      } else {
        this.userId = this.authService.getCurrentID()
      }
    }
    this.onResize();
  }

  ngOnInit() {
    this.userType = this.authService.getType();
    if (this.userType == 'admin') {
      this.isAdmin = true;
    } else if (this.userType == 'mentor' || this.userType == 'coordinator') {
      this.isMentor = true;
    }

    if (this.userType == 'admin' && !environment.production) {
      this.quizIsVisible = true;
    }

    if (this.router.url.includes('/single-reading')) {
      this.isSingleReading = true;
    } else {
      this.isSingleReading = false;
    }

    //this.books =this.ebookEpubService.getBooksList();

    // this.userFacade.libById(this.userId, this.CLUB_ID)
    this.bookSub =  this.userService.libById(this.userId).pipe(
        takeUntil(this._unsubscribeAll)
    ).subscribe((data) => {
        console.log(data)
        if (data != undefined) {
          this.books = data.books;
          this.booksLoaded = true;
          this.dataSource = new MatTableDataSource<Book>(this.books);
          // pagination and sort table
          setTimeout(() => {
            this.dataSource.sort = this.sort;
            this.dataSource.sortingDataAccessor = (value, sortHeaderId) => value[sortHeaderId];
            this.dataSource.paginator = this.paginator;
          }, 0);

          this.booksObs = this.dataSource.connect();
          // Filter in nested array as well
          this.dataSource.filterPredicate = (data: any, filter) => {
            // Check if students array is empty
            let withoutStudentsFound = false;
            if (filter.recommendation) {
              if (data['recommendation'] == filter.recommendation) {
                withoutStudentsFound = true;
              }
            }
            // Find position for search word
            let positionFound = JSON.stringify(data).toLowerCase().indexOf(filter.search) != -1;
            if (filter.recommendation) {
                return withoutStudentsFound && positionFound;
            } else {
                return positionFound;
            }
          };
        }
    });

    // this.userFacade.libById(this.userId, this.CLUB_ID)
    // this.bookSub = this.userFacade.userlib$.pipe(
    //     takeUntil(this._unsubscribeAll)
    // ).subscribe((data) => {
    //     console.log(data)
    //     if (data != undefined) {
    //       this.books = data.books;
    //       this.booksLoaded = true;
    //       this.dataSource = new MatTableDataSource<Book>(this.books);
    //       // pagination and sort table
    //       setTimeout(() => {
    //         this.dataSource.sort = this.sort;
    //         this.dataSource.sortingDataAccessor = (value, sortHeaderId) => value[sortHeaderId];
    //         this.dataSource.paginator = this.paginator;
    //       }, 0);

    //       this.booksObs = this.dataSource.connect();
    //       // Filter in nested array as well
    //       this.dataSource.filterPredicate = (data: any, filter) => {
    //         // Check if students array is empty
    //         let withoutStudentsFound = false;
    //         if (filter.recommendation) {
    //           if (data['recommendation'] == filter.recommendation) {
    //             withoutStudentsFound = true;
    //           }
    //         }
    //         // Find position for search word
    //         let positionFound = JSON.stringify(data).toLowerCase().indexOf(filter.search) != -1;
    //         if (filter.recommendation) {
    //             return withoutStudentsFound && positionFound;
    //         } else {
    //             return positionFound;
    //         }
    //       };
    //     }
    // });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;

    if (this.screenWidth >= 999) {
      this.pageSize = 4;
    } 

    if (this.screenWidth <= 999) {
      this.pageSize = 2;
    } 
     if (this.screenWidth <= 767) {
      this.pageSize = 2;
    }
 }

  deleteBook(bookId) {
    this.dialogConfig = new MatDialogConfig();
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = false;
    //this.dialogConfig.width = '400px';
    this.dialogConfig.data = {
      title: 'Achtung',
      note: 'Soll das Buch wirklich gelöscht werden?',
      accept: true,
      acceptIcon: '',
      acceptText: 'Ja',
      decline: true,
      declineIcon: '',
      declineText: 'Nein'
    };
    this.dialog.closeAll();
    let dialogRef = this.dialog.open(ConfirmDialogComponent, this.dialogConfig);
    dialogRef.afterClosed().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(result => {
      if (result == 'ok') {
        this.ebookEpubService.deleteBook(bookId);
      }
    });
  }

  getBookImageUrl(isbn) {
    return this.bookService.getBookimage(isbn);
  }

  goToCreateQuestion(bookId) {
    this.router.navigate(["/create-question/" +bookId]);
  }

  goToListQuestion(bookId) {
    this.router.navigate(["/list-question/" +bookId]);
  }

  recommendationSelectionFilter(event) {
    const filterValue = event.value;
    this.filterValues['recommendation'] = filterValue.trim();
    this.dataSource.filter = this.filterValues;
  }

  applyFilter(event: Event) {
    console.log(this.filterValues)
    const filterValue = (event.target as HTMLInputElement).value;
    this.filterValues['search'] = filterValue.trim().toLowerCase()
    this.dataSource.filter = this.filterValues;

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnDestroy() {
    if (this.dataSource) { 
      this.dataSource.disconnect(); 
    }
    this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
  }
}
