import { Component, OnDestroy, OnInit, Inject, Optional } from '@angular/core';
import { Subject, Subscription, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BookList, BookWithIsbn } from '../shared/models/book-startpage';
import { BookFacade } from '../shared/store/book/book.facade';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { BookDonation, BookService } from '../../providers/book.service';
import { formatCurrency } from "@angular/common";
import { UserService } from "../../providers/user.service";
import { Router } from '@angular/router';

@Component({
  selector: 'app-book-horizontal-list-page',
  templateUrl: './book-library-overview.component.html',
  styleUrls: ['./book-library-overview.component.scss'],
})


export class BookLibraryOverviewPageComponent implements OnInit, OnDestroy {
  CLUB_ID = environment.clubId;
  isMentor: boolean = false;
  roomId;
  userId;
  userType;
  books: BookList;
  booksSubscription!: Subscription;
  credit: string = '0,00 €';
  userToken: any = "0";
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  IsOverlay: boolean = false;
  mentorCredit: string = '0';

  constructor(
    private readonly bookFacade: BookFacade,
    private authService : AuthService,
    private bookService : BookService,
    public userService: UserService,
    public router: Router,
    ) {}

  ngOnInit(): void {
    this.userId = this.authService.getCurrentID();
    this.userType = this.authService.getType();
    // get token from user
    this.userToken = this.authService.getUserToken();
    if (this.userType == 'mentor' || this.userType == 'coordinator') {
      this.isMentor = true;
      var activeStudent = this.authService.getActiveStudent();
      this.roomId = activeStudent._id;
      this.mentorCredit = activeStudent["token"].filter(token => token.status == "active").length;
      // this.mentorCredit = formatCurrency(activeStudent["credit"], "de-DE", "€");
    } else {
      this.roomId  = this.authService.getCurrentID();
    }
    console.log(this.roomId)

    let userAgeI = sessionStorage.getItem("userAge");
    let userAge:number;
    if (userAgeI == null) {
      userAge = 10
    } else {
      userAge = Number(userAgeI);
    } 

    if (this.router.url.includes('/room')) {
      this.IsOverlay = true;
    }

    // this.booksSubscription = this.bookFacade.startpageBookList$.subscribe(
    //   (books) => {
    //     this.books = books;
    //   }
    // ); 
    // this.bookFacade.fetchByClub(this.CLUB_ID, userAge);

    // this.bookService.fetchByClub(this.CLUB_ID, userAge).pipe(
    //   takeUntil(this._unsubscribeAll)
    //     ).subscribe((res) => {
    //       this.books = res;
    // })

    // Get books and bookDonations together
    forkJoin([
        this.bookService.fetchByClub(this.CLUB_ID, userAge),
        this.bookService.fetchBookDonationList()
      ]).pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe(res => {
        console.log(res);
        let bookDonations: BookDonation[] = res[1].filter(el => el.redeemed === true);
        if (bookDonations.length > 0) {
          this.books = this.checkForDonations(res[0], bookDonations);
        } else {
          this.books = res[0];
        } 
        console.log(this.books)
    });

    // Get updated user credits
    this.userService.fetchById(this.roomId).pipe(
      takeUntil(this._unsubscribeAll)
        ).subscribe((res) => {
          sessionStorage.setItem("userWallet", formatCurrency(res.credit, "de-DE", "€"));
          // Update credit after purchase cause of subscription
          this.authService.getUserCredit();
      })
    // Check user credit
    this.authService.currentCredit.pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(currentCredit => {
      this.credit = currentCredit;
      // Refresh changes
      //this.changeDetectorRef.detectChanges();
    });

    // Get updated user tokens
    this.userService.getUserbyId(this.roomId).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(res => {
        let userToken = res["token"].filter(token => token.status == "active").length;
        sessionStorage.setItem("userToken", userToken);
    });

    // Check user token
    this.authService.currentUserToken.pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(currentUserToken => {
      this.userToken = currentUserToken;
    });
  }

  checkForDonations(books, bookDonations) {
    // map through each object key
    return Object.fromEntries(Object.entries(books).map(([key]) => [key, books[key].map((obj) => {
      // check if isbn is in bookDonations array
      const foundValue = bookDonations.find((value) => BigInt(value.isbn) == BigInt(obj.isbn));
      // check if prePurchases exist
      return { ...obj, donation_available: foundValue?.prePurchased?.length > 0 ? true : false };
    })]));
  }

  ngOnDestroy(): void {
    // this.booksSubscription.unsubscribe();
    this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
  }

  get booksKeys(): string[] {
    if (this.books) {
      return Object.keys(this.books);
    }
    return [];
  }

  booksByKey(key: string): BookWithIsbn[] {
      // @ts-ignore
    return this.books[key];
  }
}
