import { Component, OnInit } from '@angular/core';
import { BookListComponent } from '../books/book-list/book-list.component';
import { MatDialog } from '@angular/material/dialog';
import { EbooksEpubService } from "../providers/epub.service";
import { AuthService } from '../auth/auth.service';
import { SessionService } from '../providers/session.service';
import { UserService } from "../providers/user.service";
//import moment from 'moment';
import { takeUntil, map } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { Insomnia } from '@ionic-native/insomnia/ngx';
import { ReadingOverlayComponent } from '../reading-overlay/reading-overlay.component';
import { QuizComponent } from '../quiz/quiz.component';
import { QuizService } from '../providers/quiz.service';
// Bookstore
import { BookPurchasesListComponent } from '../bookstore/book-purchases-list/book-purchases-list.component';
import { BookService } from '../providers/book.service';

@Component({
  selector: 'app-single-reading',
  templateUrl: './single-reading.component.html',
  styleUrls: ['./single-reading.component.scss'],
})
export class SingleReadingComponent implements OnInit {
  IS_APP = environment.isApp;
  QUIZ_ACTIVE = environment.quizModule;
  BOOKSTORE_ACTIVE = environment.bookstoreModule;
  book;
  bookActivePage = 1;
  bookStartPage = 1;
  bookActivePart = 0;
  readingModeIsPDF: boolean = false;
  readingModeIsEpub: boolean = false;
  userId;
  isRecording: boolean = false;
  studentBooks: any = [];
  studentIsbnBooks: any = [];
  quizIsHidden: boolean = true;
  private fetchSubscription!: Subscription;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  silentReading: boolean = false
  quizPages: any = [];
  quizAvailable: boolean = false;

  constructor(
    public dialog: MatDialog,
    public ebookEpubService: EbooksEpubService,
    private bookService: BookService,
    private authService : AuthService,
    private sessionService : SessionService,
    public userService: UserService,
    private insomnia: Insomnia,
    private quizService: QuizService
  ) { }

  ngOnInit() {
    this.userId = this.authService.getCurrentID();
    this.userService.getStudentBooks(this.userId).pipe(
          takeUntil(this._unsubscribeAll)
        ).subscribe(data => {
          console.log(data)
          if (this.BOOKSTORE_ACTIVE) {
            if (data.length > 0) {
              this.studentIsbnBooks = data;
              let isbn = this.studentIsbnBooks[0].book;
              this.bookService.fetchByIsbn(BigInt(isbn))
                .pipe(map(data => data), takeUntil(this._unsubscribeAll))
                .subscribe(data => {
                  // Modify callback data from bookstore for reuse with old logic
                  data['isbn'] = isbn;
                  data['bookStructure'] = data['structure'];
                  data['bookActivePage'] = this.studentIsbnBooks[0].activePage;
                  data['bookActivePart'] = this.studentIsbnBooks[0].part;
                  this.book = data;
                  // TODO QUIZ
                  this.startReadingMode();
              });
            }
          } else {
            if (data.length > 0) {
              this.studentBooks = data;
              // Open last read book
              this.ebookEpubService.getBookById(this.studentBooks[0].book).pipe(
                takeUntil(this._unsubscribeAll)
              ).subscribe(data => {
                this.book = data;
                if (data['quizPages']) {
                  this.quizPages = data['quizPages'];
                  delete data['quizPages'];
                }
                this.startReadingMode();
              });
            }
          }
    });
    // Prevent screen from sleeping
    //if (this.IS_APP) {
      this.insomnia.keepAwake();
    //}
  }

  checkForQuiz() {
    if (this.QUIZ_ACTIVE) {
      if (this.quizPages.includes(this.bookActivePage)|| this.quizPages.includes(this.bookStartPage) ) {
        this.quizAvailable = true;
      } else {
        this.quizAvailable = false;
      }
    }
  }

  /**
   * open book list modal
   */
  openDialog(): void {
    let dialogRef;
    if (this.BOOKSTORE_ACTIVE) {
      dialogRef = this.dialog.open(BookPurchasesListComponent, {
        width: '100%',
        autoFocus: false,
        panelClass: 'bookselection-overlay-panel',
        data: { canChooseBook: true }
      });    
    } else {
      dialogRef = this.dialog.open(BookListComponent, {
        width: '100%',
        height: '80%',
        autoFocus: false,
        data: { canChooseBook: true }
      });
    }
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(book => {
      // Set current active page before switching to new book
      this.setActivePageOnBook(book);
      this.quizIsHidden = false;
    });
  }

  /**
   * open quiz selection modal
   */
  openQuizOverlay(): void {
    const dialogRef = this.dialog.open(ReadingOverlayComponent, {
      width: '100%',
      autoFocus: false,
      data: { 
        bookId: this.book._id,
        bookName: this.book.name,
        startPage: this.bookStartPage,
        activePage: this.bookActivePage
      }
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(res => {
      switch (res) {
        case 'yes':
          this.startQuiz();
          this.quizIsHidden = true;
          this.bookStartPage = this.bookActivePage;
          this.quizAvailable = false;
          break;
  
        case 'later':
          this.quizIsHidden = true;
          this.bookStartPage = this.bookActivePage;
          this.silentReading = true;
          this.checkForQuiz();
          break;
  
        case 'no':
          this.quizIsHidden = true;
          this.bookStartPage = this.bookActivePage;
          this.quizAvailable = false;
          break;

        default:
          break;
      }
      // if (res) {
      //   this.bookStartPage = res;
      // }
      // this.quizIsHidden = true;
      // console.log(res)
      // Set current active page before switching to new book
      // this.setActivePageOnBook(book);
    });
  }

  startQuiz(): void {
    let startPage = this.bookStartPage;
    let activePage = this.bookActivePage;

    if (startPage > activePage) {
      startPage = this.bookActivePage;
      activePage = this.bookStartPage;
    }

    const dialogRef = this.dialog.open(QuizComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'quiz-overlay-panel',
      data: {
        bookId: this.book._id,
        bookName: this.book.name,
        startPage: startPage,
        activePage: activePage
      }
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(res => {
      this.quizIsHidden = true;
      this.silentReading = false;
    });
  }

  startReadingMode() {
      // Check for active page to continue reading
      if (this.BOOKSTORE_ACTIVE) {
        this.bookActivePage = this.ebookEpubService.getActivePageFromStoredBook(this.book.isbn, this.studentIsbnBooks);
      } else {
        this.bookActivePage = this.ebookEpubService.getActivePageFromStoredBook(this.book._id, this.studentBooks);
        this.setReadBook();
      }
      this.setReaderByType();
  }

  setReadBook() {
    if (this.isRecording) {
      if (this.BOOKSTORE_ACTIVE) {
      } else {
        this.bookStartPage = this.bookActivePage;
        this.sessionService.setReadBook(this.book._id, this.bookStartPage, this.bookActivePage);
      }
    }
  }

  setReaderByType() {
    if (this.book.format == 'epub') {
      this.readingModeIsPDF = false;
      this.readingModeIsEpub = true; 
    } else {
      this.readingModeIsPDF = true;
      this.readingModeIsEpub = false; 
    }
  }

  setActivePageOnBook(book?) {
      if (this.book != undefined) {
        // Find current book
        if (this.BOOKSTORE_ACTIVE) {
          var foundBook = this.studentIsbnBooks[this.studentIsbnBooks.findIndex((item) => {
            return item.book === this.book.isbn;
          })];
        } else {
          var foundBook = this.studentBooks[this.studentBooks.findIndex((item) => {
            return item.book === this.book._id;
          })];
        }
        // Change active page of current book
        if (foundBook) {
          foundBook.activePage = this.bookActivePage;
          foundBook.lastRead = new Date().toISOString();
        } else {
          if (this.BOOKSTORE_ACTIVE) {
            this.studentIsbnBooks.push({
              book: this.book.isbn,
              part: this.bookActivePart,
              activePage: this.bookActivePage,
              lastRead: new Date().toISOString()
            });
          } else {
            this.studentBooks.push({
              book: this.book._id,
              activePage: this.bookActivePage,
              lastRead: new Date().toISOString()
            });
          }
        }
      }
      // Set new book
      if (book) {
        if (this.BOOKSTORE_ACTIVE) {
          // Add data on book change to set active page
          let bookClone =  JSON.parse(JSON.stringify(book));
          bookClone['bookActivePage'] = this.ebookEpubService.getActivePageFromStoredBook(bookClone.isbn, this.studentIsbnBooks);;
          this.book = bookClone;
        } else {
          this.book = book;
        }
        this.startReadingMode();
      }
  }

  updateReadBooksOnStudent() {
    if (this.book) {
      this.setActivePageOnBook();
      // Update student books in db
      if (this.BOOKSTORE_ACTIVE) {
        this.userService.updateUser({
          _id : this.userId,
          studentIsbnBooks : this.studentIsbnBooks
        }).pipe(
          takeUntil(this._unsubscribeAll)
        ).subscribe(res => {
          console.log(res)
        });
      } else {
        this.userService.updateUser({
          _id : this.userId,
          studentBooks : this.studentBooks
        }).pipe(
          takeUntil(this._unsubscribeAll)
        ).subscribe(res => {
          console.log(res)
        });
      }
    }
  }

  getActivePage(page) {
    this.bookActivePage = page;
    if (this.isRecording) {
      if (this.BOOKSTORE_ACTIVE) {

      } else {
        // Update page in current book
        this.sessionService.changeActivePageInReadBook(this.book._id, this.bookActivePage);
      }
    }
    //silent reading + quiz
    if (this.silentReading == true) {
      this.checkForQuiz() 
    }
  }

  getActivePart(part) {
    this.bookActivePart = part;
  }

  getIsRecording(status) {
    this.isRecording = status;
    this.setReadBook();
    if (status == false) {
      this.checkForQuiz();
      setTimeout(() => {
        if (this.quizAvailable === true) {
          this.openQuizOverlay();
          // this.bookStartPage = this.bookActivePage;
        }  
      }, 0);
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.updateReadBooksOnStudent();
    // Unsubscribe from all subscriptions
    setTimeout(() => {
      //console.log('reading unsubscribe -------------')
      this._unsubscribeAll.next(true);
      this._unsubscribeAll.complete();
    }, 500);
    // Allow sleep mode again
    //if (this.IS_APP) {
      this.insomnia.allowSleepAgain();
    //}
  }
  
}
