<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="reading-button-wrap">
        <h1>Quiz Ergebnis</h1>
        <h1>{{result}}%</h1>

        <!-- <h1>Ergebnis</h1> -->
        <mat-list class="quiz-result-list" role="list" *ngFor="let quiz of quiz; let i = index" [ngClass]="checkAnswer(i, quiz.selected)">
          <mat-list-item class="quiz-summary-list"> 
            <div class="quiz-summary">
              <p>Frage: {{quiz.question}}</p>
              <div *ngIf="quiz.answers.length > 1">
                <mat-radio-group [(ngModel)]="quiz.selected" [disabled]="true">
                  <!-- <mat-radio-button *ngFor="let o of quiz.answers" name="{{o._id}}" value="{{o._id}}" [ngStyle]="{'font-weight': answerFontWeight}">{{o.answerText}}</mat-radio-button> -->
                  <mat-radio-button *ngFor="let o of quiz.answers"  name="{{o._id}}" value="{{o._id}}" [ngClass]="{'rightAnswer': o.rightAnswer, 'wrongAnswer': !o.rightAnswer }">
                    {{o.answerText}}</mat-radio-button>
                </mat-radio-group>  
              </div>

              <mat-form-field class="example-full-width" appearance="fill"  *ngIf="quiz.answers.length < 2">
                <p>Referenz antwort: {{quiz?.referenceAnswer}}</p>
                <p>Antwort:</p>
                <textarea matInput placeholder="Antwort"[(ngModel)]="quiz.freeTextAnswer" [disabled]="true"></textarea>
              </mat-form-field>
              <button mat-button color="primary" class="size-big"  *ngIf="quiz.answers.length < 1" (click)="rightAnswer(quiz)">Richtig</button>
              <button mat-button color="accent"  class="size-big"  *ngIf="quiz.answers.length < 1" (click)="wrongAnswer(quiz)">Falsch</button>
            </div>
          </mat-list-item>
        </mat-list>
        <button mat-button color="accent"  class="size-big" (click)="closeOverlay()">Schließen</button>
      </div>
    </div>
  </div>
</div>