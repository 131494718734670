import { Component, OnInit } from '@angular/core';
import { SpeedTestService } from './services/speed-test.service';

@Component({
  selector: 'app-speed-test',
  templateUrl: './speed-test.component.html',
  styleUrls: ['./speed-test.component.scss'],
})
export class SpeedTestComponent implements OnInit {
  public hasTracked: boolean = false;
  public isTracking: boolean = false;
  public iterations: number = 20;
  public speeds: any[] = [];
  average;

  constructor(
    private speedTestService: SpeedTestService
  ) { }

  ngOnInit() {
    console.log('speed test loaded')
    this.trackSpeed();
  }

  trackSpeed():void {
    if (this.hasTracked) {
      this.speeds = [];
      this.hasTracked = false;
    }

    if (this.iterations > 100) {
      this.iterations = 100;
    }

    this.isTracking = true;

    this.speedTestService.getMbps({ iterations: 1, retryDelay: 1500 }).subscribe(
      (speed) => {
        this.speeds.unshift(
          speed.toFixed(2)
        );
        console.log('Your speed is ' + speed);

        if (this.speeds.length < this.iterations) {
          this.trackSpeed();
        } else {
          this.isTracking = false;
          this.hasTracked = true;
          console.log(this.speeds)
          this.getAverage()
        }
      }
    )
  }

  getAverage() {
    this.average = this.speeds.reduce(function(p,c,i,a){return p + (c/a.length)},0).toFixed(2);
  }
}
