import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-lexicon',
  templateUrl: './lexicon.component.html',
  styleUrls: ['./lexicon.component.scss'],
})
export class LexiconComponent {
  searchForm: FormGroup;
  isSubmitted: boolean = false;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private dialogRef: MatDialogRef<LexiconComponent>,
  ) {
    this.searchForm = this.formBuilder.group({
      searchText: ['', Validators.required],
    });
  }

  onSearch(): void {
    this.isSubmitted = true;

    if (this.searchForm.invalid) {
      return;
    }

    this.dialogRef.close({ searchTerm: this.searchForm.controls['searchText'].value });
  }
}
