import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import DOMPurify from 'dompurify';

@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {

  constructor(
    protected sanitizer: DomSanitizer
  ) {}

 public transform(value: any, allowedTags:string = ''): any {
    let options = {};
    if (allowedTags != '') {
      let tags = [];
      allowedTags.split(',').map(function (str) {
        console.log(str)
        tags.push(str.trim());
      });
      options = {
        ALLOWED_TAGS: tags
      };
    }
    const sanitizedContent = DOMPurify.sanitize(value, options);
    return this.sanitizer.bypassSecurityTrustHtml(sanitizedContent);

  }
}