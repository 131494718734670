import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Subscription, Subject } from 'rxjs';
import { Book } from 'src/app/bookstore/shared/models/book';
import { BookFacade } from 'src/app/bookstore/shared/store/book/book.facade';
import { BookWithIsbn } from "../shared/models/book-startpage";
import { BookService } from '../../providers/book.service';
import { takeUntil } from 'rxjs/operators';
import { AuthService} from '../../auth/auth.service'
@Component({
  selector: 'app-search-results-page',
  templateUrl: './search-results-page.component.html',
  styleUrls: ['./search-results-page.component.scss'],
})
export class SearchResultsPageComponent implements OnDestroy {
  searchText: string = '';
  books: Book[] = [];
  // books: BookWithIsbn[] = [];
  // isLoading?: boolean;
  isLoading: boolean = true;
  error?: HttpErrorResponse;
  searchResultLoaded: boolean = false;
  userToken;
  mentorToken;
  userType;
  isMentor: boolean = false;
  private searchSubscription!: Subscription;
  public sessionStorage = sessionStorage;
  public localStorage = localStorage;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  sortOptions = [
    {
      text: 'Niedrigster Preis zuerst',
      compareFn: (book1: Book, book2: Book) => {
        if (book1.price == book2.price) {
          return 0;
        }
        return book1.price < book2.price ? -1 : 1;
      },
    },
    {
      text: 'Höchster Preis zuerst',
      compareFn: (book1: Book, book2: Book) => {
        if (book1.price == book2.price) {
          return 0;
        }
        return book1.price > book2.price ? -1 : 1;
      },
    },
  ];

  constructor(
    private readonly route: ActivatedRoute,
    private readonly bookFacade: BookFacade,
    private readonly router: Router,
    private bookService : BookService,
    private authService : AuthService
  ) {
    this.userType = this.authService.getType();
    if (this.userType == 'mentor' || this.userType == 'coordinator') {
      this.isMentor = true;
      var activeStudent = this.authService.getActiveStudent();
      this.mentorToken = activeStudent["token"].filter(token => token.status == "active").length;
    }
    this.route.params.subscribe((params) => this.search(
      params['searchText'],
      sessionStorage.getItem("userAge"),
      sessionStorage.getItem("userReadingAbility")
    ));
  }

  ngOnDestroy(): void {
    // this.searchSubscription.unsubscribe();
    this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
  }

  search(searchText: string, userAgeI: string|null, readingAbility?: string|null): void {
    let userAge:number;
    if (userAgeI == null) {
      userAge = 10
    } else {
      userAge = Number(userAgeI);
    }      
    if (readingAbility == null) {
      readingAbility="B1"
    } else {
        switch(readingAbility) {
          case "1":
            readingAbility = "A1"
            break
          case "2":
            readingAbility = "A2"
            break
          case "3":
            readingAbility = "B1"
            break
          case "4":
            readingAbility = "B2"
            break
          case "5":
            readingAbility = "C1"
            break
          case "6":
            readingAbility = "C2"
            break
        }
    }
    console.log(readingAbility)
    this.searchText = searchText;
    this.books = [];
    this.bookService.search(searchText, userAge, readingAbility).pipe(
      takeUntil(this._unsubscribeAll)
        ).subscribe((book: Book[]) => {
          this.isLoading = false;
          this.books = book["books"];
          console.log(this.books)
    })

    // this.bookFacade.search(searchText, userAge, readingAbility);
    // this.searchSubscription = combineLatest([
    //   this.bookFacade.isSearchLoading$,
    //   this.bookFacade.searchError$,
    //   this.bookFacade.searchResults$,
    // ]).subscribe(([isLoading, error, searchResults]) => {
    //   this.searchResultLoaded = true;
    //   this.isLoading = isLoading;
    //   this.error = error;
    //   console.log(searchResults);
    //   this.books = searchResults ?? [];
    // });

  }

  sortChanged(event: MatSelectChange): void {
    const arrayForSort = [...this.books];
          arrayForSort.sort(event.value.compareFn);
    this.books = arrayForSort;
  }

  onBack(): void {
   this.router.navigate([
      'library',
    ]);
  }
}
