import { Component, OnInit, ElementRef, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import * as RecordRTC from 'recordrtc';
import { WebSocketService } from '../providers/websocket.service';
import { WebrtcService } from '../providers/webrtc.service';
import { Subscription, Subject } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { formatDate } from '@angular/common';
import { SessionService } from '../providers/session.service';
import { CapacitorService } from '../providers/capacitor.service';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import ysFixWebmDuration from 'fix-webm-duration';
import { element } from 'protractor';
//import getBlobDuration from 'get-blob-duration';
import { NotificationService } from '../providers/notification.service';

declare var MediaRecorder: any;
declare let cordova: any;
var n = <any>navigator;
//    n.mediaDevices.getUserMedia = navigator.mediaDevices.getUserMedia || n.webkitGetUserMedia || n.mozGetUserMedia;
export class Session {
  _id: string;
  file: File;
  filename: string;
  student: string;
  sessionReadTime: string;
  mentor: string;
  pathToFile?: string;
  readingMode: string;
  chunkHash?: string;
  mentorDevice: any;
  studentDevice: any;
}

@Component({
  selector: 'app-record-rtc',
  templateUrl: './record-rtc.component.html',
  styleUrls: ['./record-rtc.component.scss']
})
export class RecordRTCComponent implements OnInit{
  IS_APP = environment.isApp;
  BOOKSTORE_ACTIVE = environment.bookstoreModule;
  sendChunksToServer: boolean = false;
  mentorUsername;
  studentUsername;
  private stream: MediaStream;
  private config: object;
  private recorder: any;
  public debug: boolean = false;
  public recordingStarted: boolean = false;
  public recordingPaused: boolean = true;
  public recordingFinished: boolean = false;
  public recordingUploaded: boolean = false;
  public blobIsReady: boolean = false;
  public isIOSAppBugHide: boolean = false;
  private connectionListenerSubs: Subscription;
  microphoneActive: boolean = false;
  partnerMicrophoneActive: boolean = false;
  cameraActive: boolean = false;
  partnerCameraActive: boolean = false;
  volumeActive: boolean = false;
  showVolumeControlNavbar: boolean = false;
  session : any;
  uploadprogress: number = 0;
  showprogress: boolean = false;
  color = 'primary';
  mode = 'determinate';
  recordedChunks: any = [];
  blobs:any = [];
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  chunkCount:number = 0;
  chunkHash;

  @Input('userType') isMentor;
  @Input('studentId') studentId: string;
  @Input('mentorId') mentorId: string;
  @Input('showRecordNavbar') showRecordNavbar: boolean;
  @Input('readingModeActive') readingModeActive: boolean;
  @Input('readingMode') readingMode: string;
  @Output('isRecording') informParent = new EventEmitter<boolean>();

  constructor(
    private webSocketService: WebSocketService,
    public webRTC: WebrtcService,
    public elRef: ElementRef,
    private authService : AuthService,
    private sessionService : SessionService,
    private capacitorService: CapacitorService,
    private notificationService: NotificationService,
  ) { }

  ngOnInit() {
    // IOS BUG HIDE RECORD NAV
    // if ( (this.capacitorService.getDevicePlatform() == 'ios' &&
    //       this.capacitorService.compareOSVersion(this.capacitorService.getDeviceOSVersion(), '14.5', '<')) && this.IS_APP) {
    //   this.isIOSAppBugHide = true;
    // }
    this.mentorUsername = this.authService.getUsername();
    if (this.isMentor) {
      this.studentUsername = this.authService.getUsernameOfActiveStudent();
    }
    this.connectionListenerSubs = this.webRTC.getConnectionStatusListener().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(isConnected => {
      // this.showRecordNavbar = isConnected;
      this.microphoneActive = true;
      this.partnerMicrophoneActive = true;
      this.cameraActive = true;
      this.partnerCameraActive = true;
      this.volumeActive = true;
    });
    if (this.readingMode == 'Solo') {
      this.showRecordNavbar = true;
      this.isMentor = true;
    } else {
      this.showVolumeControlNavbar = true;
    }
  }

  processAudio(audioWebMURL) {
    //var recordedBlob = this.recorder.getBlob();
    this.recorder.getDataURL(function (dataURL) { });
  }

  /**
   * recordingAction
   */
  recordingAction() {
    if (!this.recordingStarted && this.recordingPaused) {
      this.startRecording();
      this.informParent.emit(true);
    } else if (this.recordingStarted && !this.recordingPaused) {
      this.webSocketService.emitTo('record-timer', this.webSocketService.getCurrentRoom(), 'pause');
      this.recordingPaused = true;
      this.toggleRecording('pause');
    } else {
      this.webSocketService.emitTo('record-timer', this.webSocketService.getCurrentRoom(), 'resume');
      this.recordingPaused = false;
      this.toggleRecording('resume');
    }
  }

  /**
   * startRecording
   */
  async startRecording() {
    if(typeof n.mediaDevices === 'undefined' || !n.mediaDevices.getUserMedia) {
        alert('This browser does not supports WebRTC getUserMedia API.');

        if(!!n.getUserMedia) {
            alert('This browser seems supporting deprecated getUserMedia API.');
        }
    }

    if (this.BOOKSTORE_ACTIVE) {
      this.sessionService.setRecordingStatus('start');
    }

    if (this.readingMode == 'Solo') {
      // Get own stream
      await this.getMedia();
    } else {
      // Get partner stream
      await this.successCallback(this.webRTC.getPartnerStream());
    }
  }

  /**
   * getMedia
   */
  getMedia() {
    n.mediaDevices
        .getUserMedia({
          video: false,
          audio: {
            echoCancellation: true
          }
        }).then(this.successCallback.bind(this))
        .catch(this.errorCallback.bind(this));
  }

  /**
   * successCallback
   */
  successCallback(stream: MediaStream) {
    this.stream = stream;
    this.stream.getAudioTracks().forEach(function(track, index, array) {
        console.log('track ' + index)
        console.log(track);    
        //track.enabled = true; 
    });
    // Create hash for blob chunks to merge later
    this.chunkHash = Math.random().toString(36).substring(2, 15);
    // Change options for audio only
    this.config = {
        type: 'audio',
        //mimeType: 'audio/wav',
        mimeType: 'audio/webm;codecs=opus',
        // FINETUNING start
        // get intervals based blobs in milliseconds
        timeSlice: 1000,
        // both for audio and video tracks
        bitsPerSecond: 128000,
        // ignored when codecs=pcm
        audioBitsPerSecond: 128000,
        // // the range 22050 to 96000.
        // sampleRate: 96000,
        // // let us force 16khz recording:
        desiredSampRate: 16000,
        // // Legal values are (256, 512, 1024, 2048, 4096, 8192, 16384).
        bufferSize: 16384,
        // FINETUNING end
        numberOfAudioChannels: 1,
        ondataavailable: this.dataAvailable.bind(this),
        recorderType: RecordRTC.MediaStreamRecorder,
    };

    console.log(this.stream)
   
    this.recorder = new RecordRTC.RecordRTCPromisesHandler(this.stream, this.config);
    this.recorder.startRecording();

    // Emit time for metamorphose
    this.webSocketService.emitTo('record-timer', this.webSocketService.getCurrentRoom(), 'start');
    this.recordingStarted = true;
    this.recordingPaused = false;

    console.log(this.informParent);
  }

  /**
   * errorCallback
   */
  errorCallback(error) {
    //handle error here
    console.log('error record', error);
  }

  dataAvailable(blob) {
    if (this.sendChunksToServer) {
      console.log(blob);
      //this.recordedChunks.push(blob);
      this.chunkCount++;

      // Build a form
      const chunkForm = new FormData();
            chunkForm.append('file', blob);
            // chunkForm.append('name', file.name);
            // chunkForm.append('total', blockCount);
            chunkForm.append('index', this.chunkCount.toString());
            //chunkForm.append('size', blob.size);
            chunkForm.append('chunkHash', this.chunkHash);
            // console.log(chunkForm)

      this.sessionService.uploadSessionChunks(chunkForm);
    }
    // this.blobs.push(blob);

    // var size = 0;
    // this.blobs.forEach(function(b) {
    //     size += b.size;
    // });

    // console.log('Total blobs: ' + this.blobs.length + ' (Total size: ' + this.bytesToSize(size) + ')');
  }

  
  bytesToSize(bytes: number): string {
    const sizes: string[] = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes === 0) return 'n/a'
    const i: number = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString())
    if (i === 0) return `${bytes} ${sizes[i]}`
    return `${(bytes / Math.pow(1024, i)).toFixed(1)} ${sizes[i]}`
  }

  /**
   * toggleRecording change the status / start or pause
   */
  toggleRecording(status) {
    if(this.recordingStarted) {
      if (status == 'pause') {
          console.log('recording paused');
          this.recorder.pauseRecording();
          this.informParent.emit(false);
      } else if (status == 'resume') {
          console.log('recording resumed');
          this.recorder.resumeRecording();
          this.informParent.emit(true);
      }
    }
  } 

  /**
   * stopRecording
   */
  stopRecording(interaction:string) {
    // Inform to stop metamorphose
    this.webSocketService.emitTo('record-timer', this.webSocketService.getCurrentRoom(), 'stop');
    console.log(this.recorder)
    this.recordingFinished = true;
    this.recordingStarted = false;
    this.recordingPaused = true;
    this.showprogress = true;
    this.informParent.emit(false);
    if (this.BOOKSTORE_ACTIVE) {
      this.sessionService.setRecordingStatus('stop');
    }
    // Stop recording
    var _this = this;
    console.log('---- record stop now ----')
    this.recorder.stopRecording().then(function() {
    //this.recorder.stopRecording(this.stopRecordingCallback()).then(function() {
      console.log('stop then fired')
      _this.blobIsReady = true;
      _this.upload();
    }).catch(function(error) {
        console.error(error);
    });
  }

  stopRecordingCallback() {
      console.log('stop callback fired')
      // this.blobIsReady = true;
      // this.upload();
      var blob = new Blob(this.blobs, {type: 'audio/wav'});
      console.log('blob size is ' + this.bytesToSize(blob.size));
  }

  /**
   * upload audio
   */
  upload() {
    console.log('upload fired')
    var timestamp = formatDate(new Date(), 'yyyyMMddhhmmss', 'en');
    var fileName = '';
    if (this.readingMode == 'Solo') {
      fileName = 'solo_' + this.studentId + '_' + timestamp;
    } else {
      fileName = 'tandem_' + this.mentorId + '_' + this.studentId + '_' + timestamp;
    }
    // check if blob is ready
    var _this = this;
    // Create blob from chunks
    if (this.sendChunksToServer) {
      // Send empty file to server cause files are already uploaded
      const emptyChunks = [];
      const emptyBlob = new Blob(emptyChunks, {type: 'audio/wav'});
      _this.createSession(emptyBlob, fileName);
    } else {
      // Generate blob and send it to server
      this.recorder.getBlob().then(function(blob) {
        console.log('blob fired')
        if (_this.capacitorService.getDevicePlatform() == 'android' ||
            (_this.capacitorService.getDevicePlatform() == 'web' && _this.capacitorService.getBrowserName() == 'chrome')) {
            console.log('seekable blob')
            RecordRTC.getSeekableBlob(blob, function(seekableBlob) {
              _this.createSession(seekableBlob, fileName);
            });
        } else {
            console.log('fix duration in blob')
            // Get duration and fix blob
            //getBlobDuration(blob).then(function(duration) {
              ysFixWebmDuration(blob, _this.sessionService.getSessionReadTime(), function(fixedBlob) {
                _this.createSession(fixedBlob, fileName);
              });
            //});
        }
      });
    }
    // this.reset();
  }

  createSession(blob, fileName) {
    const session: Session = new Session();
          session._id = 'pre_' + new Date().getTime();
          session.file = blob;
          //session.filename = fileName + '.wav';
          session.filename = fileName + '.opus';
          session.student = this.studentId;
          if (this.readingMode == 'Tandem') {
            session.mentor = this.mentorId;
            session.studentDevice = this.webRTC.getPartnerDeviceInfo();
            session.mentorDevice = this.capacitorService.getDeviceInfo();
          } else {
            session.studentDevice = this.capacitorService.getDeviceInfo();
          }
          session.readingMode = this.readingMode;
          if (this.sendChunksToServer) {
            session.chunkHash = this.chunkHash;
          }
    this.session = session;
    this.saveSession(this.session);
  }

  saveSession(session) {
       this.showprogress = true;
       this.sessionService.createSession(session).pipe(
          takeUntil(this._unsubscribeAll)
        ).subscribe((res:any) => {
          console.log(res)
          // Show upload progress
          if (res.status == 'progress') {
            this.uploadprogress = res.message;
          }
          // Upload finished
          if (res.status == 'finished') {
            this.showprogress = false;
            // Rest progress for next upload
            this.uploadprogress = 0;
            // Reset upload
            this.recordingFinished = false;
            // Successfully uploaded
            this.recordingUploaded = true;
            // Reset blob
            this.blobIsReady = false;
            // Reset metamorphose
            if (this.readingMode == 'Solo') {
              this.webSocketService.emit('metamorphose-reset-solo', '');
            } else {
              if (this.isMentor) {
                this.webSocketService.emitTo('metamorphose-reset', this.webSocketService.getCurrentRoom(), '');
              }
            }
            // Reset chunks
            this.chunkCount = 0;
            // Reset read books
            if (this.BOOKSTORE_ACTIVE) {
              this.sessionService.resetReadBooksIsbn();
            } else {
              this.sessionService.resetReadBooks();
            }
          }
      });
 }

  /**
   * disable sound on local video
   */
  toggleMuteSpeaker() {
    this.volumeActive = !this.volumeActive;
    if(this.partnerMicrophoneActive && !this.volumeActive || this.partnerMicrophoneActive && this.volumeActive) {
      this.webRTC.toggleMutePartnerAudio();
    }
  }

  /**
   * disable microphone on stream
   */
  toggleMuteMicrophone() {
    this.microphoneActive = !this.microphoneActive;
    this.webRTC.toggleMuteMyAudioStream();
  }

  /**
   * disable video on stream
   */
  toggleVideoStream() {
    this.cameraActive = !this.cameraActive;
    this.webRTC.toggleMyVideoStream();
  }

  /**
   * disable microphone on partner stream
   */
   toggleMutePartnerMicrophone() {
    this.partnerMicrophoneActive = !this.partnerMicrophoneActive;
    if(this.volumeActive && !this.partnerMicrophoneActive || this.volumeActive && this.partnerMicrophoneActive) {
      this.webRTC.toggleMutePartnerAudio();
    }

  }

  /**
   * disable video on partner stream
   */
  togglePartnerVideoStream() {
    this.partnerCameraActive = !this.partnerCameraActive;
    this.webRTC.togglePartnerVideoStream();
  }

  playVideoStreams() {
    this.webRTC.playVideoStreams();
  }

  /**
   * reset record
   */
  reset() {
    this.recorder.reset(this.processAudio.bind(this));
    this.recordingFinished = false;
  }

  /**
   * ngOnChanges / set recorder pause
   */ 
  ngOnChanges(changes: SimpleChanges) {
    if (changes.readingModeActive) {
      if (changes.readingModeActive.currentValue === false && this.recordingStarted && !this.recordingPaused) {
        // Set recording on pause while switching to game mode
        this.webSocketService.emitTo('record-timer', this.webSocketService.getCurrentRoom(), 'pause');
        this.recordingPaused = true;
        this.toggleRecording('pause');
      }
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.turnOffUserMedia();
    // Check recording status before destroy
    if (!this.recordingUploaded) {
      if (this.recordingStarted && !this.recordingFinished) {
        this.stopRecording('onleave');
      } else if (this.recordingFinished) {
        this.upload();
      }
    }

    setTimeout(() => {
      //console.log('record timer unsubscribe -------------')
      this.connectionListenerSubs.unsubscribe();
      this._unsubscribeAll.next(true);
      this._unsubscribeAll.complete();
    }, 100);
  }

  turnOffUserMedia() {
    if (this.readingMode == 'Solo') {
      // Close Microphone on single reading
      if (this.stream != undefined) {
        this.stream.getTracks().forEach(function(track, index, array) {
          track.stop();      
        });
      }
    }
  }
}