import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface QuizResult {
  _id?: string;
  session?: string;
  student?: string;
  mentor?: string;
  quizMode?: string;
  quiz?: string;
}

@Injectable({
  providedIn: 'root'
})

export class QuizResultService {
  private restServerUrl;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  quizResult: QuizResult[] = [];
  quizResultUpdated = new Subject<QuizResult[]>();
  onQuizResultChanged: BehaviorSubject<any>;

  constructor(
    private http: HttpClient,
  ) { 
    let protocol = environment.ssl ? 'https://' : 'http://';
    this.restServerUrl = protocol + environment.restServer + ":" + environment.restServerPort ;
  }
  
  createQuizResult(formData) {
    console.log(formData)
    return this.http.post<any>(this.restServerUrl + "/api/quizResult/create", formData)
  }
  
  getQuizResultbyId(id) {
    return this.http.get<QuizResult[]>(this.restServerUrl + '/api/quizResult/' + id)
      .pipe(map(data => data));
  }

    /**
   * get list of all students
   */
	getQuizResultList() {
		this.http.get<QuizResult[]>(this.restServerUrl + '/api/quizResult/list')
    .pipe(map(data => data), takeUntil(this._unsubscribeAll))
			.subscribe(data => {
        // console.log(data)
                this.quizResult = data;
                this.quizResultUpdated.next([...this.quizResult]);
                // this.onQuizResultChanged.next(this.quizResult);
			});
  }

  // getQuizResultAboutTheBook(id) {
  //   console.log(id)
	// 	this.http.get<QuizResult[]>(this.restServerUrl + '/api/quizResult/listByBook/' + id)
  //   .pipe(map(data => data), takeUntil(this._unsubscribeAll))
	// 		.subscribe(data => {
  //       console.log(data)
  //               this.quizResult = data;
  //               this.quizResultUpdated.next([...this.quizResult]);
	// 		});
  // }

  getQuizResultUpdateListener() {
    return this.quizResultUpdated.asObservable();
  }

  // updateQuizResult(id, data:any)  {
  //   console.log(data)
  //   console.log(id)
  //   return this.http.put(
  //     this.restServerUrl + '/api/quizResult/' + id,
  //     JSON.stringify(data),
  //     { headers: new HttpHeaders().set('Content-Type', 'application/json') })
  //       .pipe(map(res => {
  //         return <QuizResult>res;
  //       }));
  // }

  updateQuizResult(data)  {
    return this.http.put(
      this.restServerUrl + '/api/quizResult/' + data._id,
      JSON.stringify(data),
      { headers: new HttpHeaders().set('Content-Type', 'application/json') })
        .pipe(map(res => {
          return <QuizResult>res;
        }));
  }

   /**
   * set delete question
  */
 deleteQuizResult(id: string) {
    this.http.delete(this.restServerUrl + '/api/quizResult/' + id).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(res => {
      // Update local list
      this.quizResult.splice(this.quizResult.findIndex((item) => {
          return item._id === id;
      }), 1);
        this.quizResultUpdated.next([...this.quizResult]);
      }, (error) => {
        console.error("Error deleting books: ", error);
    });
  }
    
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
