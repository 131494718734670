<div class="session container-fluid pt-3">
    <div class="session_wrap row">
      <div class="col-md-12">
  
        <div class="table-navigation">
          <div class="filter-area">
            <div class="filter-wrap">
              <mat-form-field>
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Nachname, Vorname" #input>
              </mat-form-field>
            </div>
          </div>
        </div>
  
        <mat-table [dataSource]="dataSource" class="mat-elevation-z1" matSort matSortActive="username" matSortDisableClear matSortDirection="asc">
          <!-- <ng-container matColumnDef="preview-button">
            <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
            <mat-cell *matCellDef="let element">
              <mat-icon (click)="openStudentProfil(element._id)">preview</mat-icon>
            </mat-cell>
          </ng-container> -->
          
          <ng-container matColumnDef="username">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Benutzername</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span>
              {{ element.username}}
              </span>
            </mat-cell>
          </ng-container>
  
          <ng-container matColumnDef="firstname">
              <mat-header-cell *matHeaderCellDef  mat-sort-header>Vorname</mat-header-cell>
              <mat-cell *matCellDef="let element">
                    {{ element.firstname}} 
              </mat-cell>
          </ng-container>
  
          <ng-container matColumnDef="lastname">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Nachname</mat-header-cell>
              <mat-cell *matCellDef="let element">
                    {{ element.lastname }} 
              </mat-cell>
          </ng-container>
  
          <ng-container matColumnDef="token">
              <mat-header-cell *matHeaderCellDef>Token (Aktiv/Eingelöst/Abgelaufen)</mat-header-cell>
              <mat-cell *matCellDef="let element">
                    <span>{{ listToken(element?.token) }}</span>
              </mat-cell>
          </ng-container>
  
          <ng-container matColumnDef="created">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Erstellungsdatum</mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.created | date:'dd.MM.yyyy' }}
              </mat-cell>
          </ng-container>
    
          <ng-container matColumnDef="buttons">
              <mat-header-cell *matHeaderCellDef></mat-header-cell>
              <mat-cell *matCellDef="let element" >
                  <div>
                      <button mat-icon-button [matMenuTriggerFor]="moreMenu" aria-label="More" *ngIf="isPermissionsTokenAdd">
                          <mat-icon class="secondary-text">more_vert</mat-icon>
                      </button>
  
                      <mat-menu #moreMenu="matMenu">
                          <button mat-menu-item aria-label="edit"(click)="createToken(element._id)">
                              <mat-icon>generating_tokens</mat-icon>
                              <span>Um 1 Token erhöhen</span>
                          </button>
                      </mat-menu>
                  </div>
              </mat-cell>
          </ng-container>
  
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <mat-paginator #paginator [pageSize]="10"></mat-paginator>
      </div>
    </div>
  </div>