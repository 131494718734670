<div class=" game-list container-fluid">
  <div class="col-md-12">
    <div class="row"> 
      <div class="col-md-10 col-lg-10 col-sm-10 px-3">
        <div class="game-description">
          <h1>Sammle erfolgreich Verwandlungen um Einzelspieler Spiele freizuschalten</h1>
        </div>
      </div>
  
      <div class="col-md-2 col-lg-2 col-sm-2 px-3">
        <div class="metamorphose-header">
          <!-- <button mat-button color="accent" class="size-big" [routerLink]="['/dashboard']">zur Übersicht</button> -->
          <div class="metamorphose-earned">
            <h1>{{metamorphosePoints}}</h1>
            <img src="assets/metamorphose/{{selectedmetamorphose}}_thumbnail.png" />
            <!-- <video class="metamorphose-image" playsinline muted width='200'>
                  <source src="assets/metamorphose/dino.mp4" type="video/mp4"/>
                </video> -->
          </div>
        </div>
     
      </div>
    </div>


    <div class="col-md-12 col-lg-12 col-sm-12 px-12">
      <div class="row game_selection_row">
        <div class="col-md-6 col-sm-12">
          <div class="metamorphose-button" [ngClass]="{'not-earned-yet': metamorphosePoints < 2 }">
            <h1>2</h1>
            <img src="assets/metamorphose/{{selectedmetamorphose}}_thumbnail.png" class="avatar" />
            <!-- <video class="metamorphose-image" playsinline muted width='130'>
                  <source src="assets/metamorphose/dino.mp4" type="video/mp4"/>
                </video> -->
            <div class="game-button-wrap">
              <button mat-button color="accent" [routerLink]="['/games/tower-blocks']" class="size-big"
                [disabled]="metamorphosePoints < 2">
                Blöcke schneiden
              </button>
              <img src="assets/games/tower-blocks.jpg" class="game-screenshot" />
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="metamorphose-button" [ngClass]="{'not-earned-yet': metamorphosePoints < 6 }">
            <h1>6</h1>
            <img src="assets/metamorphose/{{selectedmetamorphose}}_thumbnail.png" class="avatar" />
            <!-- <video class="metamorphose-image" playsinline muted width='130'>
                  <source src="assets/metamorphose/dino.mp4" type="video/mp4"/>
                </video>             -->
            <div class="game-button-wrap">
              <button mat-button color="accent" [routerLink]="['/games/rabbit-run']" class="size-big"
                [disabled]="metamorphosePoints < 6">
                Wolf und Hase
              </button>
              <img src="assets/games/rabbit-run.jpg" class="game-screenshot" />
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="metamorphose-button" [ngClass]="{'not-earned-yet': metamorphosePoints < 10 }">
            <h1>10</h1>
            <img src="assets/metamorphose/{{selectedmetamorphose}}_thumbnail.png" class="avatar" />
            <!-- <video class="metamorphose-image" playsinline muted width='130'>
                  <source src="assets/metamorphose/dino.mp4" type="video/mp4"/>
                </video> -->
            <div class="game-button-wrap">
              <button mat-button color="accent" [routerLink]="['/games/hextris']" class="size-big"
                [disabled]="metamorphosePoints < 10">
                Hextris
              </button>
              <img src="assets/games/hextris.jpg" class="game-screenshot" />
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="metamorphose-button" [ngClass]="{'not-earned-yet': metamorphosePoints < 15 }">
            <h1>15</h1>
            <img src="assets/metamorphose/{{selectedmetamorphose}}_thumbnail.png" class="avatar" />
            <!-- <video class="metamorphose-image" playsinline muted width='130'>
                  <source src="assets/metamorphose/dino.mp4" type="video/mp4"/>
                </video> -->
            <div class="game-button-wrap">
              <button mat-button color="accent" [routerLink]="['/games/aviator']" class="size-big"
                [disabled]="metamorphosePoints < 15">
                Weitflug
              </button>
              <img src="assets/games/aviator.jpg" class="game-screenshot" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>