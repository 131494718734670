import { Component, OnInit, Input, ViewChild, Inject, SimpleChanges} from '@angular/core';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionService } from '../providers/session.service';
import { UserService } from '../providers/user.service';
import { AuthService } from '../auth/auth.service';
import { map, takeUntil } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
})
export class AnalyticsComponent implements OnInit {
  QUIZ_ACTIVE = environment.quizModule;
  userId: string;
  isMentor = true;
  isAdmin = false;
  userType;
  totalReadingTime = 0; 
  totalBookCount = 0;
  totalPagesCount = 0;
  totalReadingTimeSolo = 0;
  totalPagesCountSolo = 0;
  metamorphosePoints = 0;
  quizSolo = 0;
  quizSoloRight = 0;
  quizTotal = 0;
  quizTotalRight = 0;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  @Input('readingPointsIncreased') readingPointsIncreased: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sessionService : SessionService,
    private authService : AuthService,
    private userService : UserService
  ) { }

  ngOnInit() {
    this.userId = this.route.snapshot.params['id'];
    this.userType =  this.authService.getType();
    if (!(this.userType == 'mentor'  || this.userType == 'coordinator')){
      this.isMentor = false;
    }
    // Get analytics data
    this.sessionService.getAnalyticsOfSession(this.userId)
        .pipe(map(data => data), takeUntil(this._unsubscribeAll))
        .subscribe(data => {
          console.log(data)
          this.totalReadingTime = data.totalReadingTime; 
          this.totalBookCount = data.totalBookCount;
          this.totalPagesCount = data.totalPagesCount;
          this.totalReadingTimeSolo = data.totalReadingTimeSolo;
          this.totalPagesCountSolo = data.totalPagesCountSolo;
          if (this.QUIZ_ACTIVE) {
            this.quizSolo = data.quizSolo ? data.quizSolo : 0;
            this.quizSoloRight = data.quizSoloRight? data.quizSoloRight : 0;
            this.quizTotal = data.quizTotal? data.quizTotal : 0;
            this.quizTotalRight = data.quizTotalRight? data.quizTotalRight : 0;
          }
    });
    // Get metamorphose points
    this.getMetamorphosePoints();
  }

  getMetamorphosePoints() {
    this.userService.getUserbyId(this.userId).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(res => {
      this.metamorphosePoints = res["readingPoints"] ? res["readingPoints"] : 0;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes)
    if (changes.readingPointsIncreased.previousValue != undefined &&
        changes.readingPointsIncreased.previousValue != changes.readingPointsIncreased.currentValue) {
          // Update metamorphose points
          this.getMetamorphosePoints();
        }
  }
  
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}

