import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'linebreaks'
})

export class LinebreaksPipe implements PipeTransform {
  transform(value: string): string {
    if ( value != null) {
      return value.replace(/\n/g, '<br/>');
    }
  }
}