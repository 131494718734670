import { HttpErrorResponse } from '@angular/common/http';
import { Action, createReducer, on } from '@ngrx/store';
import { Book } from '../../models/book';
import * as BookActions from './book.actions';
import {BookList, BookWithIsbn} from "../../models/book-startpage";

export const bookFeatureKey = 'book';

export interface BookStateModel {
  isFetchByIsbnLoading: boolean;
  fetchByIsbnError?: HttpErrorResponse;
  book?: Book;
  isPurchaseLoading: boolean;
  purchaseError?: HttpErrorResponse;
  isSearchLoading: boolean;
  searchError?: HttpErrorResponse;
  searchResults?: BookWithIsbn[];
  isStartpageBookListLoading: boolean;
  startpageBookList?: BookList;
  startpageBookListError?: HttpErrorResponse;
}

export interface BookState {
  readonly [bookFeatureKey]: BookStateModel;
}

export const bookInitalState: BookStateModel = {
  isFetchByIsbnLoading: false,
  isPurchaseLoading: false,
  isSearchLoading: false,
  isStartpageBookListLoading: false,
};

const reducer = createReducer(
  bookInitalState,
  on(BookActions.fetchByIsbn, (state) => ({
    ...state,
    isFetchByIsbnLoading: true,
  })),
  on(BookActions.fetchByIsbnSuccess, (state, action) => ({
    ...state,
    isFetchByIsbnLoading: false,
    fetchByIsbnError: undefined,
    book: action.book,
  })),
  on(BookActions.fetchByIsbnFail, (state, action) => ({
    ...state,
    isFetchByIsbnLoading: false,
    fetchByIsbnError: action.error,
  })),
  on(BookActions.purchaseByIsbn, (state) => ({
    ...state,
    isPurchaseLoading: true,
  })),
  on(BookActions.purchaseByIsbnSuccess, (state, action) => ({
    ...state,
    isPurchaseLoading: false,
    purchaseError: undefined,
  })),
  on(BookActions.purchaseByIsbnFail, (state, action) => ({
    ...state,
    isPurchaseLoading: false,
    purchaseError: action.error,
  })),
  on(BookActions.search, (state) => ({
    ...state,
    isSearchLoading: true,
  })),
  on(BookActions.searchSuccess, (state, action) => ({
    ...state,
    isSearchLoading: false,
    searchError: undefined,
    searchResults: action.searchResults,
  })),
  on(BookActions.searchFail, (state, action) => ({
    ...state,
    isSearchLoading: false,
    searchError: action.error,
  })),
  on(BookActions.fetchByClub, (state) => ({
    ...state,
    isStartpageBookListLoading: true,
  })),
  on(BookActions.fetchByClubSuccess, (state, action) => ({
    ...state,
    isStartpageBookListLoading: false,
    startpageBookListError: undefined,
    startpageBookList: action.books,
  })),
  on(BookActions.fetchByClubFail, (state, action) => ({
    ...state,
    isStartpageBookListLoading: false,
    startpageBookListError: action.error,
    startpageBookList: undefined,
  }))
);

export const bookReducer = (
  state: BookStateModel | undefined,
  action: Action
): BookStateModel => reducer(state, action);
