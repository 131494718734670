<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="search-wrapper">
        <div class="vertically-center-content">
          <app-search-input></app-search-input>
        </div>
        <div class="vertically-center-content">
          <!-- <p class="balance-text">Dein Guthaben: {{sessionStorage.getItem("userWallet")}}</p> -->
          <p *ngIf="!isMentor" class="balance-text">Dein Guthaben: {{sessionStorage.getItem("userToken")}} Token</p>
          <p *ngIf="isMentor" class="balance-text">Guthaben Lesekind: {{mentorToken}} Token</p>
        </div>
      </div>
      <div>
        <h2>Ergebnisse für Deine Suche nach: "{{ searchText }}"</h2>
        <div class="order-by-container">
          <mat-form-field class="order-by-select" appearance="fill">
            <mat-label>Sortieren nach</mat-label>
            <mat-select (selectionChange)="sortChanged($event)">
              <mat-option *ngFor="let sortOption of sortOptions" [value]="sortOption">
                {{ sortOption.text }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <ng-container *ngIf="!isLoading; else loader">
          <app-book-vertical-list  [books]="books"></app-book-vertical-list>
          <p *ngIf="this.books.length===0" class="error-message">&nbsp;&nbsp;Leider wurden zu Deiner Suche keine passenden
            Bücher gefunden. </p>
        </ng-container>
        <ng-template #loader>
          <div class="loading-screen search-loading">
            <app-loading-spinner></app-loading-spinner>
          </div>
        </ng-template>
      

      </div>
      <div class="button-field">
        <button (click)="onBack()" class="btn btn-block btn-secondary">
          Zurück
        </button>
      </div>
    </div>
  </div>
</div>
