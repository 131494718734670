import { Component, Input, OnInit } from '@angular/core';
import { BookWithIsbn } from "../shared/models/book-startpage";

@Component({
  selector: 'app-book-vertical-list',
  templateUrl: './book-vertical-list.component.html',
  styleUrls: ['./book-vertical-list.component.scss'],
})
export class BookVerticalListComponent implements OnInit {
  @Input() books!: BookWithIsbn[];

  constructor() {}

  ngOnInit(): void {
    if (!this.books) {
      throw new Error('Input parameter "books" is undefined');
    }
  }
}
